import React, { useEffect } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";

function ClosedCopy() {
    const [siteLoader, setSiteLoader] = useState(false);

    const navigate = useNavigate();
  
    const handleBack = async () => {
      navigate("/copytrading");
    };
  
    const typeOptions = [
      { key: "FutureCopy", text: "Future Copy", value: "FutureCopy" },
      { key: "SpotCopy", text: "Spot Copy", value: "SpotCopy" },
    ];
  
    const navOngoing = async() => {
      navigate("/ongoingcopy");
    }
  
    const navMock = async() => {
      navigate("/mockcopytrade");
    }

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="">
          <div className="copi_setting_hero">
            <div className="container">
              <div
                className="copi_setting_hero_backman"
                onClick={() => handleBack()}
              >
                <i class="fa-solid fa-arrow-left"></i>
                <span>Back</span>
              </div>
              <div className="ongo_top_man">
                <Dropdown
                  placeholder="Choose Type"
                  fluid
                  selection
                  options={typeOptions}
                  // onChange={(e, { value }) => dayschoose(value)}
                  className="opt-select-copieoptions ongoing-ch-dropdown"
                />
                <span className="ongo_top_slash">|</span>
                <div className="ongo_other_main">
                  <div className="ongo_head_otherinact" onClick={() => navOngoing()}>
                    <span>Ongoing (1)</span>
                  </div>
                  <div className="ongo_head_act">
                    <span>Closed (0)</span>
                  </div>
                  <div className="ongo_head_otherinact" onClick={() => navMock()}>
                    <span>Mock Copy Trading (2)</span>
                  </div>
                </div>
              </div>
              <div className="row ongo_top_card">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <div className="ongo_top_card_innn">
                    <span className="ongo_top_card_innn_head">
                      Total Margin Balance (USDT)
                    </span>
                    <span className="ongo_top_card_innn_subalance">
                      0.00000
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ongo_col_mar_578">
                  <div className="ongo_top_card_innn">
                    <span className="ongo_top_card_innn_head">
                      Total Wallet Balance (USDT)
                    </span>
                    <span className="ongo_top_card_innn_subalance">
                      0.00000
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ongo_col_mar_768">
                  <div className="ongo_top_card_innn">
                    <span className="ongo_top_card_innn_head">
                      {" "}
                      Total Realized PnL (USDT)
                    </span>
                    <span className="ongo_top_card_innn_subalance">
                      0.00000
                    </span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6 ongo_col_mar">
                  <div className="ongo_top_card_innn">
                    <span className="ongo_top_card_innn_head">
                      Net Profit (USDT)
                    </span>
                    <span className="ongo_top_card_innn_subalance">
                      0.00000
                    </span>
                  </div>
                </div>
              </div>
             <div className="copy_detail_norecord">
                <img src={require("../assets/no-copytrade-data.webp")} alt="No data" className="copy_detail_nodata" />
             </div>   
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ClosedCopy;