import React, { useEffect } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";
import "./OTC.css";

function OtcOption() {
  const [siteLoader, setSiteLoader] = useState(false);
  const [symbolActiveIndex, setSymbolActiveIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();

  const options = [
    { key: "BTC", text: "BTC", value: "BTC" },
    { key: "ETH", text: "ETH", value: "ETH" },
  ];

  const symbolOptions = ["BTCUSDT", "ETHUSDT", "BNBUSDT", "XRPUSDT", "SOLUSDT"];

  const startegiesOptions = [
    "Single Call",
    "Single Put",
    "Call Spread",
    "Put Spread",
    "Calendar Spread",
    "Diagonal Spread",
    "Straddle",
    "Strangle",
  ];

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  const handleClickSymbols = (index) => {
    setSymbolActiveIndex(index);
  };

  const navSpot = async () => {
    navigate("/otc");
  };

  const navAlgo = async () => {
    navigate("/algootc");
  };

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="">
          <div className="otc_spot_hero">
            <div className="container">
              <div className="ongo_top_man">
                <span className="otc_heading">OTC Trading</span>
                <span className="ongo_top_slash">|</span>
                <div className="ongo_other_main">
                  <div
                    className="ongo_head_otherinact"
                    onClick={() => navSpot()}
                  >
                    <span>Spot</span>
                  </div>
                  <div
                    className="ongo_head_otherinact"
                    onClick={() => navAlgo()}
                  >
                    <span>Algo Order</span>
                  </div>
                  <div className="ongo_head_act">
                    <span>Option</span>
                  </div>
                </div>
              </div>
              <div className="otc_spo_defman">
                <span className="otc_spo_defman_head">Options RFQ</span>
                <span className="otc_spo_defman_sub">
                  Request-for-quote (RFQ) for large and block Options trades.
                </span>
              </div>

              <div className="row option_otc_cent">
                <div className="col-lg-6">
                  <div className="optn_left_main">
                    <span className="optn_inn_head">BTCUSDT</span>
                    <div className="optn_inn_bodman">
                      <span className="optn_inn_bodman_title">Type</span>
                      <Dropdown
                        placeholder="call"
                        fluid
                        className="option-raq-drops"
                        selection
                        options={options}
                      />
                      <span className="optn_inn_bodman_title">Side</span>
                      <Dropdown
                        placeholder="call"
                        fluid
                        className="option-raq-drops"
                        selection
                        options={options}
                      />
                      <span className="optn_inn_bodman_title">Expiry</span>
                      <Dropdown
                        placeholder="2025-01-29"
                        fluid
                        className="option-raq-drops"
                        selection
                        options={options}
                      />
                      <span className="optn_inn_bodman_title">Strike</span>
                      <Dropdown
                        placeholder="980000"
                        fluid
                        className="option-raq-drops"
                        selection
                        options={options}
                      />
                      <span className="optn_inn_bodman_title">Amount</span>
                      <div className="optn_innlast_inpman">
                        <input
                          type="number"
                          min="0"
                          max="12"
                          placeholder="00"
                          className="algo_cen_left_explimitpr"
                        />
                        <span className="exception_strategy">Contract</span>
                      </div>
                    </div>
                    <div className="algo_lef_btn">
                      <button>Request</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="optn_righ_main">
                    <div className="optn_righ_main_top">
                      <span className="optn_righ_main_top_head">Symbol</span>
                      <div className="optn_righ_main_top_subman">
                        {symbolOptions.map((option, index) => (
                          <div
                            key={index}
                            className={`option_faq_top_tap ${
                              symbolActiveIndex === index ? "active" : ""
                            }`}
                            onClick={() => handleClickSymbols(index)}
                          >
                            <span>{option}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="optn_righ_main_top">
                      <span className="optn_righ_main_top_head">
                        Strategies
                      </span>
                      <div className="optn_righ_main_top_subman">
                        {startegiesOptions.map((option, index) => (
                          <div
                            key={index}
                            className={`option_faq_top_tap ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => handleClick(index)}
                          >
                            <span>{option}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="otc_spot_bottom_main">
                <div className="otc_bot_view_main">
                  <span className="otc_bot_ref_spa">Referral History</span>
                  <div className="otc_bot_view_head">
                    <span>View</span>
                    <i
                      class="ri-arrow-right-s-line"
                      style={{ color: "#ffc630" }}
                    ></i>
                  </div>
                </div>
                <div className="table-responsive table-cont otc_spot_tableup">
                  <table className="table">
                    <thead>
                      <tr className="stake-head font-satoshi">
                        <th>Order ID</th>
                        <th className="pad-left-23 txt-center">Symbol</th>
                        <th className="pad-left-23 txt-center">Direction</th>
                        <th className="pad-left-23 txt-center">Order Type</th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Price Limit
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Progress
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Average Price
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Filled / Total Amount
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          TWAP Remaining / Total Time
                        </th>
                        <th className="opt-nowrap table-action pad-left-23 pad-rght-tab">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center py-5">
                          <div className="empty_data">
                            <div className="empty_data_img py-4">
                              <img
                                src={require("../assets/no-copytrade-data.webp")}
                                width="100px"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OtcOption;
