import React, { useEffect } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";
import "./OTC.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function AlgoOtc() {
  const [siteLoader, setSiteLoader] = useState(false);
  const [buySell, setBuySell, buySellref] = useState("buy");
  const [exceptStrategy, setExceptStrategy, exceptStrategyref] =
    useState("TWAP");
  const [exceptUrgency, setExceptUrgency, exceptUrgencyref] = useState("Low");
  const [timePeriod, setTimePeriod, timePeriodref] = useState("24h");
  const [activeClass, setActiveclass, activeClassref] = useState("bar");
  const [activeIndex, setActiveIndex] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();

  const pairOptions = [
    { key: "BTCUSDT", text: "BTCUSDT", value: "BTCUSDT" },
    { key: "ETHUSDT", text: "ETHUSDT", value: "ETHUSDT" },
    { key: "BNBUSDT", text: "BNBUSDT", value: "BNBUSDT" },
  ];

  const options = [
    { key: "BTC", text: "BTC", value: "BTC" },
    { key: "ETH", text: "ETH", value: "ETH" },
  ];

  const times = ["30mins", "1hour", "6hours", "12hours"];

  const navSpot = async () => {
    navigate("/otc");
  };
  const navOption = async () => {
    navigate("/otcoption");
  };

  const handleBuy = async (value) => {
    setBuySell(value);
  };

  const handleStartegy = async (value) => {
    setExceptStrategy(value);
  };

  const handleUrgency = async (value) => {
    setExceptUrgency(value);
  };

  const handlePeriod = async (value) => {
    setTimePeriod(value);
  };

  const handleActive = async (value) => {
    setActiveclass(value);
  };

  const handleClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="">
          <div className="otc_spot_hero">
            <div className="container">
              <div className="ongo_top_man">
                <span className="otc_heading">OTC Trading</span>
                <span className="ongo_top_slash">|</span>
                <div className="ongo_other_main">
                  <div
                    className="ongo_head_otherinact"
                    onClick={() => navSpot()}
                  >
                    <span>Spot</span>
                  </div>
                  <div className="ongo_head_act">
                    <span>Algo Order</span>
                  </div>
                  <div
                    className="ongo_head_otherinact"
                    onClick={() => navOption()}
                  >
                    <span>Option</span>
                  </div>
                </div>
              </div>
              <div className="otc_spo_defman">
                <span className="otc_spo_defman_head">Algo Orders</span>
                <span className="otc_spo_defman_sub">
                  Execute large or illiquid orders with institutional-grade
                  intelligent algorithmic order strategies.
                </span>
              </div>
              <div className="row algo_cent_main">
                <div className="col-lg-5 p-0">
                  <div className="algo_cen_left">
                    <div className="algo_cen_left_top">
                      <div className="algo_cen_left_Head">
                        <Dropdown
                          placeholder="Choose Type"
                          fluid
                          selection
                          options={pairOptions}
                          // onChange={(e, { value }) => dayschoose(value)}
                          className="opt-select-copieoptions algo-ch-dropdown"
                        />
                        <div className="algo_cen_left_righsub mt-0">
                          <span className="algo_cen_left_righsub_top">
                            102,572.82
                          </span>
                          <span className="algo_cen_left_righsub_bot">
                            +3.54%
                          </span>
                        </div>
                      </div>
                      <div className="algo_cen_left_body">
                        <div className="ongo_other_main">
                          <div
                            className={
                              buySellref.current == "buy"
                                ? "ongo_head_act"
                                : "ongo_head_otherinact"
                            }
                            onClick={() => handleBuy("buy")}
                          >
                            <span>Buy BTC</span>
                          </div>
                          <div
                            className={
                              buySellref.current == "sell"
                                ? "ongo_head_act"
                                : "ongo_head_otherinact"
                            }
                            onClick={() => handleBuy("sell")}
                          >
                            <span>Sell BTC</span>
                          </div>
                        </div>
                        <div className="algo_cen_left_amnman">
                          <span className="algo_cen_left_amnman_span">
                            Amount (BTC)
                          </span>
                          <div className="otc_algo_lft_top">
                            <div className="foot_frst">
                              <input
                                // type="number"
                                type="text"
                                id="numberInput"
                                min="0"
                                maxLength={10}
                                placeholder="0.00"
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ); // Allow numbers and dot
                                  if (
                                    (e.target.value.match(/\./g) || []).length >
                                    1
                                  ) {
                                    e.target.value = e.target.value.slice(
                                      0,
                                      -1
                                    );
                                  }
                                }}
                                className="swap_in_val fnt_26"
                              />
                              <span className="algo_inpequ_usdt">
                                {" "}
                                = 0.00 USDT
                              </span>
                            </div>
                            <div className="otc_chng_frst">
                              {/* <span className="swap-chng-max">MAX</span> */}
                              <Dropdown
                                inline
                                placeholder="Select Coin"
                                options={options}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="algo_cen_left_expstr inp_hth_62">
                          <div className="algo_cen_twpov">
                            <div
                              className={
                                exceptStrategyref.current == "TWAP"
                                  ? "algo_cen_twap_active"
                                  : "algo_cen_pov_inactive"
                              }
                              onClick={() => handleStartegy("TWAP")}
                            >
                              <span>TWAP</span>
                            </div>
                            <div
                              className={
                                exceptStrategyref.current == "POV"
                                  ? "algo_cen_twap_active"
                                  : "algo_cen_pov_inactive"
                              }
                              onClick={() => handleStartegy("POV")}
                            >
                              <span>POV</span>
                            </div>
                          </div>
                          <span className="exception_strategy">
                            Exception Strategy
                          </span>
                        </div>
                        {exceptStrategyref.current == "TWAP" ? (
                          <>
                            <div className="algo_cen_left_expstr inp_hth_54">
                              <div className="algo_cen_left_exphrmain">
                                <input
                                  type="number"
                                  min="0"
                                  max="12"
                                  placeholder="00"
                                />
                                <span>Hours</span>
                                <input
                                  type="number"
                                  min="0"
                                  max="12"
                                  placeholder="00"
                                />
                                <span>Mins</span>
                              </div>
                              <span className="exception_strategy">
                                Exception Strategy
                              </span>
                            </div>
                            <div className="algo_cen_left_minut">
                              {times.map((time, index) => (
                                <div
                                  key={index}
                                  className={`algo_left_minut_man ${
                                    activeIndex === index ? "active" : ""
                                  }`}
                                  onClick={() => handleClick(index)}
                                >
                                  <span>{time}</span>
                                </div>
                              ))}
                              {/* <div className="algo_left_minut_man">
                            <span className="">30mins</span>
                          </div>
                          <div className="algo_left_minut_man">
                            <span className="">1hour</span>
                          </div>
                          <div className="algo_left_minut_man">
                            <span className="">6hours</span>
                          </div>
                          <div className="algo_left_minut_man">
                            <span className="">12hours</span>
                          </div> */}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="algo_cen_left_expstr inp_hth_62">
                              <div className="algo_cen_twpov">
                                <div
                                  className={
                                    exceptUrgencyref.current == "Low"
                                      ? "algo_cen_twap_active"
                                      : "algo_cen_pov_inactive"
                                  }
                                  onClick={() => handleUrgency("Low")}
                                >
                                  <span>Low</span>
                                </div>
                                <div
                                  className={
                                    exceptUrgencyref.current == "Medium"
                                      ? "algo_cen_twap_active"
                                      : "algo_cen_pov_inactive"
                                  }
                                  onClick={() => handleUrgency("Medium")}
                                >
                                  <span>Medium</span>
                                </div>
                                <div
                                  className={
                                    exceptUrgencyref.current == "High"
                                      ? "algo_cen_twap_active"
                                      : "algo_cen_pov_inactive"
                                  }
                                  onClick={() => handleUrgency("High")}
                                >
                                  <span>High</span>
                                </div>
                                <div
                                  className={
                                    exceptUrgencyref.current == "Urgent"
                                      ? "algo_cen_twap_active"
                                      : "algo_cen_pov_inactive"
                                  }
                                  onClick={() => handleUrgency("Urgent")}
                                >
                                  <span>Urgent</span>
                                </div>
                              </div>
                              <span className="exception_strategy">
                                Urgency
                              </span>
                            </div>
                            <div className="algo_cen_left_expstr inp_hth_54">
                              {/* <input
                                type="date"
                                min="0"
                                max="12"
                                placeholder="00"
                                className="algo_cen_left_expdateinp"
                              /> */}
                              <div className=" fr-rgt-cal-wrapper">
                                <DatePicker
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  className="algo_cen_left_expdateinp"
                                  placeholderText="00"
                                />
                              </div>
                              <span className="exception_strategy">
                                Order Expiry Time
                              </span>
                            </div>
                          </>
                        )}
                        <div className="algo_cen_left_expstr inp_hth_54">
                          {/* <input
                            type="date"
                            min="0"
                            max="12"
                            placeholder="00"
                            className="algo_cen_left_expdateinp"
                          /> */}
                          <div className=" fr-rgt-cal-wrapper">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              className="algo_cen_left_expdateinp"
                              placeholderText="00"
                            />
                          </div>
                          <span className="exception_strategy">
                            Delay Start Time
                          </span>
                        </div>
                        <div className="algo_cen_left_expstr inp_hth_54">
                          <input
                            type="number"
                            min="0"
                            max="12"
                            placeholder="00"
                            className="algo_cen_left_explimitpr"
                          />
                          <span className="exception_strategy">
                            Limit Price (USDT)
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="algo_lef_btn">
                      <button>Request</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 p-0">
                  <div className="algo_cen_right">
                    <div className="algo_cen_right_top">
                      <div className="algo_rig_top_lef">
                        {activeClassref.current == "bar" ? (
                          <>
                            <span
                              className={
                                timePeriodref.current == "24h"
                                  ? "algo_rightop_hractive"
                                  : "algo_rightop_hr_inactive"
                              }
                              onClick={() => handlePeriod("24h")}
                            >
                              24H
                            </span>
                            <span
                              className={
                                timePeriodref.current == "1w"
                                  ? "algo_rightop_hractive"
                                  : "algo_rightop_hr_inactive"
                              }
                              onClick={() => handlePeriod("1w")}
                            >
                              1W
                            </span>
                            <span
                              className={
                                timePeriodref.current == "1m"
                                  ? "algo_rightop_hractive"
                                  : "algo_rightop_hr_inactive"
                              }
                              onClick={() => handlePeriod("1m")}
                            >
                              1M
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="algo_rig_top_right">
                        {activeClassref.current == "bar" ? (
                          <img
                            src={require("../assets/icons/algo-bar-active-graph.webp")}
                            alt="algo trade"
                            // onClick={() => handleActive("bar")}
                          />
                        ) : (
                          <img
                            src={require("../assets/icons/algo-bar-graph.webp")}
                            alt="algo trade"
                            onClick={() => handleActive("bar")}
                          />
                        )}
                        <span>|</span>
                        {activeClassref.current == "notes" ? (
                          <img
                            src={require("../assets/icons/algo-notes-active-graph.webp")}
                            alt="algo trade"
                            // onClick={() => handleActive("notes")}
                          />
                        ) : (
                          <img
                            src={require("../assets/icons/algo-notes-graph.webp")}
                            alt="algo trade"
                            onClick={() => handleActive("notes")}
                          />
                        )}
                      </div>
                    </div>
                    <div className="algo_cen_right_bod">
                      {activeClassref.current == "bar" ? (
                        <img
                          src={require("../assets/otc-algo-chart.webp")}
                          alt="trade chart"
                          className="otc_algo_chartstat"
                        />
                      ) : (
                        <div className="algo_notes_maindiv">
                          <div className="algo_notes_topone">
                            <span className="algo_notes_topone_head">
                              Get started with Algo Orders:
                            </span>
                            <span className="algo_notes_top_subhead">
                              Algo Orders enhance the execution of large orders
                              and trading on pairs with low liquidity and reduce
                              the average cost of execution and market signaling
                              by executing orders in smaller blocks using an
                              intelligent execution algorithm. Binance also
                              supports trading TWAP via API.
                            </span>
                          </div>
                          <div className="algo_notes_bodyone">
                            <span className="algo_notes_bodyone_span">
                              1.Select from a list of trading pairs available.
                            </span>
                            <span className="algo_notes_bodyone_span">
                              2. Enter the asset amount you would like to buy or
                              sell. You can toggle between the base currency or
                              the alternate currency. The approximate required
                              asset amount and estimated fee amount will be
                              shown.
                            </span>
                            <span className="algo_notes_bodyone_span">
                              3. Select the order execution strategy for your
                              order. There are two types of execution
                              strategies: TWAP (Time-Weighted Average Price) and
                              POV (Percentage of Volume).
                            </span>
                            <span className="algo_notes_bodyone_span">
                              4.For TWAP, input your desired duration. For POV,
                              select your desired Urgency profile target. Please
                              refer to the tooltips and the below FAQ for more
                              detailed instructions.
                            </span>
                            <span className="algo_notes_bodyone_span">
                              5.You may specify an optional limit price for your
                              order.
                            </span>
                            <span className="algo_notes_bodyone_span">
                              6. Place your order. Once your order is
                              successfully placed, the assets required for your
                              order will be reserved for this execution while
                              the order is live.
                            </span>
                            <span className="algo_notes_bodyone_span">
                              7. View the ongoing status of your orders in the
                              Recent Orders section. If required, you may cancel
                              ongoing orders at anytime, and partially filled
                              orders will be booked and settled based on the
                              already executed amounts.
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="otc_spot_bottom_main">
                <div className="otc_bot_view_main">
                  <span className="otc_bot_ref_spa">Referral History</span>
                  <div className="otc_bot_view_head">
                    <span>View</span>
                    <i
                      class="ri-arrow-right-s-line"
                      style={{ color: "#ffc630" }}
                    ></i>
                  </div>
                </div>
                <div className="table-responsive table-cont otc_spot_tableup">
                  <table className="table">
                    <thead>
                      <tr className="stake-head font-satoshi">
                        <th>Order ID</th>
                        <th className="pad-left-23 txt-center">Symbol</th>
                        <th className="pad-left-23 txt-center">Direction</th>
                        <th className="pad-left-23 txt-center">Order Type</th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Price Limit
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Progress
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Average Price
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Filled / Total Amount
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          TWAP Remaining / Total Time
                        </th>
                        <th className="opt-nowrap table-action pad-left-23 pad-rght-tab">
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td colSpan={10} className="text-center py-5">
                          <div className="empty_data">
                            <div className="empty_data_img py-4">
                              <img
                                src={require("../assets/no-copytrade-data.webp")}
                                width="100px"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AlgoOtc;
