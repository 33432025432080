import React, { useEffect, useCallback, useRef } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import { socket } from "../context/socket";
import "./Trade.css";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import { toast } from "react-toastify";

// import {// toast} from "react-// toastify";
import { env } from "../core/service/envconfig";
import moment from "moment";
import Box from "@mui/material/Box";
import { widget } from "../core/lib/chart/charting_library/charting_library.min";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
// import PriceFooter from "./TradePage/PriceFooter";
import { capitalize } from "../core/lib/format";
import "./OptionalTrade.css";
import { greeksTabs, subHeadCoin } from "./optionaltradedata";
import InternalPopup from "./InternalPopup";
import Modal from "@mui/material/Modal";
import { Dropdown } from "semantic-ui-react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function OptionalTrade() {
  const [theme, setTheme] = useState("Dark");
  const [pair, setPair] = useState("BTC_USDT");

  const options = ["one", "two", "three"];
  const [pairlist, setpairlist] = useState([]);
  const [favpairlist, setfavpairlist] = useState([]);

  const [orderbookask, setorderbookask, orderbookaskref] = useState([]);
  const [orderbooksloader, setorderbooksloader, orderbooksloaderref] =
    useState(false);

  const [orderbookbid, setorderbookbid, orderbookbidref] = useState([]);
  const [currentlasprice, setcurrentlasprice] = useState("");
  const [searchInputlist, setsearchInputlist, searchInputlistref] = useState(
    []
  );
  const [value, setValue] = useState(1700.0);
  const [direction, setDirection] = useState(1);

  const [valueRed, setValueRed] = useState(9600);
  const [directionRed, setDirectionRed] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => prevValue + direction); // Increment or Decrement

      // Change direction when reaching limits (e.g., 1705 or 1695)
      if (value >= 1705) setDirection(-1);
      if (value <= 1695) setDirection(1);
    }, 1000); // Runs every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [value, direction]);

  useEffect(() => {
    const interval = setInterval(() => {
      setValueRed((prevValue) => prevValue + directionRed); // Increment or Decrement

      // Change direction when reaching limits (e.g., 1705 or 1695)
      if (valueRed >= 9595) setDirectionRed(-1);
      if (valueRed <= 9605) setDirectionRed(1);
    }, 1000); // Runs every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [valueRed, directionRed]);

  const [chartPair, setchartPair] = useState("BTCUSDT");
  const [curnt_Ordertype_tab, setcurnt_Ordertype_tab, curnt_Ordertype_tabref] =
    useState("Limit");
  const [currentPair, setcurrentPair] = useState("");
  const [fromCurrency, setFromCurrenncy] = useState("");
  const [toCurrency, setToCurrenncy] = useState("");
  const [currentType, setcurrentType, currentTyperef] = useState("buy");
  const [frombalance, setFromBalance, frombalanceref] = useState(0);
  const [tobalance, settobalance, tobalanceref] = useState(0);
  const [maxbuy, setmaxbuy, maxbuyref] = useState(0);
  const [maxsell, setmaxsell, maxsellref] = useState(0);
  const [checkAuth, setcheckAuth] = useState(false);
  const [pairDetails, setpairDetails] = useState("");
  const [Tocurrencies, setTocurrencies, Tocurrenciesref] = useState([
    "USDT",
    "INR",
    "BTC",
    "EUR",
    "ETH",
    "BNB",
    "XRP",
  ]);

  const [orderbookLoader, setorderbookLoader] = useState(true);
  const [orderbookLoaderbid, setorderbookLoaderbit] = useState(true);
  const [orderbookdivider, setorderbookdivider] = useState(true);
  const [pairLoader, setPairLoader] = useState(false);
  const [sideLoader, setsideLoader] = useState(false);

  const [perpage, setperpage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeOrderDatas, setActiveOders] = useState([]);
  const [tradeHistoryData, settradeHistory] = useState([]);
  // const [alltradeHistoryData, setalltradeHistory] = useState([]);
  const [cancelOrders, setcancelOrders] = useState([]);
  const [pairTickerDetails, setpairTickerDetails] = useState("");
  const [marketPrice, setmarketPrice, marketPriceref] = useState(0);
  const [marketTrade, setmarketTrade, marketTraderef] = useState([]);
  const [topmove, settopmove, topmoveref] = useState({});

  const [totalactive, settotalactive] = useState(0);
  const [currentPagecan, setCurrentPagecan] = useState(1);
  const [totalcan, settotalcan] = useState(0);
  const [currentPageHis, setcurrentPageHis] = useState(1);
  const [totalHist, settotalHist] = useState(0);
  const [authentication, setauthentication] = useState(false);
  const [searchpair, setsearchpair] = useState(null);
  const [currentpairs, setcurrentpairs, currentpairsref] = useState(null);
  const [allpairslist, setallpairslist, allpairslistref] = useState([]);
  const [orderloader, setorderloader, orderloaderref] = useState(false);
  const [sellorderloader, setsellorderloader, sellorderloaderref] =
    useState(false);

  const [siteLoader, setSiteLoader, siteLoaderref] = useState(false);
  const [priceLoader, setpriceLoader, priceLoaderref] = useState(false);
  const [fromcurrency, Setfromcurrency, fromcurrencyref] = useState(false);

  const [marketTradeloader, setmarketTradeloader, marketTradeloaderref] =
    useState(false);
  const [sliderValue, setSliderValue, sliderValueref] = useState(0);
  const [sliderValue1, setSliderValue1, sliderValue1ref] = useState(0);
  const [sliderValue2, setSliderValue2, sliderValue2ref] = useState(0);
  const [sliderValue3, setSliderValue3, sliderValue3ref] = useState(0);
  const [sliderValue4, setSliderValue4, sliderValue4ref] = useState(0);
  const [sliderValue5, setSliderValue5, sliderValue5ref] = useState(0);
  const [favpairs, setfavpairs, favpairsref] = useState([]);

  const [greekCoin, setGreekCoin] = useState("SOLUSDT");

  function valuetext(value) {
    return `${value}°C`;
  }
  const Fullorderbook = (event, newValue) => {
    setorderbookLoader(true);
    setorderbookLoaderbit(true);
    setorderbookdivider(true);
  };
  const bidorderbook = (event, newValue) => {
    setorderbookLoader(false);
    setorderbookLoaderbit(true);
    setorderbookdivider(false);
  };
  const askorderbook = (event, newValue) => {
    setorderbookLoader(true);
    setorderbookLoaderbit(false);
    setorderbookdivider(false);
  };

  const handleSliderChangeGeneric = (sliderIndex, event, newValue) => {
    setcurrentType(event.target.name);

    // Reset all sliders to "0" except the current one
    const newSliderValues = ["0", "0", "0", "0", "0", "0"];
    newSliderValues[sliderIndex] = newValue;

    // Set the correct slider state based on index
    setSliderValue(newSliderValues[0]);
    setSliderValue1(newSliderValues[1]);
    setSliderValue2(newSliderValues[2]);
    setSliderValue3(newSliderValues[3]);
    setSliderValue4(newSliderValues[4]);
    setSliderValue5(newSliderValues[5]);

    // Call the appropriate buy_sell_percentage function

    if (sliderIndex % 2 === 0) {
      buy_sell_percentage(newValue);
      buy_sell_percentage1("0");
    } else {
      buy_sell_percentage1(newValue);
      buy_sell_percentage("0");
    }
  };

  // Example of using this function in place of the others
  const handleSliderChange = (event, newValue) =>
    handleSliderChangeGeneric(0, event, newValue);
  const handleSliderChange1 = (event, newValue) =>
    handleSliderChangeGeneric(1, event, newValue);
  const handleSliderChange2 = (event, newValue) =>
    handleSliderChangeGeneric(2, event, newValue);
  const handleSliderChange3 = (event, newValue) =>
    handleSliderChangeGeneric(3, event, newValue);
  const handleSliderChange4 = (event, newValue) =>
    handleSliderChangeGeneric(4, event, newValue);
  const handleSliderChange5 = (event, newValue) =>
    handleSliderChangeGeneric(5, event, newValue);

  const recordPerPage = 5;
  const pageRange = 5;
  const recordPerPagecan = 5;
  const pageRangecan = 5;

  const recordPerPageHist = 5;
  const pageRangeHis = 5;

  const navigate = useNavigate();

  const tvWidget = null;

  useEffect(() => {
    var urls = window.location.href;
    var pair = urls.split("trade/")[1];
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();

    // console.log(changelower, "changelower");
    if (tvWidget !== null) {
      tvWidget.remove();
      tvWidget = null;
    }
    setorderbooksloader(true);
    check();
    socketinit();
    selectPairbyCurrency("USDT");
    selectfavPair();
  }, [socket]);

  async function check() {
    getmarketdata();

    let mecheck = await getAuthToken();

    var callapi = false;
    if (mecheck != "" && mecheck != undefined && mecheck != null) {
      await setcheckAuth(true);
      callapi = true;
    } else {
      await setcheckAuth(false);
      callapi = false;
    }
    var urls = window.location.href;
    var fetchPair = urls.split("trade/")[1];
    if (fetchPair) {
      setcurrentPair(fetchPair);
      var fromcurr = fetchPair.split("_")[0];
      var toCurr = fetchPair.split("_")[1];
      setFromCurrenncy(fromcurr);
      setToCurrenncy(toCurr);
      getCurrpairDatas(fetchPair);
      fetchTickerPrice(fetchPair);
      getMarketTrades(fetchPair);
      selectPair(fetchPair);

      if (callapi == true) {
        await getUserbalance(fetchPair);
        await getstopLimitOrders(1);
        await getActiveOrders(1, fetchPair);
        await tradeHistory(1);
        await getCancelOrders(1);
        await getfavpair();
      } else {
      }

      setchartPair(fromcurr + toCurr);
      setPair(fromcurr + "_" + toCurr);
      allpairDatas();
    } else {
      navigate("/");
    }
    socket.connect();
    let pair_string = fromcurr + toCurr;
    socket.emit("GetOrderBook", { symbol: pair_string.toLowerCase() });
  }

  const socketinit = async () => {
    // console.log("-0-0-0-0-0-0-0-")
    socket.on("OrderBook", async (response) => {
      var data = await response.data;
      if (data != null && data != undefined && data != "") {
        if (data.symbol) {
          setorderbookask([]);
          setorderbookbid([]);
          var orderbookbid = [];
          var orderbookask = [];
          orderbookbid = data["bids"].length == 0 ? [] : data["bids"];
          orderbookask = data["asks"].length == 0 ? [] : data["asks"];
          var askcumtotal = 0;
          for (let index = 0; index < orderbookask.length; index++) {
            var element = orderbookask[index];
            var multiply = element[0] * element[1];
            askcumtotal = parseFloat(askcumtotal) + parseFloat(multiply);
            orderbookask[index]["percent"] = (multiply / askcumtotal) * 100;
          }
          var bidcumtotal = 0;
          for (let index = 0; index < orderbookbid.length; index++) {
            var element = orderbookbid[index];
            var multiply = element[0] * element[1];
            bidcumtotal = parseFloat(bidcumtotal) + parseFloat(multiply);
            orderbookbid[index]["percent"] = (multiply / bidcumtotal) * 100;
          }

          const mergedOrderBook_ask = orderbookask.reduce((acc, record) => {
            const [price, amount, total] = record.map(Number); // Ensure all values are numbers
            const existingOrder = acc.find((order) => order[0] === price);

            if (existingOrder) {
              existingOrder[1] += amount; // Sum the amounts
              existingOrder[2] += total; // Sum the totals
            } else {
              acc.push([price, amount, total]); // Add the new record as is
            }
            return acc;
          }, []);

          const mergedOrderBook_bid = orderbookbid.reduce((acc, record) => {
            const [price, amount, total] = record.map(Number); // Ensure all values are numbers
            const existingOrder = acc.find((order) => order[0] === price);

            if (existingOrder) {
              existingOrder[1] += amount; // Sum the amounts
              existingOrder[2] += total; // Sum the totals
            } else {
              acc.push([price, amount, total]); // Add the new record as is
            }
            return acc;
          }, []);

          setorderbookask(
            // orderbookask.sort(function (a, b) {
            //   return b[0] - a[0];
            // })

            mergedOrderBook_ask.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
          setorderbookbid(
            // orderbookbid.sort(function (a, b) {
            //   return b[0] - a[0];
            // })
            mergedOrderBook_bid.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
          setorderbooksloader(false);
        }
      }
    });
    socket.on("TickerPrice", async (response) => {
      if (response.data) {
        var tickerdetail = response.data;
        setpairTickerDetails(tickerdetail);
        setmarketPrice(
          tickerdetail.lastprice.lastprice
            ? tickerdetail.lastprice.lastprice
            : 0.0
        );
        setmaxbuy(
          parseFloat(tobalanceref.current) / parseFloat(formValue.price)
        );
        setmaxsell(
          parseFloat(frombalanceref.current) * parseFloat(formValue.price)
        );
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            // formValue.price = "";
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
          //formValue.stop_price = "";
        } else if (curnt_Ordertype_tabref.current == "Market") {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        } else {
          if (priceLoaderref.current == false) {
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        }
      }
    });

    socket.on("TradeHistory", async (response) => {
      var tickerdetail = response.data;
      if (tickerdetail !== null) {
        setmarketTrade(tickerdetail);
      } else {
        if (marketTrade.length > 0) {
        }
      }
    });
    socket.on("TOPMOVE", async (response) => {
      var tickerdetail = response.data;
      if (tickerdetail !== null) {
        settopmove(tickerdetail);
      } else {
        settopmove({});
      }
    });
    let token_socket = sessionStorage.getItem("socketToken");
    if (token_socket) {
      let socketToken = token_socket.split('"_')[0];
      socket.on("userDetails" + socketToken, async (response) => {
        var urls = window.location.href;
        var fetchPair = urls.split("trade/")[1];
        if (fetchPair) {
          setcurrentPair(fetchPair);
          getActiveOrders(1, fetchPair);
          getUserbalance(fetchPair);
          tradeHistory(1);
          getCancelOrders(1);
          getMarketTrades(fetchPair);
        }
      });
      socket.on("cancelOrder" + socketToken, async (response) => {
        showsuccessToast(
          "Your order cancelled by admin, Please try again later"
        );
        var urls = window.location.href;
        var fetchPair = urls.split("trade/")[1];
        if (fetchPair) {
          setcurrentPair(fetchPair);
          getActiveOrders(1, fetchPair);
          getUserbalance(fetchPair);
          tradeHistory(1);
          getCancelOrders(1);
        }
      });
    }
    socket.on("close", function () {
      socket.connect();
      setorderbooksloader(true);
      socketinit();
    });
  };

  const [loginStatus, setLoginStatus] = useState(false);
  useEffect(() => {
    let token_check = sessionStorage.getItem("user_token");
    if (token_check) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
    }
  }, []);

  const loginNave = async () => {
    navigate("/login");
  };

  useEffect(() => {
    fetchTheme();
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  // const buildchart = (theme, pair) => {
  //   //console.log("theme ====",theme)
  //   theme = "dark";
  //   const widgetOptions = {
  //     symbol: pair,
  //     datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
  //       env.apiHost + "chartapi/chart"
  //     ),
  //     interval: pair == "USDT_INR" ? "240" : "1",
  //     container_id: "tv_chart_container",
  //     library_path: "/charting_library/",
  //     locale: getLanguageFromURL() || "en",
  //     disabled_features: ["use_localstorage_for_settings"],
  //     enabled_features: ["study_templates"],
  //     charts_storage_url: "",
  //     charts_storage_api_version: "1.1",
  //     client_id: "tradingview.com",
  //     user_id: "public_user_id",
  //     fullscreen: false,
  //     //autosize: true,
  //     width: "100%",
  //     height: "518",
  //     studies_overrides: {},
  //     loading_screen: { backgroundColor: "#17171A" },
  //     theme: theme,
  //     toolbar_bg: "#17171A",
  //     timezone: "Asia/Kolkata",
  //     overrides: {
  //       "paneProperties.background": "#17171A",
  //       "paneProperties.vertGridProperties.color": "transparent",
  //       "paneProperties.horzGridProperties.color": "transparent",
  //     },
  //   };

  //   if (theme == "White") {
  //     delete widgetOptions.toolbar_bg;
  //     delete widgetOptions.overrides;
  //   }

  //   const tvWidget = new widget(widgetOptions);

  //   tvWidget.onChartReady(() => {
  //     tvWidget.headerReady().then(() => {
  //       const button = tvWidget.createButton();
  //       button.setAttribute("title", "Click to show a notification popup");
  //       button.classList.add("apply-common-tooltip");
  //       button.addEventListener("click", () =>
  //         tvWidget.showNoticeDialog({
  //           title: "Notification",
  //           body: "TradingView Charting Library API works correctly",
  //           callback: () => {
  //             console.log("Noticed!");
  //           },
  //         })
  //       );
  //     });
  //   });
  // };

  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "light") {
        setTheme("White");
      } else {
        setTheme("Dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      setTheme("Dark");
    }

    let urls = window.location.href;
    let fetchPair = urls.split("trade/")[1];
    if (fetchPair != null) {
      let themeValue = "Dark";
      if (theme == "light") {
        themeValue = "White";
      } else if (theme == "dark") {
        themeValue = "Dark";
      }
      // buildchart(themeValue, fetchPair);
    }
  };

  const selectPair = async (pair) => {
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();
    socket.emit("GetOrderBook", { symbol: changelower });
    setchartPair(replace_string);
    setPair(pair);
  };

  const Movelogin = () => {
    navigate("/login");
  };

  const getCurrpairDatas = async (pair) => {
    var data = {
      apiUrl: apiService.getCurrpairData,
      payload: { pair: pair },
    };
    var fetchticker = await postMethod(data);
    if (fetchticker) {
      setpairDetails(fetchticker.data);
      Setfromcurrency(fetchticker.fromCurr);
    }
  };

  const getmarketdata = async () => {
    var data = {
      apiUrl: apiService.getmarketData,
    };
    var fetchticker = await getMethod(data);
    if (fetchticker.status) {
      setTocurrencies(fetchticker.data);
    }
  };

  const [selectedmarket, setselectedmarket, selectedmarketref] =
    useState("USDT");

  const [moves, setmoves, movesref] = useState("all");

  const selectPairbyCurrency = async (curr) => {
    setPairLoader(true);
    setselectedmarket(curr);
    setsideLoader(true);
    socket.off("DashTickerPrice");
    socket.emit("GetTickerPrice", "getall");
    var data = {
      apiUrl: apiService.pairbycurrency,
      payload: { currency: curr },
    };

    var pairdetail = await postMethod(data);
    if (pairdetail) {
      socket.on("DashTickerPrice", async (response) => {
        setPairLoader(false);
        setsideLoader(false);
        var tickarr = await response.data;

        // console.log(response.data, "tickers ---response");
        for (let index = 0; index < pairdetail.data.length; index++) {
          const element = pairdetail.data[index];
          var replace_string = element.pair.replace("_", "");
          var changelower = replace_string.toLowerCase();
          if (tickarr[changelower]) {
            var tickobj = JSON.parse(tickarr[changelower]);
            if (tickarr) {
              if (element.pair == tickobj.pair) {
                pairdetail.data[index]["price_change"] =
                  (await tickobj.change_percent)
                    ? parseFloat(tickobj.change_percent).toFixed(4)
                    : 0.0;
                pairdetail.data[index]["lastprice"] = (await tickobj.lastprice
                  .lastprice)
                  ? parseFloat(tickobj.lastprice.lastprice).toFixed(
                      element.liq_price_decimal
                    )
                  : 0.0;
              }
            }
          }
        }
      });

      await setsearchInputlist(pairdetail.data);
      if (searchpair != null) {
        setpairlist(
          searchInputlistref.current.filter(function (tag) {
            if (
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0 ||
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0
            ) {
              return tag;
            }
          })
        );
      } else {
        await setpairlist(pairdetail.data);
        await setsearchInputlist(pairdetail.data);
        setPairLoader(false);
        setsideLoader(false);
      }
    }
  };

  const selectfavPair = async () => {
    try {
      setPairLoader(true);
      setsideLoader(true);

      // Clear previous socket listeners and fetch ticker price data
      socket.off("DashTickerPrice");
      socket.emit("GetTickerPrice", "getall");

      // Fetch all pairs
      const data = {
        apiUrl: apiService.allpair,
      };
      const pairdetail = await postMethod(data);

      // Fetch favorite pairs
      getfavpair();

      if (pairdetail && pairdetail.data) {
        let favoritePairs = [];

        // Wait for real-time ticker data
        socket.on("DashTickerPrice", async (response) => {
          setPairLoader(false);
          setsideLoader(false);

          const tickarr = await response.data;

          // Filter pairs by favorites
          favoritePairs = pairdetail.data.filter((pair) => {
            // Remove the underscore from pair.pair to match the favpairs format if needed
            const formattedPair = pair.pair.replace("_", "");

            // console.log(pair, favpairsref.current);

            // Check if the formatted pair exists in favpairs
            return (
              favpairsref.current?.includes(pair.pair) ||
              favpairs?.includes(formattedPair)
            );
          });

          // Update favorite pairs with real-time ticker data
          favoritePairs = favoritePairs.map((pair) => {
            const replace_string = pair.pair.replace("_", "");
            const changelower = replace_string.toLowerCase();

            // Update with ticker values if available
            if (tickarr[changelower]) {
              const tickobj = JSON.parse(tickarr[changelower]);

              pair["price_change"] = tickobj.change_percent
                ? parseFloat(tickobj.change_percent).toFixed(4)
                : 0.0;
              pair["lastprice"] = tickobj.lastprice?.lastprice
                ? tickobj.lastprice.lastprice
                : 0.0;
            }

            return pair;
          });

          // Apply search filter if search input is provided
          if (searchpair != null) {
            favoritePairs = favoritePairs.filter((pair) =>
              pair.liquidity_name
                .toLowerCase()
                .includes(searchpair.toLowerCase())
            );
          }

          // Set favorite pairs list to state

          setfavpairlist(favoritePairs);

          // Turn off loaders after data is set
          setPairLoader(false);
          setsideLoader(false);
        });
      } else {
        setPairLoader(false);
        setsideLoader(false);
        console.error("Error fetching pair details");
      }
    } catch (error) {
      console.error("Error in selectfavPair:", error);
      setPairLoader(false);
      setsideLoader(false);
    }
  };

  async function handleInputChange(event) {
    event.preventDefault();

    const sanitizedValue = event.target.value.replace(/\s+/g, "");

    if (sanitizedValue.indexOf("_") > 0) {
      var searchval = sanitizedValue.replace("_", "");
      setcurrentpairs(sanitizedValue);
      pair_change();
      setpairlist(
        allpairslistref.current.filter(function (tag) {
          if (
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
              0 ||
            tag.liquidity_name.toLowerCase().indexOf(searchval.toLowerCase()) >=
              0
          ) {
            return tag;
          }
        })
      );
    } else {
      setsearchpair(sanitizedValue);
      setpairlist(
        searchInputlistref.current.filter(function (tag) {
          if (
            tag.liquidity_name
              .toLowerCase()
              .indexOf(sanitizedValue.toLowerCase()) >= 0 ||
            tag.liquidity_name
              .toLowerCase()
              .indexOf(sanitizedValue.toLowerCase()) >= 0
          ) {
            return tag;
          }
        })
      );
    }
  }

  //------trade forms--------//

  const pairChange = async (pair) => {
    try {
      setPairLoader(true);
      navigate("/trade/" + pair.pair);
      setcurrentPair(pair.pair);
      getCurrpairDatas(pair.pair);

      var indexPage = pairlist.findIndex((x) => x.pair == pair.pair);

      if (indexPage != -1) {
        var getPair = pairlist[indexPage];
        var fromcurr = getPair.pair.split("_")[0];
        var toCurr = getPair.pair.split("_")[1];
        setFromCurrenncy(fromcurr);
        setToCurrenncy(toCurr);
        if (checkAuth === true) {
          getUserbalance(pair.pair);
          getCancelOrders(1);
          getActiveOrders(1, pair.pair);
        }
        getCurrpairDatas(getPair.pair);
        fetchTickerPrice(getPair.pair);
        getMarketTrades(getPair.pair);
        setPair(getPair.pair);
        setPairLoader(false);
        var themevalue =
          localStorage.getItem("theme") == "light" ? "White" : "Dark";

        // buildchart("dark", getPair.pair);

        var replace_string = getPair.pair.replace("_", "");
        var changelower = replace_string.toLowerCase();
        socket.emit("GetOrderBook", { symbol: changelower });
        setchartPair(replace_string);
      }
    } catch (error) {}
  };

  const favpair = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.addfavpairs,
        payload: { pair: pair.pair },
      };
      var fetchticker = await postMethod(data);
      if (fetchticker.status) {
        showsuccessToast(fetchticker.message);
        getfavpair();
        selectfavPair();
      } else {
        showerrorToast(fetchticker.message);
      }
    } catch (error) {}
  };

  const getfavpair = async () => {
    try {
      var data = {
        apiUrl: apiService.getfavpairs,
      };
      var fetchticker = await getMethod(data);
      if (fetchticker.status) {
        setfavpairs(fetchticker.data);
      } else {
        setfavpairs([]);
      }
    } catch (error) {}
  };

  const getUserbalance = async (pair) => {
    var obj = {
      pair: pair,
    };
    var data = {
      apiUrl: apiService.getparUserBalance,
      payload: obj,
    };
    setSiteLoader(true);
    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      // var getFromBalance = resp.data.fromCurrency;
      // var getToBalance = resp.data.toCurrency;
      // setFromBalance(getFromBalance.totalBalance);
      // settobalance(getToBalance.totalBalance);
    } else {
    }
  };

  const pair_change = async () => {
    try {
      if (currentpairsref.current.indexOf("_") > 0) {
        var pairname = currentpairsref.current;
        var indexPage = pairlist.findIndex((x) => x.pair == pairname);
        if (indexPage != -1) {
          setPairLoader(true);
          navigate("/trade/" + pairname);
          setcurrentPair(pairname);
          getCurrpairDatas(pairname);
          var getPair = pairlist[indexPage];
          var fromcurr = getPair.pair.split("_")[0];
          var toCurr = getPair.pair.split("_")[1];
          setFromCurrenncy(fromcurr);
          setToCurrenncy(toCurr);
          if (checkAuth === true) {
            getUserbalance(pair.pair);
            getCancelOrders(1);
            getActiveOrders(1, pair.pair);
          }
          getCurrpairDatas(getPair.pair);
          // fetchTickerPrice(getPair.pair);
          getMarketTrades(getPair.pair);
          setPair(getPair.pair);
          setPairLoader(false);
        }
      }
    } catch (error) {}
  };

  const allpairDatas = async () => {
    var data = {
      apiUrl: apiService.allpairs,
    };
    var allpairs = await getMethod(data);
    if (allpairs) {
      setallpairslist(allpairs.data);
    }
  };

  const initialFormValue = {
    price: "",
    amount: "",
    total: "",
    stop_price: "",
    sellprice: "",
    sellamount: "",
    selltotal: "",
    sellstop_price: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();

  const {
    price,
    amount,
    total,
    stop_price,
    sellprice,
    sellamount,
    selltotal,
    sellstop_price,
  } = formValue;
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (/[eE\+\-]/.test(pasteData)) {
      e.preventDefault();
    }
  };
  const handleKeyDown = (e) => {
    // List of allowed keys (like Backspace, Delete, Tab, Arrow keys, etc.)
    const allowedKeys = [
      "Backspace",
      "Delete",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // If the pressed key is NOT in allowedKeys and matches e, E, +, or -, prevent the input
    if (!allowedKeys.includes(e.key) && /[eE\+\-]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Check for invalid characters in input value
    if (/[eE\+\-]/.test(value)) {
      return;
    }

    // Shallow clone formData for local manipulation
    let formData = { ...formValue, [name]: value };

    // Perform upfront validations to avoid unnecessary calculations
    const price = parseFloat(formData.price || 0);
    const amount = parseFloat(formData.amount || 0);
    const sellPrice = parseFloat(formData.sellprice || 0);
    const sellAmount = parseFloat(formData.sellamount || 0);

    if (price < 0 || amount < 0 || sellPrice < 0 || sellAmount < 0) {
      if (price < 0 || sellPrice < 0) showerrorToast("Enter valid price");
      if (amount < 0 || sellAmount < 0) showerrorToast("Enter valid amount");
      return;
    }

    let totalPrice = 0;

    // Consolidate logic for calculating total
    if (sellAmount > 0) {
      totalPrice = sellPrice * sellAmount;
      formData.selltotal = totalPrice.toFixed(8);
    } else {
      totalPrice = price * amount;
      formData.total = totalPrice.toFixed(8);
    }

    setFormValue(formData);
  };

  const [active1Tab, setActive1Tab] = useState("currency"); // Default tab: currency

  const handleTabClick = (tabType) => {
    setActive1Tab(tabType);
  };

  const handleChange_total = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    // console.log("total value===", value);

    let formData = { ...formValue, ...{ [name]: value } };
    if (curnt_Ordertype_tabref.current == "Limit") {
      let amount = value / formData.price;
      formData = {
        ...formData,
        ...{ ["total"]: parseFloat(value) },
        ...{ ["amount"]: parseFloat(amount).toFixed(8) },
      };
    } else if (curnt_Ordertype_tabref.current == "Market") {
      let amount = value / +marketPriceref.current;
      formData = {
        ...formData,
        ...{ ["total"]: parseFloat(value) },
        ...{ ["amount"]: parseFloat(amount).toFixed(8) },
      };
    } else {
      let amount = value / formData.price;
      formData = {
        ...formData,
        ...{ ["total"]: parseFloat(value) },
        ...{ ["amount"]: parseFloat(amount).toFixed(8) },
      };
    }

    if (formData.price < 0) {
      showerrorToast("Enter valid price");
    }
    if (formData.amount < 0) {
      showerrorToast("Enter valid amount");
    }
    if (formData.total < 0) {
      showerrorToast("Enter valid total");
    }
    // console.log("formData==", formData);
    setFormValue(formData);
  };

  const orderPlace = async (ordertype, ordertab) => {
    try {
      const { amount, price, stop_price } = formValue;
      const liqAmountDecimal = pairDetails?.liq_amount_decimal;
      const liqPriceDecimal = pairDetails?.liq_price_decimal;

      const isValidAmount = parseFloat(amount) > 0;
      let checkPrice = 0;

      if (ordertype === "Stop") {
        if (!stop_price || isNaN(stop_price)) {
          showerrorToast("Invalid stop limit price");
          return;
        }
      }

      if (ordertype === "Limit" || ordertype === "Stop") {
        checkPrice = parseFloat(price);
      } else if (ordertype === "Market") {
        checkPrice = parseFloat(marketPrice);
      }

      // Validate essential fields
      if (!isValidAmount || checkPrice <= 0 || !total) {
        showerrorToast("Please fill in all fields correctly.");
        return;
      }

      // Prepare orderData object
      let orderData = {
        amount: parseFloat(amount).toFixed(liqAmountDecimal),
        type: ordertab,
        orderType: ordertype,
        fromCurrency,
        toCurrency,
        pair: currentPair,
        stop_price: 0, // Default stop_price
        pair_id: pairDetails._id,
        fromCurr_id: pairDetails.from_symbol_id,
        toCurr_id: pairDetails.to_symbol_id,
      };

      switch (ordertype) {
        case "Limit":
          orderData.price = parseFloat(price).toFixed(liqPriceDecimal);
          orderData.total = parseFloat(total).toFixed(liqPriceDecimal);
          break;

        case "Market":
          const formattedMarketPrice =
            parseFloat(marketPrice).toFixed(liqPriceDecimal);
          orderData.price = formattedMarketPrice;
          orderData.total = (marketPrice * parseFloat(amount)).toFixed(
            liqPriceDecimal
          );
          break;

        case "Stop":
          orderData.price = parseFloat(price).toFixed(liqPriceDecimal);
          orderData.total = (parseFloat(price) * parseFloat(amount)).toFixed(
            liqPriceDecimal
          );
          orderData.stop_price =
            parseFloat(stop_price).toFixed(liqPriceDecimal);
          break;

        default:
          showerrorToast("Invalid order type");
          return;
      }

      const data = {
        apiUrl: apiService.orderPlaceapi,
        payload: orderData,
      };

      setorderloader(true);
      const resp = await postMethod(data);
      setorderloader(false);

      if (resp.status) {
        resetFormValues();
        fetchTickerPrice(currentPair);
        getActiveOrders(1, currentPair);
        getUserbalance(currentPair);
        showsuccessToast(resp.Message);
      } else {
        handleOrderFailure(resp.Message);
      }
    } catch (error) {
      showerrorToast("Something went wrong. Please try again later.");
    }
  };

  const resetFormValues = () => {
    setFormValue(initialFormValue);
    setSliderValue("0");
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");
  };

  // Handle order failure separately for reusability
  const handleOrderFailure = (message) => {
    formValue.amount = "";
    fetchTickerPrice(currentPair);
    formValue.total = "";
    formValue.stop_price = "";
    showerrorToast(message);
  };

  const sellorderPlace = async (ordertype, ordertab) => {
    try {
      const { sellamount, sellprice, sellstop_price } = formValue;
      const liqPriceDecimal = pairDetails?.liq_price_decimal || 2;
      const liqAmountDecimal = pairDetails?.liq_amount_decimal || 2;

      const parsedSellAmount = parseFloat(sellamount);
      const parsedSellPrice = parseFloat(sellprice);
      const parsedSellStopPrice = parseFloat(sellstop_price);

      // Validate form values before proceeding
      if (
        parsedSellAmount <= 0 ||
        (ordertype !== "Market" && parsedSellPrice <= 0) ||
        !selltotal
      ) {
        return showerrorToast("Please fill in all fields correctly.");
      }

      // Stop order requires a valid stop price
      if (
        ordertype === "Stop" &&
        (!sellstop_price || isNaN(parsedSellStopPrice))
      ) {
        return showerrorToast("Invalid stop limit price.");
      }

      // Create order data based on order type
      const orderData = {
        amount: parsedSellAmount.toFixed(liqAmountDecimal),
        price:
          ordertype === "Market"
            ? parseFloat(marketPrice).toFixed(liqPriceDecimal)
            : parseFloat(parsedSellPrice).toFixed(liqPriceDecimal),
        total: (ordertype === "Market"
          ? marketPrice * parsedSellAmount
          : parseFloat(selltotal)
        ).toFixed(liqPriceDecimal),
        stop_price:
          ordertype === "Stop"
            ? parsedSellStopPrice.toFixed(liqPriceDecimal)
            : 0,
        type: ordertab,
        orderType: ordertype,
        fromCurrency,
        toCurrency,
        pair: currentPair,
        pair_id: pairDetails._id,
        fromCurr_id: pairDetails.from_symbol_id,
        toCurr_id: pairDetails.to_symbol_id,
      };

      const data = {
        apiUrl: apiService.orderPlaceapi,
        payload: orderData,
      };

      setsellorderloader(true); // Start loader

      const resp = await postMethod(data); // API call

      setsellorderloader(false); // Stop loader

      // Reset form and slider values regardless of the response
      resetSellFormValues();

      // Handle API response
      if (resp.status) {
        showsuccessToast(resp.Message);
        // Fetch necessary data asynchronously after placing the order
        Promise.all([
          fetchTickerPrice(currentPair),
          getActiveOrders(1, currentPair),
          getUserbalance(currentPair),
        ]);
      } else {
        showerrorToast(resp.Message);
      }
    } catch (error) {
      setsellorderloader(false); // Ensure the loader stops on error
      showerrorToast("Something went wrong. Please try again later.");
    }
  };

  // Helper function to reset form and slider values
  const resetSellFormValues = () => {
    formValue.sellamount = "";
    formValue.sellprice = "";
    formValue.selltotal = "";
    formValue.sellstop_price = "";

    setSliderValue("0");
    setSliderValue1("0");
    setSliderValue2("0");
    setSliderValue3("0");
    setSliderValue4("0");
    setSliderValue5("0");
  };

  const [activeTab, setActiveTab] = useState("mytrade"); // Default to 'mytrade'
  const [historyactiveTab, sethistoryActiveTab] = useState("openorders"); // Default to 'mytrade'
  const [spottab, setSpotTab] = useState("limit"); // Default to 'mytrade'

  const buy_sell_percentage = (percentage) => {
    // Check if the user is authenticated
    if (!checkAuth) {
      return;
    }

    // Get references
    const currentType = currentTyperef.current;

    let total = 0;
    let amount = 0;

    if (currentType === "buy") {
      total = (+percentage * +tobalance) / 100;
      amount = total / +formValue.price;

      formValue.amount = amount.toFixed(8); // Use 8 decimals
      formValue.total = +total.toFixed(8);
    } else if (currentType === "sell") {
      total = (+percentage * +frombalance) / 100;
      const tot = total * +formValue.price;

      formValue.amount = total.toFixed(8); // Use 8 decimals
      formValue.total = +tot.toFixed(8);
    }
  };

  const buy_sell_percentage1 = (percentage) => {
    // Ensure the user is authenticated
    if (!checkAuth) {
      return;
    }

    // Get necessary values
    const currentType = currentTyperef.current;

    let total = 0;
    let amount = 0;

    if (currentType === "buy") {
      total = (+percentage * +tobalance) / 100;
      amount = total / +formValue.sellprice;

      formValue.sellamount = amount.toFixed(8);
      formValue.selltotal = +total.toFixed(8);
    } else if (currentType === "sell") {
      total = (+percentage * +frombalance) / 100;
      const tot = total * +formValue.sellprice;

      formValue.sellamount = total.toFixed(8);
      formValue.selltotal = +tot.toFixed(8);
    }
  };

  const getActiveOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getActiveOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settotalactive(resp.count);
        setActiveOders(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const getstopLimitOrders = async (pages, getpair) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: getpair,
      };
      var data = {
        apiUrl: apiService.getStop_limit_Orders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        // settotalactive(resp.count);
        // console.log(resp.result);
      } else {
      }
    } catch (error) {}
  };

  const tradeHistory = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: pairDetails.pair,
      };
      var data = {
        apiUrl: apiService.tradeHistory,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        settradeHistory(resp.result);
        settotalHist(resp.count);
      } else {
      }
    } catch (error) {}
  };

  const getCancelOrders = async (pages) => {
    try {
      var obj = {
        FilPerpage: perpage,
        FilPage: pages,
        pair: currentPair,
      };
      var data = {
        apiUrl: apiService.getCancelOrders,
        payload: obj,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcancelOrders(resp.result);
        settotalcan(resp.count);
      } else {
      }
    } catch (error) {}
  };

  //========FETCH TICKER PRICE ==========//

  const fetchTickerPrice = async (pair) => {
    // console.log("fetchTickerPrice pair===", pair);
    try {
      var data = {
        apiUrl: apiService.fetch_tickers,
        payload: { pair: pair },
      };
      var fetchticker = await postMethod(data);
      if (fetchticker) {
        var data = await fetchticker.data;
        // console.log("fetchTickerPrice data====", data);
        setpairTickerDetails(data);
        setmarketPrice(
          data.lastprice.lastprice ? data.lastprice.lastprice : 0.0
        );

        // console.log(data.lastprice.lastprice, "fetchticket");
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            // formValue.price = "";
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        } else if (
          curnt_Ordertype_tabref.current == "Market" ||
          curnt_Ordertype_tabref.current == "Limit"
        ) {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        }
      }
    } catch (error) {}
  };

  // ================FETCH MARKET =============//

  const getMarketTrades = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.marketTrades,
        payload: { pair: pair },
      };
      // setauthentication(true);
      setmarketTradeloader(true);
      var fetchTradeHisotory = await postMethod(data);
      // setauthentication(false);
      if (fetchTradeHisotory) {
        if (fetchTradeHisotory.status) {
          setmarketTradeloader(false);
          setmarketTrade(fetchTradeHisotory.Message);
        } else {
          setmarketTradeloader(false);
          setmarketTrade([]);
        }
      } else {
      }
    } catch (error) {}
  };
  const activePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // call API to get data based on pageNumber
    getActiveOrders(pageNumber, "");
  };

  const cancelPageChange = (pageNumber) => {
    setCurrentPagecan(pageNumber); // call API to get data based on pageNumber
    getCancelOrders(pageNumber);
  };

  const orderhistoryactive = (pageNumber) => {
    setcurrentPageHis(pageNumber); // call API to get data based on pageNumber
    tradeHistory(pageNumber);
  };

  const orderCancel = async (cancelDatas) => {
    try {
      var obj = {
        _id: cancelDatas._id,
      };
      var data = {
        apiUrl: apiService.cancelOrder,
        payload: obj,
      };
      var fetchTradeHisotory = await postMethod(data);
      if (fetchTradeHisotory) {
        showsuccessToast(
          "Order cancelled successfully, your amount credit to your wallet"
        );
        getActiveOrders(1, currentPair);
        getUserbalance(currentPair);
      } else {
        showerrorToast("Please try again later");
      }
    } catch (error) {
      showerrorToast("Please try again later");
    }
  };

  const callCancelOrder = async () => {
    if (checkAuth === true) {
      getCancelOrders(1);
    }
  };
  const callOrdehistory = async () => {
    if (checkAuth === true) {
      tradeHistory(1);
    }
  };

  const addPrice = async (price) => {
    setpriceLoader(true);
    if (curnt_Ordertype_tabref.current == "Limit") {
      formValue.price = parseFloat(price).toFixed(
        pairDetails?.liq_price_decimal
      );
      formValue.sellprice = parseFloat(price).toFixed(
        pairDetails?.liq_price_decimal
      );
    }
    // console.log(formValue, "-=-=-form value=-=-");
  };

  const renderInput = (label, name, value, currency, onChange) => (
    <div className="form_right">
      <label>{label}</label>
      <div className="input_section">
        <input
          type="number"
          min="0"
          className="form-control"
          placeholder="0.00"
          name={name}
          value={value}
          onChange={onChange}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
        />
        <span>{currency}</span>
      </div>
    </div>
  );

  const rendermarketInput = (label, name, value, currency, onChange) => (
    <div className="form_right">
      <label>{label}</label>
      <div className="input_section">
        <input
          type="number"
          min="0"
          className="form-control"
          placeholder="0.00"
          name={name}
          value={value}
          onChange={onChange}
          onPaste={handlePaste}
          onKeyDown={handleKeyDown}
          disabled
        />
        <span>{currency}</span>
      </div>
    </div>
  );

  const handleBuy = useCallback(() => {
    if (checkAuth) orderPlace("Limit", "buy");
    else Movelogin();
  }, [checkAuth, orderPlace, Movelogin]);

  const handleSell = useCallback(() => {
    if (checkAuth) sellorderPlace("Limit", "sell");
    else Movelogin();
  }, [checkAuth, sellorderPlace, Movelogin]);

  const handlemarketBuy = useCallback(() => {
    if (checkAuth) orderPlace("Market", "buy");
    else Movelogin();
  }, [checkAuth, orderPlace, Movelogin]);

  const handlemarketSell = useCallback(() => {
    if (checkAuth) sellorderPlace("Market", "sell");
    else Movelogin();
  }, [checkAuth, sellorderPlace, Movelogin]);

  const handlestopBuy = useCallback(() => {
    if (checkAuth) orderPlace("Stop", "buy");
    else Movelogin();
  }, [checkAuth, orderPlace, Movelogin]);

  const handlestopSell = useCallback(() => {
    if (checkAuth) sellorderPlace("Stop", "sell");
    else Movelogin();
  }, [checkAuth, sellorderPlace, Movelogin]);

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const changeOrderTab = (type) => {
    setSpotTab(type);
    formValue.amount = "";
    formValue.sellamount = "";
    formValue.total = "";
    formValue.selltotal = "";
  };

  const [headCurrencies, setHeadCurrencies] = useState("SOLUSDT");

  const handleCurrencyClick = (tab) => {
    setHeadCurrencies(tab);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGreekTab = (coin) => {
    setGreekCoin(coin);
  };

  const [isFormHead, setIsFormHead] = useState("open");
  const [isFormHeadSecond, setIsFormHeadSecond] = useState("open");
  const [isFormLimit, setIsFormLimit] = useState("limit");
  const [isFormLimitSecond, setIsFormLimitSecond] = useState("limit");

  const handleFormHead = (order) => {
    setIsFormHead(order);
  };

  const handleFormHeadSecond = (order) => {
    setIsFormHeadSecond(order);
  };

  const handleLimit = (order) => {
    setIsFormLimit(order);
  };

  const handleLimitSecond = (order) => {
    setIsFormLimitSecond(order);
  };

  const optionsOpen = [
    { text: "GTC", value: "GTC" },
    { text: "IOC", value: "IOC" },
    { text: "FOK", value: "FOK" },
  ];

  // subheader arrow functionality
  const sliderRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleRightClick = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: 50,
        behavior: "smooth",
      });
    }
  };

  const handleLeftClick = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({
        left: -50,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      sliderElement.addEventListener("scroll", handleScroll);
    }
    handleScroll();

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [sliderRef.current]);

  const aroundATMOptions = [
    { text: "2", value: "2" },
    { text: "3", value: "3" },
    { text: "4", value: "4" },
    { text: "5", value: "5" },
    { text: "6", value: "6" },
    { text: "7", value: "7" },
  ];

  const strikeFromOptions = [
    { text: "520", value: "520" },
    { text: "540", value: "540" },
    { text: "550", value: "550" },
    { text: "570", value: "570" },
    { text: "580", value: "580" },
    { text: "590", value: "590" },
  ];

  const strikeToOptions = [
    { text: "520", value: "520" },
    { text: "540", value: "540" },
    { text: "550", value: "550" },
    { text: "570", value: "570" },
    { text: "580", value: "580" },
    { text: "590", value: "590" },
  ];

  // fav left arrow
  const favSliderRef = useRef(null);
  const [FavLeftArrow, setFavLeftArrow] = useState(false);
  const [FavRightArrow, setFavRightArrow] = useState(true);

  const handleRightFavClick = () => {
    if (favSliderRef.current) {
      favSliderRef.current.scrollBy({
        left: 50,
        behavior: "smooth",
      });
    }
  };

  const handleLeftFavClick = () => {
    if (favSliderRef.current) {
      favSliderRef.current.scrollBy({
        left: -50,
        behavior: "smooth",
      });
    }
  };

  const handleFavScroll = () => {
    if (favSliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = favSliderRef.current;
      setFavLeftArrow(scrollLeft > 0);
      setFavRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const favSliderElement = favSliderRef.current;
    if (favSliderElement) {
      favSliderElement.addEventListener("scroll", handleFavScroll);
    }
    handleFavScroll();

    return () => {
      if (favSliderElement) {
        favSliderElement.removeEventListener("scroll", handleFavScroll);
      }
    };
  }, [favSliderRef.current]);

  const [isCheckShow, setIsCheckShow] = useState(false);

  const handleCheckClick = () => {
    setIsCheckShow(!isCheckShow);
  };

  const [isStrikeShow, setIsStrikeShow] = useState(false);

  const handleStrikeClick = () => {
    setIsStrikeShow(!isStrikeShow);
  };

  const [iconTab, setIconTab] = useState("chart");
  const [showTrade, setShowTrade] = useState("chart");
  const [tradeState, setTradestate, tradeStateref] = useState("chart");

  const handleIconClick = (tab) => {
    setIconTab(tab);
    setTradestate(tab);
    setShowTrade(tab);
  };

  const [activeTabhead, setActiveTabHead, activeTabheadref] =
    useState("position");

  const handleTabs = async (value) => {
    setActiveTabHead(value);
  };

  return (
    <>
      <Header />

      <div className="new-popup-height container">
        <div className="header">
          <main className="min-height-100vh ">
            <div className="row opt_new_mainn">
              <div className="col-lg-9 opt_new_mainn_lef p-0">
                <div className="op-subHeader-one">
                  {/* graph tabs */}
                  <ul className="nav nav-pills op-subHeader-icon">
                    <li
                      onClick={() => handleIconClick("chart")}
                      className={`icon-list ${
                        iconTab === "chart" ? "active" : ""
                      }`}
                    >
                      <span className={`op-grid-icon`}>
                        <i className="bi bi-grid-1x2-fill"></i>
                      </span>
                    </li>
                    <li
                      onClick={() => handleIconClick("candles")}
                      className={`icon-list ${
                        iconTab === "candles" ? "active" : ""
                      }`}
                    >
                      <span className="op-grid-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          enableBackground="new 0 0 100 100"
                          version="1.0"
                          viewBox="0 0 100 100"
                          width="22px"
                          height="22px"
                          fill={iconTab === "candles" ? "#ffd700" : "#8b8b8c"}
                        >
                          <g id="candlestick-chart-fluctuating">
                            <path d="M33.333,44.961v21.706c0,4.114-3.347,7.463-7.461,7.463H25v5.037c0,2.303-1.866,4.167-4.167,4.167 s-4.167-1.864-4.167-4.167v-5.037h-0.873c-4.114,0-7.461-3.349-7.461-7.463V44.961c0-4.114,3.347-7.461,7.461-7.461h0.873v-4.167 c0-2.301,1.866-4.167,4.167-4.167S25,31.032,25,33.333V37.5h0.873C29.987,37.5,33.333,40.847,33.333,44.961z M84.204,37.5h-0.871 v-4.167c0-2.301-1.864-4.167-4.167-4.167c-2.303,0-4.167,1.866-4.167,4.167V37.5h-0.871c-4.114,0-7.463,3.347-7.463,7.461v21.706 c0,4.114,3.349,7.463,7.463,7.463H75v5.037c0,2.303,1.864,4.167,4.167,4.167c2.303,0,4.167-1.864,4.167-4.167v-5.037h0.871 c4.114,0,7.463-3.349,7.463-7.463V44.961C91.667,40.847,88.318,37.5,84.204,37.5z M55.037,25h-0.871v-4.167 c0-2.301-1.866-4.167-4.167-4.167s-4.167,1.866-4.167,4.167V25h-0.873c-4.114,0-7.461,3.347-7.461,7.461v22.577 c0,4.114,3.347,7.463,7.461,7.463h0.873v4.167c0,2.303,1.866,4.167,4.167,4.167s4.167-1.864,4.167-4.167V62.5h0.871 c4.114,0,7.463-3.349,7.463-7.463V32.461C62.5,28.347,59.151,25,55.037,25z" />
                          </g>
                        </svg>
                      </span>
                    </li>
                  </ul>

                  {/* options currencies */}
                  <div className="op-subHeader-one-inner">
                    <ul className="nav nav-pills op-subHeader-ml">
                      {subHeadCoin.map((data) => {
                        return (
                          <div
                            className={`op-subHeader-icons-wrapper ${
                              headCurrencies === data.currency ? "active" : ""
                            } `}
                            key={data.id}
                            onClick={() => handleCurrencyClick(data.currency)}
                          >
                            <img
                              className="op-subHeader-inner-icon"
                              src={data.img}
                              alt=""
                            />

                            <div className="contract-and-info">
                              <div
                                data-bn-type="text"
                                className="contract-tab-item css-1jpjezl "
                              >
                                {data.currency}
                              </div>
                              <div className="info-container mt-1">
                                <div
                                  className={`css-1p5bven 
                                        ${
                                          data.tradeSignal
                                            ? "tradeGreen"
                                            : "tradeRed"
                                        }`}
                                >
                                  {data.price}
                                </div>
                                <div
                                  className={`icon-container ${
                                    data.tradeSignal ? "tradeGreen" : "tradeRed"
                                  }`}
                                >
                                  <i
                                    className={`${
                                      data.tradeSignal
                                        ? data.greenIcon
                                        : data.redIcon
                                    }`}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                {tradeStateref.current === "chart" ? (
                  <>
                    <div className="op-fav new_fav_opt">
                      {/* fav  */}
                      <div className="op-sph-two op-fav-inner">
                        {FavLeftArrow && (
                          <div
                            className="op-fav-arr-left"
                            onClick={handleLeftFavClick}
                          >
                            <i className="fa-solid fa-chevron-left"></i>
                          </div>
                        )}

                        <div className="op-fav-left" ref={favSliderRef}>
                          <div className="op-fav-tag">Favorites</div>
                          <div className="op-fav-tag">All</div>
                          <div className="op-fav-tag">2025-01-27</div>
                          <div className="op-fav-tag">2025-01-28</div>
                          <div className="op-fav-tag">2025-01-28</div>
                          <div className="op-fav-tag">2025-01-29</div>
                          <div className="op-fav-tag">2025-01-29</div>
                          <div className="op-fav-tag">2025-01-29</div>
                          <div className="op-fav-tag">2025-01-29</div>
                        </div>

                        {FavRightArrow && (
                          <div
                            className="op-fav-arr-right"
                            onClick={handleRightFavClick}
                          >
                            <i className="fa-solid fa-chevron-right"></i>
                          </div>
                        )}
                      </div>

                      {/* strike */}
                      <div className="op-fav-right">
                        <div className="d-flex align-items-center">
                          <div className="op-fav-check-wrap">
                            <input
                              type="checkbox"
                              id="atm"
                              className="op__check"
                            />
                            <label
                              htmlFor="atm"
                              onClick={handleCheckClick}
                            ></label>
                          </div>
                          <label
                            onClick={handleCheckClick}
                            htmlFor="atm"
                            className="op-st-label"
                          >
                            Around ATM
                          </label>

                          {isCheckShow && (
                            <Dropdown
                              inline
                              placeholder="2"
                              options={aroundATMOptions}
                              className="mx-3 op-st-dropdown"
                            />
                          )}
                        </div>

                        <div className="d-flex align-items-center">
                          <div className="op-fav-check-wrap">
                            <input
                              type="checkbox"
                              id="strikerange"
                              className="op__check"
                            />
                            <label
                              htmlFor="strikerange"
                              onClick={handleStrikeClick}
                            ></label>
                          </div>
                          <label
                            onClick={handleStrikeClick}
                            htmlFor="strikerange"
                            className="op-st-label"
                          >
                            Strike Range
                          </label>

                          {isStrikeShow && (
                            <div>
                              <Dropdown
                                inline
                                placeholder="400"
                                options={strikeFromOptions}
                                className="mx-3 op-st-dropdown"
                              />
                              <span className="op-st-span"> - </span>
                              <Dropdown
                                inline
                                placeholder="920"
                                options={strikeToOptions}
                                className="mx-3 op-st-dropdown"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="opt_ove_flo">
                      <div className="opt_fav_beloman">
                        <span className="opt_fav_beloman_thispa">Calls</span>
                        <div className="opt_fav_belo_cenman">
                          <span className="opt_fav_beloman_tnipa">
                            Est. Settlement Price: 98,863.6 | ATM Vol: 59.4%
                          </span>
                          <span className="opt_fav_beloman_thispa">
                            {" "}
                            2025-01-27
                          </span>
                          <span className="opt_fav_beloman_tnipa">
                            Time to Expiry: 00:00:00(Daily)
                          </span>
                        </div>
                        <span className="opt_fav_beloman_thispa">Puts</span>
                      </div>
                    </div>
                    {/* <div className="opt_tabs_newhad">
                      <span>Open</span>
                      <span>Delta</span>
                      <span>Bid Size</span>
                      <span> Bid/IV</span>
                      <span>Marl/IV</span>
                      <span>Ask/IV</span>
                      <span> Ask Size</span>
                      <span> Position</span>
                      <span> Strike</span>
                      <span> Position</span>
                      <span> Ask Size</span>
                      <span> Ask/IV</span>
                      <span> Marl/IV</span>
                      <span> Bid/IV</span>
                      <span> Bid Size</span>
                      <span> Delta</span>
                      <span> Open</span>
                    </div> */}

                    <div className="opt_ove_flo">
                      <div className="opt_tab_new_now">
                        <div className="opt_tabs_newbod">
                          <div className="opt_tabs_newhad px-4">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              Open
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              Delta
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              Bid Size
                            </span>
                            <span className="">Bid/IV</span>
                            <span className="cvbn">Marl/IV</span>
                            <span className="">Ask/IV</span>
                            <span className="opt_tabs_newbod_inner_spn ">
                              {" "}
                              Ask Size
                            </span>
                            <span className="opt_tabs_newbod_inner_spn ">
                              Position
                            </span>
                          </div>

                          <div className="opt_tabs_newbod_inner bck_optr_dim">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner bck_optr_dim">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner bck_optr_dim thic_bor_pot">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner">
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                          </div>
                        </div>
                        <div className="opt-cent-newal ">
                          <div className=" opt_tabs_newhad justify-content-center">
                            <span> Strike</span>
                          </div>
                          <div className="opt-cent-newal-innn">
                            <span> 96,000</span>
                          </div>
                          <div className="opt-cent-newal-innn">
                            <span> 96,000</span>
                          </div>
                          <div className="opt-cent-newal-innn thic_bor_pot">
                            <span> 96,000</span>
                          </div>
                          <div className="opt-cent-newal-innn">
                            <span> 96,000</span>
                          </div>
                          <div className="opt-cent-newal-innn">
                            <span> 96,000</span>
                          </div>
                          <div className="opt-cent-newal-innn">
                            <span> 96,000</span>
                          </div>
                          <div className="opt-cent-newal-innn">
                            <span> 96,000</span>
                          </div>
                          <div className="opt-cent-newal-innn">
                            <span> 96,000</span>
                          </div>

                          <div className="pot_cent_abs">
                            <span>96,000.0</span>
                          </div>
                        </div>
                        <div className="opt_tabs_newbod">
                          <div className=" opt_tabs_newhad">
                            <span className="opt_tabs_newbod_inner_spn">
                              Position
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              Ask Size
                            </span>
                            <span className="">Ask/IV</span>
                            <span className="cvbn">Marl/IV</span>
                            <span className="">Bid/IV</span>
                            <span className="opt_tabs_newbod_inner_spn">
                              Bid Size
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              Delta
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              Open
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner ">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner ">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner thic_bor_pot">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner bck_optr_dim">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner bck_optr_dim">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner bck_optr_dim">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner bck_optr_dim">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                          <div className="opt_tabs_newbod_inner bck_optr_dim">
                            <span className="opt_tabs_newbod_inner_spn pdg_let33">
                              {" "}
                              --
                            </span>
                            <span className="opt_tabs_newbod_inner_spn pdg_let2">
                              {" "}
                              0.01
                            </span>
                            <div className="qwerty">
                              <span className="mnbhg">
                                {valueRed.toFixed(1)}
                              </span>
                              <span className="hgfdsa">999.99%</span>
                            </div>
                            <div className="qwerty">
                              <span className="cvbn">1,700.0</span>
                              <span className="hgfdsa">110.76%</span>
                            </div>
                            <div className="qwerty">
                              <span className="asdfgh">{value.toFixed(1)}</span>
                              <span className="hgfdsa">0.00%</span>
                            </div>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.01
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              0.00056
                            </span>
                            <span className="opt_tabs_newbod_inner_spn">
                              {" "}
                              1,980.87
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-3">
                      <span className="opt_rectra_spa">
                        Recent Trades (BTC Options)
                      </span>
                    </div>
                    <div className="row opt_ove_flo">
                      <div className="col-lg-6 col-sm-6">
                        <div className="opt_blw_calsputs mt-2">
                          <span className="opt_blw_calsputs_calls">Calls</span>
                          <div className="opt_blw_calsputs_centi">
                            <span> 24h Call Volume: 1775.24</span>
                          </div>
                        </div>
                        <div className="opt_bottom_tabl">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <th className="pad_opy_tbpa">Side</th>
                                <th>Symbol</th>
                                <th>Price</th>
                                <th>Size (Cont)</th>
                                <th className="text-end">Time</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <span>Buy</span>
                                  </td>
                                  <td>BTC-250131-104000-C</td>
                                  <td>820.0</td>
                                  <td className="text-center">0.02</td>
                                  <td className="text-end">
                                    2025-01-27 16:44:31
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>Buy</span>
                                  </td>
                                  <td>BTC-250131-104000-C</td>
                                  <td>820.0</td>
                                  <td className="text-center">0.02</td>
                                  <td className="text-end">
                                    2025-01-27 16:44:31
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <span>Buy</span>
                                  </td>
                                  <td>BTC-250131-104000-C</td>
                                  <td>820.0</td>
                                  <td className="text-center">0.02</td>
                                  <td className="text-end">
                                    2025-01-27 16:44:31
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="opt_blw_calsputs_strt mt-2">
                          <div className="opt_blw_calsputs_centi">
                            <span> 24h Put Volume: 1775.24</span>
                          </div>
                          <span className="opt_blw_calsputs_calls">Puts</span>
                        </div>
                        <div className="opt_bottom_tabl">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <th className="srt_pad_optta">Side</th>
                                <th>Symbol</th>
                                <th>Price</th>
                                <th>Size (Cont)</th>
                                <th className="txt_lst_optta">Time</th>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="srt_pad_optta">Sell</td>
                                  <td>BTC-250131-104000-C</td>
                                  <td>820.0</td>
                                  <td className="text-center">0.02</td>
                                  <td className="txt_lst_optta">
                                    2025-01-27 16:44:31
                                  </td>
                                </tr>
                                <tr>
                                  <td className="srt_pad_optta">Sell</td>
                                  <td>BTC-250131-104000-C</td>
                                  <td>820.0</td>
                                  <td className="text-center">0.02</td>
                                  <td className="txt_lst_optta">
                                    2025-01-27 16:44:31
                                  </td>
                                </tr>
                                <tr>
                                  <td className="srt_pad_optta">Sell</td>
                                  <td>BTC-250131-104000-C</td>
                                  <td>820.0</td>
                                  <td className="text-center">0.02</td>
                                  <td className="txt_lst_optta">
                                    2025-01-27 16:44:31
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="op-subHeader">
                      <div className="op-subHeader-flex">
                        {/* one */}
                        <div className="op-sbh-one-flex">
                          {/* BTC-250124-80000-P */}
                          <div>
                            <div className="op-sbh-title">
                              BTC-250124-80000-P
                            </div>
                            <span className="op-sbh-span">1,014,961.40x</span>
                          </div>
                          {/* dropdown */}
                          <div class="btn-group">
                            <button
                              class="btn btn-secondary btn-lg dropdown-toggle more-select"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            ></button>

                            <ul class="dropdown-menu op-sbh-dropdown">
                              <div className="more-links">
                                <Link className="nav-trade-links ">
                                  <div className="nav-trade-wrapper">
                                    <div>
                                      <p className="mt-2">BTC-250124-80000-P</p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </ul>
                          </div>
                          {/* text */}
                          <div className="op-sbh-txt">20.0</div>
                        </div>

                        {/* two */}
                        <div className="op-sph-two">
                          {/* <div className="op-sbh-arr-left">
                        <i className="fa-solid fa-chevron-left"></i>
                      </div> */}
                          {showLeftArrow && (
                            <div
                              className="op-sbh-arr-left"
                              onClick={handleLeftClick}
                            >
                              <i className="fa-solid fa-chevron-left"></i>
                            </div>
                          )}

                          <div className="op-sph-two-flex" ref={sliderRef}>
                            <div>
                              <span className="op-sph-two-title">Index</span>
                              <div className="op-sph-two-content">
                                101,888.1
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">Mark</span>
                              <div className="op-sph-two-content">0.1</div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">Strike</span>
                              <div className="op-sph-two-content">80,000.0</div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">
                                24h Change
                              </span>
                              <div className="op-sph-two-content">
                                5.0 (+33.33%)
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">
                                24h Vol (Count/USDT)
                              </span>
                              <div className="op-sph-two-content">
                                0.10/2.00
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">
                                Open (Cont/USDT)
                              </span>
                              <div className="op-sph-two-content">
                                101.16/10,291,157.52
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">
                                Time to Expiry
                              </span>
                              <div className="op-sph-two-content">
                                50day 1hr
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">
                                IV (Bid/Mark/Ask)
                              </span>
                              <div className="op-sph-two-content">
                                <span>50day 1hr</span> / <span>75.38%</span> /{" "}
                                <span>233.89%</span>
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">Delta</span>
                              <div className="op-sph-two-content">
                                -0.99117162
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">Gamma</span>
                              <div className="op-sph-two-content">
                                0.00000084
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">Theta</span>
                              <div className="op-sph-two-content">
                                -6.68662819
                              </div>
                            </div>
                            <div>
                              <span className="op-sph-two-title">Vega</span>
                              <div className="op-sph-two-content">
                                8.60433941
                              </div>
                            </div>
                          </div>

                          {showRightArrow && (
                            <div
                              className="op-sbh-arr-right"
                              onClick={handleRightClick}
                            >
                              <i className="fa-solid fa-chevron-right"></i>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-lg-4 pot_can_colnopad no-pad-new p-0">
                        <div className="op-order_book h-915-opt">
                          <div className="d-flex span-div justify-content-between">
                            <span>Order Book</span>
                            <span>
                              <i class="fa-solid fa-ellipsis"></i>
                            </span>
                          </div>
                          <div className="contant_scetion">
                            <div className="op-orderbook-header ">
                              <div class="op-orderbook-header-tips current-flex">
                                <div>
                                  <button
                                    data-bn-type="button"
                                    data-testid="defaultModeButton"
                                    class=" css-sz6ky9"
                                    onClick={Fullorderbook}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      class="css-3kwgah"
                                    >
                                      <path
                                        d="M4 4h7v7H4V4z"
                                        fill="#F6465D"
                                      ></path>
                                      <path
                                        d="M4 13h7v7H4v-7z"
                                        fill="#0ECB81"
                                      ></path>
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button
                                    data-bn-type="button"
                                    data-testid="buyModeButton"
                                    class=" css-1meiumy"
                                    onClick={bidorderbook}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      class="css-3kwgah"
                                    >
                                      <path
                                        d="M4 4h7v16H4V4z"
                                        fill="#0ECB81"
                                      ></path>
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                  <button
                                    data-bn-type="button"
                                    data-testid="sellModeButton"
                                    class=" css-1meiumy"
                                    onClick={askorderbook}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      class="css-3kwgah"
                                    >
                                      <path
                                        d="M4 4h7v16H4V4z"
                                        fill="#F6465D"
                                      ></path>
                                      <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </button>
                                </div>

                                {/* <div className="orderbook-num">
                                                  <span>0.01</span>{" "}
                                                  <span>
                                                    <i class="fa-solid fa-caret-down"></i>
                                                  </span>
                                                </div> */}
                              </div>
                            </div>

                            <div className="market_order">
                              <article>
                                <section class="op-orderbook-header ml-0 mr-0">
                                  <table width="100%">
                                    <tr>
                                      <th class="price" width="25%">
                                        Price({pairDetails.to_symbol})
                                      </th>
                                      <th width="25%">
                                        Amount({pairDetails.from_symbol})
                                      </th>
                                      <th width="25%">Total</th>
                                    </tr>
                                  </table>
                                </section>

                                {orderbooksloaderref.current == true ? (
                                  <i class="fa-solid fa-spinner text-center fa-spin"></i>
                                ) : (
                                  <>
                                    <section class="side" id="asks">
                                      <table
                                        width="100%"
                                        className="green_content"
                                      >
                                        {orderbookLoader == false ? (
                                          ""
                                        ) : orderbookaskref.current.length >
                                          0 ? (
                                          orderbookaskref.current.map(
                                            (ask, i) => {
                                              return (
                                                <tr data-width="70">
                                                  <td
                                                    width="25%"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    className="price sell priceclick"
                                                    onClick={() =>
                                                      addPrice(ask[0])
                                                    }
                                                  >
                                                    {parseFloat(ask[0]).toFixed(
                                                      pairDetails?.liq_price_decimal
                                                    )}
                                                  </td>
                                                  <td width="25%">
                                                    {parseFloat(ask[1]).toFixed(
                                                      pairDetails?.liq_amount_decimal
                                                    )}
                                                  </td>
                                                  <td width="25%">
                                                    {parseFloat(ask[2]).toFixed(
                                                      pairDetails?.liq_price_decimal
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            {" "}
                                            <td
                                              colSpan="3"
                                              className="text-center"
                                            >
                                              {" "}
                                              Data not found!
                                            </td>{" "}
                                          </tr>
                                        )}
                                      </table>
                                    </section>
                                    {orderbookdivider == false ? (
                                      ""
                                    ) : (
                                      <section class="divider">
                                        <div className="current-flex">
                                          <div class="current-price">
                                            {parseFloat(marketPrice).toFixed(
                                              pairDetails?.liq_price_decimal
                                            )}
                                            <span>
                                              <i class="fa-solid fa-arrow-down text-red"></i>
                                            </span>
                                            {/* <span className="current-down-price">
                                                              $67,850
                                                            </span> */}
                                          </div>
                                          <span className="current-right-arrow">
                                            {" "}
                                            <i class="fa-solid fa-angle-right"></i>
                                          </span>
                                        </div>
                                      </section>
                                    )}
                                    <section class="side bids">
                                      <table width="100%">
                                        {orderbookLoaderbid == false ? (
                                          ""
                                        ) : orderbookbidref.current.length >
                                          0 ? (
                                          orderbookbidref.current.map(
                                            (bid, i) => {
                                              return (
                                                <tr>
                                                  <td
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    width="25%"
                                                    className="red-green price buy priceclick"
                                                    onClick={() =>
                                                      addPrice(bid[0])
                                                    }
                                                  >
                                                    {parseFloat(bid[0]).toFixed(
                                                      pairDetails?.liq_price_decimal
                                                    )}
                                                  </td>
                                                  <td width="25%">
                                                    {parseFloat(bid[1]).toFixed(
                                                      pairDetails?.liq_amount_decimal
                                                    )}
                                                  </td>
                                                  <td width="25%">
                                                    {parseFloat(bid[2]).toFixed(
                                                      pairDetails?.liq_price_decimal
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            {" "}
                                            <td
                                              colSpan="3"
                                              className="text-center"
                                            >
                                              {" "}
                                              Data not found!
                                            </td>{" "}
                                          </tr>
                                        )}
                                      </table>
                                    </section>
                                  </>
                                )}
                              </article>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 p-0">
                        {/* <div className="op-chart_trade">
                          <div
                            id="tv_chart_container"
                            className="op-tv-chart"
                          ></div>
                        </div> */}

                        <img
                          src={require("../assets/forex-trade.webp")}
                          alt=""
                          className="w-100 h-500"
                        />

                        <div className="op-order_form">
                          <div className="op-order-taps">
                            <span
                              className={`op-or-btn ${
                                isFormHead === "open" ? "active" : ""
                              }`}
                              onClick={() => handleFormHead("open")}
                            >
                              Open
                            </span>
                            <span
                              className={`op-or-btn ${
                                isFormHead === "close" ? "active" : ""
                              }`}
                              onClick={() => handleFormHead("close")}
                            >
                              Close
                            </span>
                          </div>

                          <div className="op-or-limit-wrap">
                            <span
                              className={`op-or-limit-btn ${
                                isFormLimit === "limit" ? "active" : ""
                              }`}
                              onClick={() => handleLimit("limit")}
                            >
                              Limit
                            </span>
                            <span
                              className={`op-or-limit-btn ${
                                isFormLimit === "bbo" ? "active" : ""
                              }`}
                              onClick={() => handleLimit("bbo")}
                            >
                              BBO
                            </span>
                          </div>

                          <div className="op-av-wrap">
                            <div>
                              <span className="op-av-span">Avbl - </span>
                              <span className="op-av-span num mx-1">
                                {" "}
                                0.000000USDT
                              </span>
                            </div>

                            <div>
                              <span className="op-av-span num">Max: </span>
                              <span className="op-av-span mx-num mx-1">
                                25,196.1
                              </span>
                            </div>
                          </div>

                          <div>
                            <div className="row mt-3">
                              <div className="col-sm-6 ">
                                <div className="op-frm-input-wrap">
                                  <input
                                    type="number"
                                    className="op-frm-input"
                                    placeholder="Price"
                                  />
                                  <span className="op-frm-span">
                                    66871.99 USDT
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-6 mt-3 mt-sm-0">
                                <div className="op-frm-input-wrap">
                                  <input
                                    type="number"
                                    className="op-frm-input"
                                    placeholder="Amount"
                                  />
                                  <span className="op-frm-span">BTC</span>
                                </div>
                              </div>
                              <div className="col-lg-12 mt-3">
                                <div className="op-frm-input-wrap">
                                  <input
                                    type="number"
                                    className="op-frm-input"
                                    placeholder="Amount"
                                  />
                                  <span className="op-frm-span span-12">
                                    BTC
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="op-av-wrap op-mx-wrap">
                            <div>
                              <span className="op-av-span">Max Amount: </span>
                              <span className="op-av-span num mx-1">
                                {" "}
                                0.000000USDT
                              </span>
                            </div>
                          </div>

                          <div className="op-av-wrap">
                            <div>
                              <span className="op-av-span">TIF </span>
                              <span>
                                <Dropdown
                                  inline
                                  placeholder="GTC"
                                  options={optionsOpen}
                                  className="mx-3 op-av-dropdown"
                                />
                              </span>
                            </div>

                            <div>
                              <span className="op-av-span num">Cost: </span>
                              <span className="op-av-span mx-1">0.00</span>
                            </div>
                          </div>

                          <button className="op-order_form-btn">Buy</button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-lg-3 p-sm-0 no_pad_rghnew nopad_bt_opylo">
                {tradeStateref.current === "chart" ? (
                  <>
                    <div className="op-subHeader-flex-newone backgrnd_1026">
                      {/* one */}
                      <div className="op-sbh-one-flex">
                        {/* BTC-250124-80000-P */}
                        <div>
                          <div className="op-sbh-title">BTC-250124-80000-P</div>
                          <span className="op-sbh-span">1,014,961.40x</span>
                        </div>
                        {/* dropdown */}
                        <div class="btn-group">
                          <button
                            class="btn btn-secondary btn-lg dropdown-toggle more-select"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          ></button>

                          <ul class="dropdown-menu op-sbh-dropdown">
                            <div className="more-links">
                              <Link className="nav-trade-links ">
                                <div className="nav-trade-wrapper">
                                  <div>
                                    <p className="mt-2">BTC-250124-80000-P</p>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </ul>
                        </div>
                      </div>

                      {/* two */}
                      <div className="op-sph-two">
                        {/* <div className="op-sbh-arr-left">
                        <i className="fa-solid fa-chevron-left"></i>
                      </div> */}
                        {showLeftArrow && (
                          <div
                            className="op-sbh-arr-left-righttop"
                            onClick={handleLeftClick}
                          >
                            <i className="fa-solid fa-chevron-left"></i>
                          </div>
                        )}

                        <div className="op-sph-two-flex-newone" ref={sliderRef}>
                          <div>
                            <span className="op-sph-two-title">Index</span>
                            <div className="op-sph-two-content">101,888.1</div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">Mark</span>
                            <div className="op-sph-two-content">0.1</div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">Strike</span>
                            <div className="op-sph-two-content">80,000.0</div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">24h Change</span>
                            <div className="op-sph-two-content">
                              5.0 (+33.33%)
                            </div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">
                              24h Vol (Count/USDT)
                            </span>
                            <div className="op-sph-two-content">0.10/2.00</div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">
                              Open (Cont/USDT)
                            </span>
                            <div className="op-sph-two-content">
                              101.16/10,291,157.52
                            </div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">
                              Time to Expiry
                            </span>
                            <div className="op-sph-two-content">50day 1hr</div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">
                              IV (Bid/Mark/Ask)
                            </span>
                            <div className="op-sph-two-content">
                              <span>50day 1hr</span> / <span>75.38%</span> /{" "}
                              <span>233.89%</span>
                            </div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">Delta</span>
                            <div className="op-sph-two-content">
                              -0.99117162
                            </div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">Gamma</span>
                            <div className="op-sph-two-content">0.00000084</div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">Theta</span>
                            <div className="op-sph-two-content">
                              -6.68662819
                            </div>
                          </div>
                          <div>
                            <span className="op-sph-two-title">Vega</span>
                            <div className="op-sph-two-content">8.60433941</div>
                          </div>
                        </div>

                        {showRightArrow && (
                          <div
                            className="op-sbh-arr-right-righttop"
                            onClick={handleRightClick}
                          >
                            <i className="fa-solid fa-chevron-right"></i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="op-order_form resp_pad_potord">
                      <div className="op-order-taps">
                        <span
                          className={`op-or-btn ${
                            isFormHeadSecond === "open" ? "active" : ""
                          }`}
                          onClick={() => handleFormHeadSecond("open")}
                        >
                          Open
                        </span>
                        <span
                          className={`op-or-btn ${
                            isFormHeadSecond === "close" ? "active" : ""
                          }`}
                          onClick={() => handleFormHeadSecond("close")}
                        >
                          Close
                        </span>
                      </div>

                      <div className="op-or-limit-wrap">
                        <span
                          className={`op-or-limit-btn ${
                            isFormLimitSecond === "limit" ? "active" : ""
                          }`}
                          onClick={() => handleLimitSecond("limit")}
                        >
                          Limit
                        </span>
                        <span
                          className={`op-or-limit-btn ${
                            isFormLimitSecond === "bbo" ? "active" : ""
                          }`}
                          onClick={() => handleLimitSecond("bbo")}
                        >
                          BBO
                        </span>
                      </div>

                      <div className="op-av-wrap">
                        <div>
                          <span className="op-av-span">Avbl - </span>
                          <span className="op-av-span num mx-1">
                            {" "}
                            0.000000USDT
                          </span>
                        </div>

                        <div>
                          <span className="op-av-span num">Max: </span>
                          <span className="op-av-span mx-num mx-1">
                            25,196.1
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="row mt-3">
                          <div className="col-lg-12 col-xl-6 col-sm-12 padopt_orfrin_0">
                            <div className="op-frm-input-wrap">
                              <input
                                type="number"
                                className="op-frm-input"
                                placeholder="Price"
                              />
                              <span className="op-frm-span">66871.99 USDT</span>
                            </div>
                          </div>
                          <div className=" col-lg-12 col-xl-6 col-sm-12 mt-3 mt-xl-0 mt-lg-1 mt-sm-2">
                            <div className="op-frm-input-wrap">
                              <input
                                type="number"
                                className="op-frm-input"
                                placeholder="Amount"
                              />
                              <span className="op-frm-span">BTC</span>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <div className="op-frm-input-wrap">
                              <input
                                type="number"
                                className="op-frm-input"
                                placeholder="Amount"
                              />
                              <span className="op-frm-span span-12">BTC</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="op-av-wrap op-mx-wrap">
                        <div>
                          <span className="op-av-span">Max Amount: </span>
                          <span className="op-av-span num mx-1">
                            {" "}
                            0.000000USDT
                          </span>
                        </div>
                      </div>

                      <div className="op-av-wrap">
                        <div>
                          <span className="op-av-span">TIF </span>
                          <span>
                            <Dropdown
                              inline
                              placeholder="GTC"
                              options={optionsOpen}
                              className="mx-3 op-av-dropdown"
                            />
                          </span>
                        </div>

                        <div>
                          <span className="op-av-span num">Cost: </span>
                          <span className="op-av-span mx-1">0.00</span>
                        </div>
                      </div>

                      <button className="op-order_form-btn">Buy</button>
                    </div>

                    <div className="op-markert_activity op-acc h-465-opt backgrnd_1026">
                      <div className="op-acc-header backgrnd_1026">
                        <h6 className="op-acc-title">Account</h6>
                        <span
                          className="op-acc-icon"
                          onClick={() => handleOpen()}
                        >
                          <i class="fa-solid fa-right-left"></i>
                        </span>
                      </div>

                      <div className="op-acc-main">
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Total Equity
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Margin Balance
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Market Value
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex op-acc-border-y">
                          <h5 className="op-acc-main-content m-0">
                            Unrealized PnL
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Available Margin
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">In Order</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex op-acc-bor-b">
                          <h5 className="op-acc-main-content m-0">
                            Maintenance Margin
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                      </div>

                      {/* account greeks */}
                      <div className="op-acc-header op-acc-greek-head backgrnd_1026">
                        <h6 className="op-acc-title">Account Greeks</h6>
                      </div>

                      <div className="op-acc-tabs">
                        {greeksTabs.map((data) => {
                          return (
                            <div
                              key={data.id}
                              onClick={() => handleGreekTab(data.coin)}
                            >
                              <span
                                className={`op-greek-tab-coin ${
                                  greekCoin === data.coin ? "active" : ""
                                }`}
                              >
                                {data.coin}
                              </span>
                            </div>
                          );
                        })}
                      </div>

                      <div className="op-acc-main">
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Delta</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Gamma</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Theta</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex op-acc-bor-b ">
                          <h5 className="op-acc-main-content m-0">Vega</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                      </div>

                      {/* estimated pnl chart */}
                      <div className="op-acc-header border-0 backgrnd_1026">
                        <h6 className="op-acc-title">Estimated PnL chart</h6>
                      </div>

                      <div className="op-acc-main">
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Max Profit
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Max Loss</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Breakeven Price
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                      </div>

                      {/* internal transfer popup */}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className="op-acc-modal">
                          <InternalPopup />
                        </Box>
                      </Modal>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="op-trades pb-0">
                      <div className="border_rig_new_opt">
                        <div className="form_seldect__pair pt-0">
                          <ul class="nav nav-pills">
                            <li class="active">
                              <div className={`trade-sec`}>Trades</div>
                            </li>
                          </ul>

                          <div class="tab-content pair_details">
                            <div
                              id="mtrade"
                              class={`tab-pane fade in ${
                                activeTab === "mytrade" ? "show active" : ""
                              } show bor_1 mar-top`}
                            >
                              <div class="fixTableHead mt-2">
                                <table>
                                  <thead>
                                    <tr>
                                      <th className="market-trades">
                                        Price ({pairDetails.to_symbol})
                                      </th>
                                      <th className="text-end market-trades">
                                        Amount ({pairDetails.from_symbol})
                                      </th>
                                      <th className="text-end market-trades">
                                        Time
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!checkAuth ? (
                                      <tr>
                                        {" "}
                                        <td colSpan="3">
                                          <Button
                                            className="Butn_header my-4 d-block mx-auto"
                                            onClick={Movelogin}
                                          >
                                            <Link
                                              to="/login"
                                              className="text-black"
                                            >
                                              Login to continue
                                            </Link>
                                          </Button>{" "}
                                        </td>{" "}
                                      </tr>
                                    ) : marketTraderef.current.length > 0 ? (
                                      marketTraderef.current.map((item, i) => {
                                        return (
                                          <tr className="position_rel_over">
                                            {item.tradeType == "buy" ? (
                                              <td className="market-price-td">
                                                <span className="color-buy">
                                                  {" "}
                                                  {item.price}{" "}
                                                </span>
                                              </td>
                                            ) : (
                                              <td className="market-price-td">
                                                <span className="red-green">
                                                  {" "}
                                                  {item.price}{" "}
                                                </span>
                                              </td>
                                            )}
                                            <td className="text-end">
                                              {item.amount}{" "}
                                            </td>
                                            <td className="text-end">
                                              {moment(item.time).format(
                                                "hh:mm:ss"
                                              )}{" "}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="3"
                                          className="text-center mt-4"
                                        >
                                          No Market Trades
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>

                            <div
                              id="mytrade"
                              class={`tab-pane ${
                                activeTab === "trade" ? "show active" : ""
                              } fade`}
                            >
                              <div class="fixTableHead mt-2">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Pair ({pairDetails.to_symbol})</th>
                                      <th className="text-end">
                                        Price ({pairDetails.from_symbol})
                                      </th>
                                      <th className="text-end">Time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {!checkAuth ? (
                                      <tr>
                                        {" "}
                                        <td colSpan="3">
                                          <Button
                                            className="Butn_header my-4 d-block mx-auto"
                                            onClick={Movelogin}
                                          >
                                            <Link
                                              to="/login"
                                              className="text-black"
                                            >
                                              Login to continue
                                            </Link>
                                          </Button>{" "}
                                        </td>{" "}
                                      </tr>
                                    ) : tradeHistoryData.length > 0 ? (
                                      tradeHistoryData.map((item, i) => {
                                        return (
                                          <tr className="position_rel_over">
                                            {item.type === "buy" ? (
                                              <td>
                                                <span className="color-buy">
                                                  {" "}
                                                  {item.askPrice
                                                    ? Number(
                                                        item.askPrice
                                                      ).toFixed(2)
                                                    : "0"}{" "}
                                                </span>
                                              </td>
                                            ) : (
                                              <td>
                                                <span className="red-green">
                                                  {" "}
                                                  {item.askPrice
                                                    ? Number(
                                                        item.askPrice
                                                      ).toFixed(2)
                                                    : "0"}{" "}
                                                </span>
                                              </td>
                                            )}
                                            <td className="text-end">
                                              {item.askAmount
                                                ? Number(
                                                    item.askAmount
                                                  ).toFixed(4)
                                                : "0"}{" "}
                                            </td>
                                            <td className="text-end">
                                              {moment(item.created_at).isValid()
                                                ? moment(
                                                    item.created_at
                                                  ).format("hh:mm:ss")
                                                : "Invalid date"}{" "}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td
                                          colSpan="3"
                                          className="text-center mt-4"
                                        >
                                          No Market Trades
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="op-markert_activity op-acc op-acc-chngtrack">
                      <div className="op-acc-header">
                        <h6 className="op-acc-title">Account</h6>
                        <span
                          className="op-acc-icon"
                          onClick={() => handleOpen()}
                        >
                          <i class="fa-solid fa-right-left"></i>
                        </span>
                      </div>

                      <div className="op-acc-main">
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Total Equity
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Margin Balance
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Market Value
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex op-acc-border-y">
                          <h5 className="op-acc-main-content m-0">
                            Unrealized PnL
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Available Margin
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">In Order</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex op-acc-bor-b">
                          <h5 className="op-acc-main-content m-0">
                            Maintenance Margin
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                      </div>

                      {/* account greeks */}
                      <div className="op-acc-header op-acc-greek-head">
                        <h6 className="op-acc-title">Account Greeks</h6>
                      </div>

                      <div className="op-acc-tabs">
                        {greeksTabs.map((data) => {
                          return (
                            <div
                              key={data.id}
                              onClick={() => handleGreekTab(data.coin)}
                            >
                              <span
                                className={`op-greek-tab-coin ${
                                  greekCoin === data.coin ? "active" : ""
                                }`}
                              >
                                {data.coin}
                              </span>
                            </div>
                          );
                        })}
                      </div>

                      <div className="op-acc-main">
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Delta</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Gamma</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Theta</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex op-acc-bor-b ">
                          <h5 className="op-acc-main-content m-0">Vega</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                      </div>

                      {/* estimated pnl chart */}
                      <div className="op-acc-header border-0">
                        <h6 className="op-acc-title">Estimated PnL chart</h6>
                      </div>

                      <div className="op-acc-main">
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Max Profit
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">Max Loss</h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                        <div className="op-acc-main-flex">
                          <h5 className="op-acc-main-content m-0">
                            Breakeven Price
                          </h5>
                          <span className="op-acc-main-content">00.00</span>
                        </div>
                      </div>

                      {/* internal transfer popup */}
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style} className="op-acc-modal">
                          <InternalPopup />
                        </Box>
                      </Modal>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="easy_Low_main">
              <div className="easy_Low_main_top">
                <span
                  className={`easy_low_tabhead ${
                    activeTabheadref.current == "position" ? "active" : ""
                  } `}
                  onClick={() => handleTabs("position")}
                >
                  Position (0)
                </span>
                {tradeStateref.current === "chart" ? (
                  <>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "orders" ? "active" : ""
                      } `}
                      onClick={() => handleTabs("orders")}
                    >
                      Orders (0)
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "history" ? "active" : ""
                      } `}
                      onClick={() => handleTabs("history")}
                    >
                      History
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "pricealert" ? "active" : ""
                      } `}
                      onClick={() => handleTabs("pricealert")}
                    >
                      Price alerts
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "transaction"
                          ? "active"
                          : ""
                      } `}
                      onClick={() => handleTabs("transaction")}
                    >
                      Transactions
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "journal" ? "active" : ""
                      } `}
                      onClick={() => handleTabs("journal")}
                    >
                      Journal
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "openorder" ? "active" : ""
                      } `}
                      onClick={() => handleTabs("openorder")}
                    >
                      Open Orders (0)
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "orderhistory"
                          ? "active"
                          : ""
                      } `}
                      onClick={() => handleTabs("orderhistory")}
                    >
                      Order History
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "tradehistory"
                          ? "active"
                          : ""
                      } `}
                      onClick={() => handleTabs("tradehistory")}
                    >
                      Trade History
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "exercisehistory"
                          ? "active"
                          : ""
                      } `}
                      onClick={() => handleTabs("exercisehistory")}
                    >
                      Exercise History
                    </span>
                    <span
                      className={`easy_low_tabhead ${
                        activeTabheadref.current == "transactionhistory"
                          ? "active"
                          : ""
                      } `}
                      onClick={() => handleTabs("transactionhistory")}
                    >
                      Transaction History
                    </span>
                  </>
                )}
              </div>
              <div className="w-100 mb-5">
                <div className="table-responsive table-cont otc_spot_tableup">
                  <table className="table">
                    <thead>
                      <tr className="stake-head font-satoshi">
                        <th>Symbol</th>
                        <th className="pad-left-23 txt-center">Size</th>
                        <th className="pad-left-23 txt-center">Available</th>
                        <th className="pad-left-23 txt-center">
                          Average Price
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Unrealized PnL
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          ROE%
                        </th>
                        <th className="pad-left-23 txt-center opt-nowrap">
                          Market Value
                        </th>
                        <th className="opt-nowrap table-action pad-left-23 pad-rght-tab">
                          Close Position
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td colSpan={8} className="text-center py-5">
                          <div className="empty_data">
                            <div className="empty_data_img py-4">
                              <img
                                src={require("../assets/no-copytrade-data.webp")}
                                width="100px"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </main>
        </div>

        {/* popup */}
        <div className="vl-popup-outer">
          <div className="email-popup-card new-vl-inner-popup">
            <div className="email-pop-img">
              <img src={require("../assets/soon.webp")} alt="email-icon" />
            </div>
            <h3>Optional Trade – Coming Soon!</h3>
            <p>
              Unlock new trading possibilities with our Optional Trade feature.
              Stay tuned for the launch!
            </p>
            <div className="Submit">
              <button onClick={() => navigate("/")}>Back To Home</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OptionalTrade;
