import React, { useEffect, useCallback } from "react";
import useState from "react-usestateref";
import { Button } from "@material-ui/core";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./Header";
import { socket } from "../context/socket";
import "./Trade.css";
import "./FutureTrade.css";
import "./OptionalTrade.css";
import {
  removeAuthToken,
  getAuthToken,
  getSocketToken,
} from "../core/lib/localStorage";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import { toast } from "react-toastify";
import { env } from "../core/service/envconfig";
import moment from "moment";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import button from "@mui/material/Button";
import { widget } from "../core/lib/chart/charting_library/charting_library.min";
import isEmpty from "../core/lib/isEmpty";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { createTheme, ThemeProvider } from "@mui/material";
import { activeOrderData } from "../utils/mockData";
// import PriceFooter from "./TradePage/PriceFooter";
import { capitalize } from "../core/lib/format";
import { Dropdown } from "semantic-ui-react";

function FutureTrade() {
  const [activeTab, setActiveTab] = useState("mytrade");
  const [activeLimits, setActiveLimits, activeLimitsref] = useState("limit");
  const [activeTabhead, setActiveTabHead, activeTabheadref] =
    useState("position");
  const [isChecked, setIschecked] = useState(false);
  const [isCheckedReduce, setIscheckedReduce] = useState(false);
  const [theme, setTheme] = useState("Dark");
  const [pair, setPair] = useState("BTC_USDT");

  const [pairlist, setpairlist] = useState([]);
  const [selectedmarket, setselectedmarket, selectedmarketref] =
    useState("USDT");
  const [pairLoader, setPairLoader] = useState(false);
  const [sideLoader, setsideLoader] = useState(false);
  const [Tocurrencies, setTocurrencies, Tocurrenciesref] = useState([
    "USDT",
    "INR",
    "BTC",
    "EUR",
    "ETH",
    "BNB",
    "XRP",
  ]);
  const [orderbookask, setorderbookask, orderbookaskref] = useState([]);
  const [orderbooksloader, setorderbooksloader, orderbooksloaderref] =
    useState(false);

  const [orderbookbid, setorderbookbid, orderbookbidref] = useState([]);
  const [currentlasprice, setcurrentlasprice] = useState("");
  const [searchInputlist, setsearchInputlist, searchInputlistref] = useState(
    []
  );
  const [chartPair, setchartPair] = useState("BTCUSDT");
  const [curnt_Ordertype_tab, setcurnt_Ordertype_tab, curnt_Ordertype_tabref] =
    useState("Limit");
  const [currentPair, setcurrentPair] = useState("");
  const [fromCurrency, setFromCurrenncy] = useState("");
  const [toCurrency, setToCurrenncy] = useState("");
  const [currentType, setcurrentType, currentTyperef] = useState("buy");
  const [frombalance, setFromBalance, frombalanceref] = useState(0);
  const [tobalance, settobalance, tobalanceref] = useState(0);
  const [maxbuy, setmaxbuy, maxbuyref] = useState(0);
  const [maxsell, setmaxsell, maxsellref] = useState(0);
  const [checkAuth, setcheckAuth] = useState(false);
  const [pairDetails, setpairDetails] = useState("");
  const [pairTickerDetails, setpairTickerDetails] = useState("");
  const [marketTradeloader, setmarketTradeloader, marketTradeloaderref] =
    useState(false);
  const [marketPrice, setmarketPrice, marketPriceref] = useState(0);
  const [marketTrade, setmarketTrade, marketTraderef] = useState([]);
  const [priceLoader, setpriceLoader, priceLoaderref] = useState(false);
  const [searchpair, setsearchpair] = useState(null);
  const [fromcurrency, Setfromcurrency, fromcurrencyref] = useState(false);
  const [tradeHistoryData, settradeHistory] = useState([]);
  const [orderbookLoader, setorderbookLoader] = useState(true);
  const [orderbookLoaderbid, setorderbookLoaderbit] = useState(true);

  const initialFormValue = {
    price: "",
    amount: "",
    total: "",
    stop_price: "",
    sellprice: "",
    sellamount: "",
    selltotal: "",
    sellstop_price: "",
  };
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();

  const {
    price,
    amount,
    total,
    stop_price,
    sellprice,
    sellamount,
    selltotal,
    sellstop_price,
  } = formValue;
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text");
    if (/[eE\+\-]/.test(pasteData)) {
      e.preventDefault();
    }
  };
  const handleKeyDown = (e) => {
    // List of allowed keys (like Backspace, Delete, Tab, Arrow keys, etc.)
    const allowedKeys = [
      "Backspace",
      "Delete",
      "Tab",
      "ArrowLeft",
      "ArrowRight",
      "Home",
      "End",
    ];

    // If the pressed key is NOT in allowedKeys and matches e, E, +, or -, prevent the input
    if (!allowedKeys.includes(e.key) && /[eE\+\-]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Check for invalid characters in input value
    if (/[eE\+\-]/.test(value)) {
      return;
    }

    // Shallow clone formData for local manipulation
    let formData = { ...formValue, [name]: value };

    // Perform upfront validations to avoid unnecessary calculations
    const price = parseFloat(formData.price || 0);
    const amount = parseFloat(formData.amount || 0);
    const sellPrice = parseFloat(formData.sellprice || 0);
    const sellAmount = parseFloat(formData.sellamount || 0);

    if (price < 0 || amount < 0 || sellPrice < 0 || sellAmount < 0) {
      if (price < 0 || sellPrice < 0) showerrorToast("Enter valid price");
      if (amount < 0 || sellAmount < 0) showerrorToast("Enter valid amount");
      return;
    }

    let totalPrice = 0;

    // Consolidate logic for calculating total
    if (sellAmount > 0) {
      totalPrice = sellPrice * sellAmount;
      formData.selltotal = totalPrice.toFixed(8);
    } else {
      totalPrice = price * amount;
      formData.total = totalPrice.toFixed(8);
    }

    setFormValue(formData);
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const options = [
    { key: "BTC", text: "BTC", value: "BTC" },
    { key: "ETH", text: "ETH", value: "ETH" },
  ];

  const themeSlider = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            width: 13,
            height: 13,
            borderRadius: 0,
            border: "2px solid #888B93",
            backgroundColor: "#1E2026",
            transform: "rotate(45deg)",
            transformOrigin: "center",
            marginLeft: -12,
            marginTop: -6,
          },
          track: {
            backgroundColor: "#1E2026",
          },
        },
      },
    },
  });

  const handleLimit = async (value) => {
    setActiveLimits(value);
  };

  const handleTabs = async (value) => {
    setActiveTabHead(value);
  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
  };

  const handleTermsReduce = (event) => {
    setIscheckedReduce(event.target.checked);
  };

  const navigate = useNavigate();

  const tvWidget = null;

  useEffect(() => {
    var urls = window.location.href;
    var pair = urls.split("trade/")[1];
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();

    // console.log(changelower, "changelower");
    if (tvWidget !== null) {
      tvWidget.remove();
      tvWidget = null;
    }
    setorderbooksloader(true);
    check();
    socketinit();
    selectPairbyCurrency("USDT");
  }, [socket]);

  async function check() {
    getmarketdata();

    let mecheck = await getAuthToken();

    var callapi = false;
    if (mecheck != "" && mecheck != undefined && mecheck != null) {
      await setcheckAuth(true);
      callapi = true;
    } else {
      await setcheckAuth(false);
      callapi = false;
    }
    var urls = window.location.href;
    var fetchPair = urls.split("trade/")[1];
    if (fetchPair) {
      setcurrentPair(fetchPair);
      var fromcurr = fetchPair.split("_")[0];
      var toCurr = fetchPair.split("_")[1];
      setFromCurrenncy(fromcurr);
      setToCurrenncy(toCurr);
      getCurrpairDatas(fetchPair);
      fetchTickerPrice(fetchPair);
      getMarketTrades(fetchPair);
      selectPair(fetchPair);

      setchartPair(fromcurr + toCurr);
      setPair(fromcurr + "_" + toCurr);
    } else {
      navigate("/");
    }
    socket.connect();
    let pair_string = fromcurr + toCurr;
    socket.emit("GetOrderBook", { symbol: pair_string.toLowerCase() });
  }

  const socketinit = async () => {
    // console.log("-0-0-0-0-0-0-0-")
    socket.on("OrderBook", async (response) => {
      var data = await response.data;
      if (data != null && data != undefined && data != "") {
        if (data.symbol) {
          setorderbookask([]);
          setorderbookbid([]);
          var orderbookbid = [];
          var orderbookask = [];
          orderbookbid = data["bids"].length == 0 ? [] : data["bids"];
          orderbookask = data["asks"].length == 0 ? [] : data["asks"];
          var askcumtotal = 0;
          for (let index = 0; index < orderbookask.length; index++) {
            var element = orderbookask[index];
            var multiply = element[0] * element[1];
            askcumtotal = parseFloat(askcumtotal) + parseFloat(multiply);
            orderbookask[index]["percent"] = (multiply / askcumtotal) * 100;
          }
          var bidcumtotal = 0;
          for (let index = 0; index < orderbookbid.length; index++) {
            var element = orderbookbid[index];
            var multiply = element[0] * element[1];
            bidcumtotal = parseFloat(bidcumtotal) + parseFloat(multiply);
            orderbookbid[index]["percent"] = (multiply / bidcumtotal) * 100;
          }

          const mergedOrderBook_ask = orderbookask.reduce((acc, record) => {
            const [price, amount, total] = record.map(Number); // Ensure all values are numbers
            const existingOrder = acc.find((order) => order[0] === price);

            if (existingOrder) {
              existingOrder[1] += amount; // Sum the amounts
              existingOrder[2] += total; // Sum the totals
            } else {
              acc.push([price, amount, total]); // Add the new record as is
            }
            return acc;
          }, []);

          const mergedOrderBook_bid = orderbookbid.reduce((acc, record) => {
            const [price, amount, total] = record.map(Number); // Ensure all values are numbers
            const existingOrder = acc.find((order) => order[0] === price);

            if (existingOrder) {
              existingOrder[1] += amount; // Sum the amounts
              existingOrder[2] += total; // Sum the totals
            } else {
              acc.push([price, amount, total]); // Add the new record as is
            }
            return acc;
          }, []);

          setorderbookask(
            // orderbookask.sort(function (a, b) {
            //   return b[0] - a[0];
            // })

            mergedOrderBook_ask.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
          setorderbookbid(
            // orderbookbid.sort(function (a, b) {
            //   return b[0] - a[0];
            // })
            mergedOrderBook_bid.sort(function (a, b) {
              return b[0] - a[0];
            })
          );
          setorderbooksloader(false);
        }
      }
    });
    socket.on("TickerPrice", async (response) => {
      if (response.data) {
        var tickerdetail = response.data;
        setpairTickerDetails(tickerdetail);
        setmarketPrice(
          tickerdetail.lastprice.lastprice
            ? tickerdetail.lastprice.lastprice
            : 0.0
        );
        setmaxbuy(
          parseFloat(tobalanceref.current) / parseFloat(formValue.price)
        );
        setmaxsell(
          parseFloat(frombalanceref.current) * parseFloat(formValue.price)
        );
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            // formValue.price = "";
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
          //formValue.stop_price = "";
        } else if (curnt_Ordertype_tabref.current == "Market") {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        } else {
          if (priceLoaderref.current == false) {
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        }
      }
    });

    socket.on("TradeHistory", async (response) => {
      var tickerdetail = response.data;
      if (tickerdetail !== null) {
        setmarketTrade(tickerdetail);
      } else {
        if (marketTrade.length > 0) {
        }
      }
    });
    socket.on("close", function () {
      socket.connect();
      setorderbooksloader(true);
      socketinit();
    });
  };

  const [loginStatus, setLoginStatus] = useState(false);
  useEffect(() => {
    let token_check = sessionStorage.getItem("user_token");
    if (token_check) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
    }
  }, []);

  const loginNave = async () => {
    navigate("/login");
  };

  useEffect(() => {
    fetchTheme();
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  const selectPairbyCurrency = async (curr) => {
    setPairLoader(true);
    setselectedmarket(curr);
    setsideLoader(true);
    socket.off("DashTickerPrice");
    socket.emit("GetTickerPrice", "getall");
    var data = {
      apiUrl: apiService.pairbycurrency,
      payload: { currency: curr },
    };

    var pairdetail = await postMethod(data);
    if (pairdetail) {
      socket.on("DashTickerPrice", async (response) => {
        setPairLoader(false);
        setsideLoader(false);
        var tickarr = await response.data;

        // console.log(response.data, "tickers ---response");
        for (let index = 0; index < pairdetail.data.length; index++) {
          const element = pairdetail.data[index];
          var replace_string = element.pair.replace("_", "");
          var changelower = replace_string.toLowerCase();
          if (tickarr[changelower]) {
            var tickobj = JSON.parse(tickarr[changelower]);
            if (tickarr) {
              if (element.pair == tickobj.pair) {
                pairdetail.data[index]["price_change"] =
                  (await tickobj.change_percent)
                    ? parseFloat(tickobj.change_percent).toFixed(4)
                    : 0.0;
                pairdetail.data[index]["lastprice"] = (await tickobj.lastprice
                  .lastprice)
                  ? parseFloat(tickobj.lastprice.lastprice).toFixed(
                      element.liq_price_decimal
                    )
                  : 0.0;
              }
            }
          }
        }
      });

      await setsearchInputlist(pairdetail.data);
      if (searchpair != null) {
        setpairlist(
          searchInputlistref.current.filter(function (tag) {
            if (
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0 ||
              tag.liquidity_name
                .toLowerCase()
                .indexOf(searchpair.toLowerCase()) >= 0
            ) {
              return tag;
            }
          })
        );
      } else {
        await setpairlist(pairdetail.data);
        await setsearchInputlist(pairdetail.data);
        setPairLoader(false);
        setsideLoader(false);
      }
    }
  };

  const getmarketdata = async () => {
    var data = {
      apiUrl: apiService.getmarketData,
    };
    var fetchticker = await getMethod(data);
    if (fetchticker.status) {
      setTocurrencies(fetchticker.data);
    }
  };

  const getCurrpairDatas = async (pair) => {
    var data = {
      apiUrl: apiService.getCurrpairData,
      payload: { pair: pair },
    };
    var fetchticker = await postMethod(data);
    if (fetchticker) {
      setpairDetails(fetchticker.data);
      Setfromcurrency(fetchticker.fromCurr);
    }
  };

  const fetchTickerPrice = async (pair) => {
    // console.log("fetchTickerPrice pair===", pair);
    try {
      var data = {
        apiUrl: apiService.fetch_tickers,
        payload: { pair: pair },
      };
      var fetchticker = await postMethod(data);
      if (fetchticker) {
        var data = await fetchticker.data;
        // console.log("fetchTickerPrice data====", data);
        setpairTickerDetails(data);
        setmarketPrice(
          data.lastprice.lastprice ? data.lastprice.lastprice : 0.0
        );

        // console.log(data.lastprice.lastprice, "fetchticket");
        if (curnt_Ordertype_tabref.current == "Stop") {
          if (formValue.price <= 0) {
            // formValue.price = "";
            formValue.price = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
            formValue.sellprice = (+marketPriceref.current).toFixed(
              pairDetails?.liq_price_decimal
            )
              ? +marketPriceref.current
              : 0.0;
          }
        } else if (
          curnt_Ordertype_tabref.current == "Market" ||
          curnt_Ordertype_tabref.current == "Limit"
        ) {
          formValue.price = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
          formValue.sellprice = (+marketPriceref.current).toFixed(
            pairDetails?.liq_price_decimal
          )
            ? +marketPriceref.current
            : 0.0;
        }
      }
    } catch (error) {}
  };

  const getMarketTrades = async (pair) => {
    try {
      var data = {
        apiUrl: apiService.marketTrades,
        payload: { pair: pair },
      };
      // setauthentication(true);
      setmarketTradeloader(true);
      var fetchTradeHisotory = await postMethod(data);
      // setauthentication(false);
      if (fetchTradeHisotory) {
        if (fetchTradeHisotory.status) {
          setmarketTradeloader(false);
          setmarketTrade(fetchTradeHisotory.Message);
        } else {
          setmarketTradeloader(false);
          setmarketTrade([]);
        }
      } else {
      }
    } catch (error) {}
  };

  const selectPair = async (pair) => {
    var replace_string = pair.replace("_", "");
    var changelower = replace_string.toLowerCase();
    socket.emit("GetOrderBook", { symbol: changelower });
    setchartPair(replace_string);
    setPair(pair);
  };

  const getLanguageFromURL = () => {
    const regex = new RegExp("[\\?&]lang=([^&#]*)");
    const results = regex.exec(window.location.search);
    return results === null
      ? null
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  const buildchart = (theme, pair) => {
    //console.log("theme ====",theme)
    theme = "dark";
    const widgetOptions = {
      symbol: pair,
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(
        env.apiHost + "chartapi/chart"
      ),
      interval: pair == "USDT_INR" ? "240" : "1",
      container_id: "tv_chart_container",
      library_path: "/charting_library/",
      locale: getLanguageFromURL() || "en",
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "tradingview.com",
      user_id: "public_user_id",
      fullscreen: false,
      //autosize: true,
      width: "100%",
      height: "518",
      studies_overrides: {},
      loading_screen: { backgroundColor: "#17171A" },
      theme: theme,
      toolbar_bg: "#17171A",
      timezone: "Asia/Kolkata",
      overrides: {
        "paneProperties.background": "#17171A",
        "paneProperties.vertGridProperties.color": "transparent",
        "paneProperties.horzGridProperties.color": "transparent",
      },
    };

    if (theme == "White") {
      delete widgetOptions.toolbar_bg;
      delete widgetOptions.overrides;
    }

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              console.log("Noticed!");
            },
          })
        );
      });
    });
  };

  const fetchTheme = () => {
    var theme = localStorage.getItem("theme");
    if (theme != undefined) {
      if (theme == "light") {
        setTheme("White");
      } else {
        setTheme("Dark");
      }
    } else {
      localStorage.setItem("theme", "dark");
      setTheme("Dark");
    }

    let urls = window.location.href;
    let fetchPair = urls.split("trade/")[1];
    if (fetchPair != null) {
      let themeValue = "Dark";
      if (theme == "light") {
        themeValue = "White";
      } else if (theme == "dark") {
        themeValue = "Dark";
      }
      buildchart(themeValue, fetchPair);
    }
  };

  const addPrice = async (price) => {
    setpriceLoader(true);
    if (curnt_Ordertype_tabref.current == "Limit") {
      formValue.price = parseFloat(price).toFixed(
        pairDetails?.liq_price_decimal
      );
      formValue.sellprice = parseFloat(price).toFixed(
        pairDetails?.liq_price_decimal
      );
    }
    // console.log(formValue, "-=-=-form value=-=-");
  };

  const Movelogin = () => {
    navigate("/login");
  };

  return (
    <div>
      <Header />
      <div className="new-popup-height">
        <div className="header">
          <main className="min-height-100vh">
            <div className="container">
              <div className="future_hero_trade">
                <div className="future_first_main">
                  <div className="future_first_main_inner">
                    <span className="first_main_innerfuspa">BTCUSDT</span>
                    <span className="first_main_in_red"> -3.83%</span>
                  </div>
                  <div className="future_first_main_inner">
                    <span className="first_main_innerfuspa">ETHUSDT</span>
                    <span className="first_main_in_red"> -5.92%</span>
                  </div>
                  <div className="future_first_main_inner">
                    <span className="first_main_innerfuspa">BNBUSDT</span>
                    <span className="first_main_in_red"> -3.56%</span>
                  </div>
                </div>
                <div className="future_scnd_main">
                  <div className="future_scnd_lft">
                    <div className="future_scnd_lft_side">
                      <img
                        src={require("../assets/stars.png")}
                        width="20px"
                        height="20px"
                        className="mt-1"
                      />
                      <span className="future_scnd_lft_head">BTCUSDT</span>
                      <div className="future_top_prep">
                        <span>Perp</span>
                      </div>
                    </div>
                    <div class="btn-group p-0">
                      <button
                        class="btn btn-secondary btn-lg dropdown-toggle more-select"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></button>

                      <ul class="dropdown-menu op-sbh-dropdown">
                        <div className="more-links">
                          <Link className="nav-trade-links ">
                            <div className="nav-trade-wrapper">
                              <div>
                                <p className="mt-2">BTC-250124-80000-P</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </ul>
                    </div>
                    <div className="future_scnd_lft_pric">
                      <span className="future_par_manpri">
                        {isNaN(marketPriceref.current)
                          ? ""
                          : parseFloat(marketPriceref.current).toFixed(
                              pairDetails?.liq_price_decimal
                            )}
                      </span>
                      <span className="future_par_subpri">
                        {/* -3,975.60 -3.76% */}
                        {isNaN(pairTickerDetails?.price_change)
                          ? ""
                          : parseFloat(pairTickerDetails?.price_change).toFixed(
                              pairDetails?.liq_price_decimal
                            )}
                        {parseFloat(pairTickerDetails?.change_percent).toFixed(
                          2
                        )}{" "}
                        %
                      </span>
                    </div>
                  </div>
                  <div className="future_scnd_right">
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had">Mark</span>
                      <span className="future_scnd_in_subhed">
                        {isNaN(marketPriceref.current)
                          ? ""
                          : parseFloat(marketPriceref.current).toFixed(
                              pairDetails?.liq_price_decimal
                            )}
                      </span>
                    </div>
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had">Index</span>
                      <span className="future_scnd_in_subhed">
                        {isNaN(pairTickerDetails?.highprice)
                          ? ""
                          : parseFloat(pairTickerDetails?.highprice).toFixed(
                              pairDetails?.liq_price_decimal
                            )}{" "}
                      </span>
                    </div>
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had">
                        Funding / Countdown
                      </span>
                      <span className="future_scnd_in_subhed">
                        {isNaN(pairTickerDetails?.volume)
                          ? ""
                          : parseFloat(pairTickerDetails?.volume).toFixed(
                              pairDetails?.liq_price_decimal
                            )}{" "}
                        / 02:21:41
                      </span>
                    </div>
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had"> 24 High</span>
                      <span className="future_scnd_in_subhed">
                        {isNaN(pairTickerDetails?.highprice)
                          ? ""
                          : parseFloat(pairTickerDetails?.highprice).toFixed(
                              pairDetails?.liq_price_decimal
                            )}{" "}
                      </span>
                    </div>
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had">24h Low</span>
                      <span className="future_scnd_in_subhed">
                        {isNaN(pairTickerDetails?.lowprice)
                          ? ""
                          : parseFloat(pairTickerDetails?.lowprice).toFixed(
                              pairDetails?.liq_price_decimal
                            )}{" "}
                      </span>
                    </div>
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had">
                        24h Volume(BTC)
                      </span>
                      <span className="future_scnd_in_subhed">
                        {isNaN(pairTickerDetails?.volume)
                          ? ""
                          : parseFloat(pairTickerDetails?.volume).toFixed(
                              pairDetails?.liq_price_decimal
                            )}
                      </span>
                    </div>
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had">
                        24h Volume(USDT)
                      </span>
                      <span className="future_scnd_in_subhed">
                        {isNaN(pairTickerDetails?.volumeto)
                          ? ""
                          : parseFloat(pairTickerDetails?.volumeto).toFixed(
                              pairDetails?.liq_price_decimal
                            )}
                      </span>
                    </div>
                    <div className="future_scnd_right_inner">
                      <span className="future_scnd_in_had">
                        {" "}
                        Open Interest(USDT)
                      </span>
                      <span className="future_scnd_in_subhed">
                        1,839,020,199.75
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 no-pad-new">
                    <div className="future_order_book">
                      <div className="d-flex span-div justify-content-between">
                        <span>Order Book</span>
                        <span>
                          <i class="fa-solid fa-ellipsis"></i>
                        </span>
                      </div>
                      <div className="contant_scetion">
                        <div className="orderbook-header ">
                          <div class="orderbook-header-tips current-flex">
                            <div>
                              <button
                                data-bn-type="button"
                                data-testid="defaultModeButton"
                                class=" css-sz6ky9"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  class="css-3kwgah"
                                >
                                  <path d="M4 4h7v7H4V4z" fill="#F6465D"></path>
                                  <path
                                    d="M4 13h7v7H4v-7z"
                                    fill="#0ECB81"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                data-bn-type="button"
                                data-testid="buyModeButton"
                                class=" css-1meiumy"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  class="css-3kwgah"
                                >
                                  <path
                                    d="M4 4h7v16H4V4z"
                                    fill="#0ECB81"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                data-bn-type="button"
                                data-testid="sellModeButton"
                                class=" css-1meiumy"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  class="css-3kwgah"
                                >
                                  <path
                                    d="M4 4h7v16H4V4z"
                                    fill="#F6465D"
                                  ></path>
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M13 4h7v4h-7V4zm0 6h7v4h-7v-4zm7 6h-7v4h7v-4z"
                                    fill="currentColor"
                                  ></path>
                                </svg>
                              </button>
                            </div>

                            {/* <div className="orderbook-num">
                                                  <span>0.01</span>{" "}
                                                  <span>
                                                    <i class="fa-solid fa-caret-down"></i>
                                                  </span>
                                                </div> */}
                          </div>
                        </div>

                        <div className="market_order h-300-future">
                          <article>
                            <section class="orderbook-header ml-0 mr-0">
                              <table width="100%">
                                <tr>
                                  <th class="price" width="25%">
                                    Price({pairDetails.to_symbol})
                                  </th>
                                  <th width="25%">
                                    Amount({pairDetails.from_symbol})
                                  </th>
                                  <th width="25%">Total</th>
                                </tr>
                              </table>
                            </section>

                            <section class="side" id="asks">
                              <table width="100%" className="green_content">
                                {/* <tr data-width="70">
                                  <td
                                    width="25%"
                                    style={{ cursor: "pointer" }}
                                    className="price sell priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr data-width="70">
                                  <td
                                    width="25%"
                                    style={{ cursor: "pointer" }}
                                    className="price sell priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr data-width="70">
                                  <td
                                    width="25%"
                                    style={{ cursor: "pointer" }}
                                    className="price sell priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr data-width="70">
                                  <td
                                    width="25%"
                                    style={{ cursor: "pointer" }}
                                    className="price sell priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr data-width="70">
                                  <td
                                    width="25%"
                                    style={{ cursor: "pointer" }}
                                    className="price sell priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr> */}
                                {orderbookLoader == false ? (
                                  ""
                                ) : orderbookaskref.current.length > 0 ? (
                                  orderbookaskref.current.map((ask, i) => {
                                    return (
                                      <tr data-width="70">
                                        <td
                                          width="25%"
                                          style={{ cursor: "pointer" }}
                                          className="price sell priceclick"
                                          onClick={() => addPrice(ask[0])}
                                        >
                                          {parseFloat(ask[0]).toFixed(
                                            pairDetails?.liq_price_decimal
                                          )}
                                        </td>
                                        <td width="25%">
                                          {parseFloat(ask[1]).toFixed(
                                            pairDetails?.liq_amount_decimal
                                          )}
                                        </td>
                                        <td width="25%">
                                          {parseFloat(ask[2]).toFixed(
                                            pairDetails?.liq_price_decimal
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    {" "}
                                    <td colSpan="3" className="text-center">
                                      {" "}
                                      Data not found!
                                    </td>{" "}
                                  </tr>
                                )}
                              </table>
                            </section>

                            <section class="divider">
                              <div className="current-flex">
                                <div class="current-price">
                                  {/* 00.00 */}
                                  {parseFloat(marketPrice).toFixed(
                                    pairDetails?.liq_price_decimal
                                  )}
                                  <span>
                                    <i class="fa-solid fa-arrow-down text-red"></i>
                                  </span>
                                  <span className="current-down-price">
                                    {/* $67,850 */}
                                    {parseFloat(marketPrice).toFixed(
                                      pairDetails?.liq_price_decimal
                                    )}
                                  </span>
                                </div>
                                <span className="current-right-arrow">
                                  {" "}
                                  <i class="fa-solid fa-angle-right"></i>
                                </span>
                              </div>
                            </section>
                            <section class="side bids">
                              <table width="100%">
                                {/* <tr>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    width="25%"
                                    className="red-green price buy priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    width="25%"
                                    className="red-green price buy priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    width="25%"
                                    className="red-green price buy priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    width="25%"
                                    className="red-green price buy priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr>
                                <tr>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    width="25%"
                                    className="red-green price buy priceclick"
                                  >
                                    00.00
                                  </td>
                                  <td width="25%">00.00</td>
                                  <td width="25%">00.00</td>
                                </tr> */}
                                {orderbookLoaderbid == false ? (
                                  ""
                                ) : orderbookbidref.current.length > 0 ? (
                                  orderbookbidref.current.map((bid, i) => {
                                    return (
                                      <tr>
                                        <td
                                          style={{ cursor: "pointer" }}
                                          width="25%"
                                          className="red-green price buy priceclick"
                                          onClick={() => addPrice(bid[0])}
                                        >
                                          {parseFloat(bid[0]).toFixed(
                                            pairDetails?.liq_price_decimal
                                          )}
                                        </td>
                                        <td width="25%">
                                          {parseFloat(bid[1]).toFixed(
                                            pairDetails?.liq_amount_decimal
                                          )}
                                        </td>
                                        <td width="25%">
                                          {parseFloat(bid[2]).toFixed(
                                            pairDetails?.liq_price_decimal
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    {" "}
                                    <td colSpan="3" className="text-center">
                                      {" "}
                                      Data not found!
                                    </td>{" "}
                                  </tr>
                                )}
                              </table>
                            </section>
                          </article>
                        </div>

                        <div className="future_trades pb-0">
                          <div className="">
                            <div className="form_seldect__pair pt-0">
                              <ul class="nav nav-pills mt-3">
                                <li class="active">
                                  <a
                                    // data-toggle="pill"
                                    // href="#mtrade"
                                    className={`${
                                      activeTab === "mytrade" ? "active" : ""
                                    }`}
                                    onClick={() => setActiveTab("mytrade")}
                                  >
                                    Market Trades
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className={`${
                                      activeTab === "trade" ? "active" : ""
                                    }`}
                                    onClick={() => setActiveTab("trade")}
                                  >
                                    My Trades
                                  </a>
                                </li>
                              </ul>

                              <div class="tab-content pair_details">
                                <div
                                  id="mtrade"
                                  class={`tab-pane fade in ${
                                    activeTab === "mytrade" ? "show active" : ""
                                  } show bor_1 mar-top`}
                                >
                                  <div class="fixTableHead mt-2">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th className="market-trades">
                                            Price ({pairDetails.to_symbol})
                                          </th>
                                          <th className="text-end market-trades">
                                            Amount ({pairDetails.from_symbol})
                                          </th>
                                          <th className="text-end market-trades">
                                            Time
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {marketTraderef.current.length > 0 ? (
                                          marketTraderef.current.map(
                                            (item, i) => {
                                              return (
                                                <tr className="position_rel_over">
                                                  {item.tradeType == "buy" ? (
                                                    <td className="market-price-td">
                                                      <span className="color-buy">
                                                        {" "}
                                                        {item.price}{" "}
                                                      </span>
                                                    </td>
                                                  ) : (
                                                    <td className="market-price-td">
                                                      <span className="red-green">
                                                        {" "}
                                                        {item.price}{" "}
                                                      </span>
                                                    </td>
                                                  )}
                                                  <td className="text-end">
                                                    {item.amount}{" "}
                                                  </td>
                                                  <td className="text-end">
                                                    {moment(item.time).format(
                                                      "hh:mm:ss"
                                                    )}{" "}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="3"
                                              className="text-center mt-4"
                                            >
                                              No Market Trades
                                            </td>
                                          </tr>
                                        )}
                                        {/* <tr className="position_rel_over">
                                          <td className="market-price-td">
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">1234</td>
                                          <td className="text-end">11.11.11</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td className="market-price-td">
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">1234</td>
                                          <td className="text-end">11.11.11</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td className="market-price-td">
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">1234</td>
                                          <td className="text-end">11.11.11</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td className="market-price-td">
                                            <span className="reds">00.00</span>
                                          </td>
                                          <td className="text-end">1234</td>
                                          <td className="text-end">11.11.11</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td className="market-price-td">
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">1234</td>
                                          <td className="text-end">11.11.11</td>
                                        </tr> */}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>

                                <div
                                  id="mytrade"
                                  class={`tab-pane ${
                                    activeTab === "trade" ? "show active" : ""
                                  } show bor_1 mar-top fade`}
                                >
                                  <div class="fixTableHead mt-2">
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>
                                            Pair ({pairDetails.to_symbol})
                                          </th>
                                          <th className="text-end">
                                            Price ({pairDetails.from_symbol})
                                          </th>
                                          <th className="text-end">Time</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {/* <tr className="position_rel_over">
                                          <td>
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">4321</td>
                                          <td className="text-end">22.22.22</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td>
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">4321</td>
                                          <td className="text-end">22.22.22</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td>
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">4321</td>
                                          <td className="text-end">22.22.22</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td>
                                            <span className="reds">00.00</span>
                                          </td>
                                          <td className="text-end">4321</td>
                                          <td className="text-end">22.22.22</td>
                                        </tr>
                                        <tr className="position_rel_over">
                                          <td>
                                            <span className="color-buy">
                                              00.00
                                            </span>
                                          </td>
                                          <td className="text-end">4321</td>
                                          <td className="text-end">22.22.22</td>
                                        </tr> */}
                                        {!checkAuth ? (
                                          <tr>
                                            {" "}
                                            <td colSpan="3">
                                              <Button
                                                className="Butn_header my-4 d-block mx-auto"
                                                onClick={Movelogin}
                                              >
                                                <Link
                                                  to="/login"
                                                  className="text-black"
                                                >
                                                  Login to continue
                                                </Link>
                                              </Button>{" "}
                                            </td>{" "}
                                          </tr>
                                        ) : tradeHistoryData.length > 0 ? (
                                          tradeHistoryData.map((item, i) => {
                                            return (
                                              <tr className="position_rel_over">
                                                {item.type === "buy" ? (
                                                  <td>
                                                    <span className="color-buy">
                                                      {" "}
                                                      {item.askPrice
                                                        ? Number(
                                                            item.askPrice
                                                          ).toFixed(2)
                                                        : "0"}{" "}
                                                    </span>
                                                  </td>
                                                ) : (
                                                  <td>
                                                    <span className="red-green">
                                                      {" "}
                                                      {item.askPrice
                                                        ? Number(
                                                            item.askPrice
                                                          ).toFixed(2)
                                                        : "0"}{" "}
                                                    </span>
                                                  </td>
                                                )}
                                                <td className="text-end">
                                                  {item.askAmount
                                                    ? Number(
                                                        item.askAmount
                                                      ).toFixed(4)
                                                    : "0"}{" "}
                                                </td>
                                                <td className="text-end">
                                                  {moment(
                                                    item.created_at
                                                  ).isValid()
                                                    ? moment(
                                                        item.created_at
                                                      ).format("hh:mm:ss")
                                                    : "Invalid date"}{" "}
                                                </td>
                                              </tr>
                                            );
                                          })
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="3"
                                              className="text-center mt-4"
                                            >
                                              No Market Trades
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 forex_trade-center p-0">
                    {/* <img
                      src={require("../assets/forex-trade.webp")}
                      alt=""
                      className="w-100 h-100"
                    /> */}
                    <div id="tv_chart_container"></div>
                  </div>
                  <div className="col-lg-3 no_pad_rghnew">
                    <div className="future_righ_man">
                      <div className="future_righ_strt">
                        <div className="future_righ_strt_main">
                          <div className="future_righ_strt_top">
                            <div className="futr_tp_inbox">
                              <span>Cross</span>
                            </div>
                            <div className="futr_tp_inbox">
                              <span>5x</span>
                            </div>
                            <div className="futr_tp_inbox">
                              <span>M</span>
                            </div>
                          </div>
                          <div className="future_righ_strt_iner">
                            <div className="future_limi_mar">
                              <span
                                className={
                                  activeLimitsref.current == "limit"
                                    ? "future_limit_active"
                                    : "future_limit_innactive"
                                }
                                onClick={() => handleLimit("limit")}
                              >
                                Limit
                              </span>
                              <span
                                className={
                                  activeLimitsref.current == "market"
                                    ? "future_limit_active"
                                    : "future_limit_innactive"
                                }
                                onClick={() => handleLimit("market")}
                              >
                                Market
                              </span>
                              <span
                                className={
                                  activeLimitsref.current == "stoplimit"
                                    ? "future_limit_active"
                                    : "future_limit_innactive"
                                }
                                onClick={() => handleLimit("stoplimit")}
                              >
                                Stop-limit
                              </span>
                              <div class="btn-group p-0">
                                <button
                                  class=" btn-lg dropdown-toggle more-select-futur"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                ></button>

                                <ul class="dropdown-menu op-sbh-dropdown">
                                  <div className="more-links">
                                    <Link className="nav-trade-links ">
                                      <div className="nav-trade-wrapper">
                                        <div>
                                          <p className="mt-2">
                                            BTC-250124-80000-P
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                </ul>
                              </div>
                            </div>
                            <div className="future_limi_below">
                              <span className="future_limi_bel_avai">
                                <span>Avbl</span> - 0.000000USDT
                              </span>
                              <div className="future_rght_frstinp">
                                <div className="future_rght_frstinp_lef">
                                  <input
                                    type="number"
                                    className="future_rght_frstinp_inner"
                                    placeholder="Price"
                                  />
                                  <span className="futu_frsinnp_span">
                                    <span>Last</span> USDT
                                  </span>
                                </div>
                                <div className="futu_ininp_rig">
                                  <span>BBO</span>
                                </div>
                              </div>
                              <div className="future_rght_scndinp">
                                <input
                                  type="number"
                                  className="future_rght_frstinp_inner"
                                  placeholder="Amount"
                                />
                                <div className="futre_chng_frst">
                                  <Dropdown
                                    inline
                                    placeholder="BTC"
                                    options={options}
                                  />
                                </div>
                              </div>
                              <ThemeProvider theme={themeSlider}>
                                <div className="slider_spacing">
                                  <Box>
                                    <Slider
                                      value={3}
                                      step={25}
                                      marks
                                      name="buy"
                                      min={0}
                                      max={100}
                                      // onChange={handleSliderChange2}
                                      sx={{
                                        "& .MuiSlider-track": {
                                          backgroundColor: "#1E2026",
                                        },
                                        "& .MuiSlider-thumb": {
                                          backgroundColor: "#1E2026",
                                        },
                                      }}
                                    />
                                  </Box>
                                </div>
                              </ThemeProvider>

                              <div className="fut_tp_buysell">
                                <span className="fut_buy_spa">
                                  <span>Buy</span> 0.000 0 BTC
                                </span>
                                <span className="fut_buy_spa">
                                  <span>Sell</span> 0.000 0 BTC
                                </span>
                              </div>

                              <div className="future_set_inn_inner_centch">
                                <div class="checkbox-container-futureset">
                                  <input
                                    id="custom-checkbox"
                                    checked={isChecked}
                                    onChange={handleTerms}
                                    className="input-field regular_checkbox"
                                    type="checkbox"
                                    placeholder="Enter the referral ID (optional)"
                                  />
                                  <label htmlFor="custom-checkbox"></label>
                                </div>
                                <label
                                  htmlFor="custom-checkbox"
                                  className="terms-check-futureset"
                                >
                                  TP/SL
                                </label>
                              </div>

                              <div className="futur_in_midche">
                                <div className="future_set_inn_inner_centch">
                                  <div class="checkbox-container-futureset">
                                    <input
                                      id="custom-checkbox-reduce"
                                      checked={isCheckedReduce}
                                      onChange={handleTermsReduce}
                                      className="input-field regular_checkbox"
                                      type="checkbox"
                                    />
                                    <label htmlFor="custom-checkbox-reduce"></label>
                                  </div>
                                  <label
                                    htmlFor="custom-checkbox-reduce"
                                    className="terms-check-futureset"
                                  >
                                    Reduce-Only
                                  </label>
                                </div>
                                <div className="futur_in_midche_rght">
                                  <span>TIF</span>
                                  <div className="futre_chng_secc">
                                    <Dropdown
                                      inline
                                      placeholder="GTC"
                                      options={options}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="futur_btn_imp">
                                <div className="futur_btn_imp_singl">
                                  <div className="futur_btn_imp_btn">
                                    <span>Buy/Long</span>
                                  </div>
                                  <div className="futur_btn_imp_lowe">
                                    <span className="futur_btn_Lowe_span">
                                      <span>Liq Price</span> -- BTC
                                    </span>
                                    <span className="futur_btn_Lowe_span">
                                      <span>Cost</span> 0.000 USDT
                                    </span>
                                    <span className="futur_btn_Lowe_span">
                                      <span>Max</span> 0.000 BTC
                                    </span>
                                  </div>
                                </div>
                                <div className="futur_btn_imp_singl">
                                  <div className="futur_btn_imp_btn_red">
                                    <span>Sell/Short</span>
                                  </div>
                                  <div className="futur_btn_imp_lowe_rig">
                                    <span className="futur_btn_Lowe_span_rig">
                                      <span>Liq Price</span> -- BTC
                                    </span>
                                    <span className="futur_btn_Lowe_span_rig">
                                      <span>Cost</span> 0.000 USDT
                                    </span>
                                    <span className="futur_btn_Lowe_span_rig">
                                      <span>Max</span> 0.000 BTC
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="future_rg_per">
                          <span>%</span> Free level
                        </span>
                      </div>
                      <div className="futur_acc_min">
                        <span>Account</span>
                        <i class="fa-solid fa-arrow-right-arrow-left"></i>
                      </div>
                      <div className="acc_inf_fut">
                        <div className="acc_inf_fut_inn">
                          <div className="acc_futin_inn">
                            <span>Account Margin Ratio</span>
                            <span>0.00%</span>
                          </div>
                          <div className="acc_futin_inn">
                            <span>Account Maintenance Margin</span>
                            <span>0.00 USD</span>
                          </div>
                          <div className="acc_futin_inn">
                            <span> Account Equity</span>
                            <span>0.00 USD</span>
                          </div>
                          <div className="acc_futin_inn">
                            <span> Position Value</span>
                            <span>0.00 USD</span>
                          </div>
                          <div className="acc_futin_inn">
                            <span>Actual Leverage</span>
                            <span>0.00 USD</span>
                          </div>
                        </div>
                        <div className="acc_befut_btn">
                          <span>Multi-Assets</span>
                        </div>
                      </div>
                      <div className="fut_bot_not">
                        <div className="futre_chng_thirr">
                          <Dropdown
                            inline
                            placeholder="USDT"
                            options={options}
                          />
                        </div>
                        <div className="fut_loe_note">
                          <i class="fa-solid fa-circle-info"></i>
                          <span>
                            {" "}
                            Please transfer assets into your futures account to
                            start trading
                          </span>
                        </div>
                        <div className="fut_note_smlbt">
                          <div className="fut_note_smlbt_box">
                            <span>Buy Crypto</span>
                          </div>
                          <div className="fut_note_smlbt_box">
                            <span>Swap</span>
                          </div>
                          <div className="fut_note_smlbt_box">
                            <span>Transfer</span>
                          </div>
                        </div>
                      </div>
                      <div className="fut_last_man">
                        <span className="fut_last_man_head">
                          Supercharge return with BFUSD
                        </span>
                        <div className="ftu_lat_botto">
                          <span>BFUSD Balance</span>
                          <span> 0.00 BFUSD</span>
                        </div>
                        <div className="ftu_lat_botto">
                          <span>Reward</span>
                          <span> 0.00 USDT</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="easy_Low_main">
                <div className="easy_Low_main_top">
                  <span
                    className={`easy_low_tabhead ${
                      activeTabheadref.current == "position" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("position")}
                  >
                    Position (0)
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabheadref.current == "orders" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("orders")}
                  >
                    Orders (0)
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabheadref.current == "history" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("history")}
                  >
                    History
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabheadref.current == "pricealert" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("pricealert")}
                  >
                    Price alerts
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabheadref.current == "transaction" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("transaction")}
                  >
                    Transactions
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabheadref.current == "journal" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("journal")}
                  >
                    Journal
                  </span>
                </div>
                <div className="w-100 mb-5">
                  <div className="table-responsive table-cont otc_spot_tableup">
                    <table className="table">
                      <thead>
                        <tr className="stake-head font-satoshi">
                          <th>Symbol</th>
                          <th className="pad-left-23 txt-center">Size</th>
                          <th className="pad-left-23 txt-center">Available</th>
                          <th className="pad-left-23 txt-center">
                            Average Price
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            Unrealized PnL
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            ROE%
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            Market Value
                          </th>
                          <th className="opt-nowrap table-action pad-left-23 pad-rght-tab">
                            Close Position
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center py-5">
                            <div className="empty_data">
                              <div className="empty_data_img py-4">
                                <img
                                  src={require("../assets/no-copytrade-data.webp")}
                                  width="100px"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        {/* popup */}

        <div className="vl-popup-outer">
          <div className="email-popup-card new-vl-inner-popup">
            <div className="email-pop-img">
              <img src={require("../assets/soon.webp")} alt="email-icon" />
            </div>
            <h3>Future Trade – Coming Soon!</h3>
            <p>
              Unlock new trading possibilities with our Future Trade feature.
              Stay tuned for the launch!
            </p>
            <div className="Submit">
              <button onClick={() => navigate("/")}>Back To Home</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FutureTrade;
