import React, { useEffect } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";
import "./OTC.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button } from 'semantic-ui-react';
function OTC() {
  const [siteLoader, setSiteLoader] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [currencyLimits, setCurrencyLimits] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [sellCurrency, setSellCurrency] = useState(null);
  const [buyCurrency, setBuyCurrency] = useState(null);
  const [sellBalance, setSellBalance] = useState(0); // Added state
  const [buyBalance, setBuyBalance] = useState(0);   // Added state
  const [sellAmount, setSellAmount] = useState(0); // Store the sell amount
  const [buyAmount, setBuyAmount] = useState(0); // Store the buy amount
  const [closebuttonLoader, setClosebuttonLoader] = useState(false);
  const [marketRate, setMarketRate] = useState(null);
  const [otcData, setOtcData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [transactionId, setTransactionId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const initialState = {
    sellCurrency: null,
    buyCurrency: null,
    sellBalance: 0,
    buyBalance: 0,
    sellAmount: 0,
    buyAmount: 0,
  };
  const resetFields = () => {
    setSellCurrency(initialState.sellCurrency);
    setBuyCurrency(initialState.buyCurrency);
    setSellBalance(initialState.sellBalance);
    setBuyBalance(initialState.buyBalance);
    setSellAmount(initialState.sellAmount);
    setBuyAmount(initialState.buyAmount);
  };
  const fetchOtcData = async () => {
    setLoading(true);

    const response = await getMethod({ apiUrl: apiService.otcGet });

    if (response && Array.isArray(response)) {
      setOtcData([...response]); // ✅ Force React to detect state change
    } else {
      console.error("Invalid data format:", response);
    }

    setLoading(false);
  };



  useEffect(() => {
    fetchOtcData();
  }, []);
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const checkKYCStatus = async () => {
    try {
      // Assuming your getMethod function automatically passes the token
      const response = await getMethod({ apiUrl: apiService.getUserDetails });
      if (response.status && response.Message.kycstatus === 1) {
        return true;
      }
      return false;
    } catch (error) {
      toast.error("Error checking KYC status.");
      return false;
    }
  };

  useEffect(() => {
    const fetchOTCCurrencies = async () => {
      setSiteLoader(true);
      try {
        const data = {
          apiUrl: apiService.otcCurrencies,
        };
        const response = await getMethod(data);

        if (response.status) {
          setCurrencies(response.data || []);
          const firstCurrency = response.data[0];
          setCurrencyLimits({
            minQuantity: firstCurrency.minQuantity,
            maxQuantity: firstCurrency.maxQuantity,
          });
        } else {
          setCurrencies([]);
          setCurrencyLimits({});
        }
      } catch (error) {
        console.error("Error fetching OTC currencies:", error);
        setCurrencies([]);
        setCurrencyLimits({});
      } finally {
        setSiteLoader(false);
      }
    };
    fetchOTCCurrencies();
  }, []);

  const options = Array.isArray(currencies) && currencies.length > 0
    ? currencies.map(currency => ({
      key: currency.currencySymbol,
      text: (
        <>

          {currency.currencySymbol}
        </>
      ),
      value: currency.currencySymbol,
      image: currency.Currency_image
    }))
    : [];

  const navAlgo = async () => {
    navigate("/algootc");
  };
  const navAlgos = async () => {
    navigate("/otcConfirm");
  };

  const navOption = async () => {
    navigate("/otcoption");
  };
  const handleCurrencyChange = async (selectedValue, type) => {
    if (!selectedValue) {
      toast.error("Please choose a currency first.");
      return;
    }

    if (type === "sell") {
      if (selectedValue === buyCurrency) {
        toast.error("Buy and Sell coin should not be the same");
        return;
      }
      setSellCurrency(selectedValue);
    } else if (type === "buy") {
      if (selectedValue === sellCurrency) {
        toast.error("Buy and Sell coin should not be the same");
        return;
      }
      setBuyCurrency(selectedValue);
      if (sellAmount <= 0 || sellAmount === "") {
        // toast.error("Please provide the sell price (amount) before choosing a buy currency.");
        return;
      }
      // If a sell amount exists, convert immediately.
      if (sellAmount > 0 && sellCurrency) {
        const response = await postMethod({
          apiUrl: apiService.currencyConversion,
          payload: { from: sellCurrency, to: selectedValue, amount: sellAmount },
        });
        if (response.status && response.Message) {
          setBuyAmount(sellAmount * response.Message);
        }
      }
    }

    // Also update the selected currency state (if needed) and set limits.
    setSelectedCurrency(selectedValue);
    const chosenCurrency = currencies.find(c => c.currencySymbol === selectedValue);
    if (chosenCurrency) {
      setCurrencyLimits({
        minQuantity: chosenCurrency.minQuantity,
        maxQuantity: chosenCurrency.maxQuantity,
      });
    } else {
      setCurrencyLimits({});
    }
  };

  const checkUserVerification = async () => {
    if (!sellCurrency || !buyCurrency || !sellAmount) {
      toast.error("Please fill in all required fields: Sell Currency, Buy Currency and Prices");
      return false;
    }

    const isVerified = await checkKYCStatus();
    const hasSufficientBalance = sellBalance >= sellAmount;

    if (!isVerified) {
      toast.error("Please complete KYC verification before proceeding.");
      return false;
    }

    if (!hasSufficientBalance) {
      toast.error("Insufficient balance.");
      return false;
    }

    return true;
  };

  const fetchUserBalance = async (currency) => {
    try {
      const payload = {
        perpage: 10,
        page: 1,
        search: currency || "",
      };

      const response = await postMethod({
        apiUrl: apiService.getUserTotalbalance,
        payload,
      });

      if (response.status) {
        return response.Message.find(item => item.currencysymbol === currency)?.currencyBalance || 0;
      }
      return 0;
    } catch (error) {
      console.error("Error fetching balance:", error);
      return 0;
    }
  };

  useEffect(() => {
    if (sellCurrency) {
      fetchUserBalance(sellCurrency).then(setSellBalance);
    }
    if (buyCurrency) {
      fetchUserBalance(buyCurrency).then(setBuyBalance);
    }
  }, [sellCurrency, buyCurrency]);
  const handleAmountChange = async (value, type) => {
    if (value === "" || value === null) {
      if (type === "sell") {
        setSellAmount(""); 
        setBuyAmount("");
      } else {
        setBuyAmount(""); 
      }
      return;
    }

    // This regular expression ensures we allow digits and only one decimal point
    const parsedValue = value.replace(/[^0-9.]/g, "");

    // Ensure that only one decimal point is allowed
    if ((parsedValue.match(/\./g) || []).length > 1) {
      return; // Prevent more than one decimal point
    }

    // Allow only valid numbers after input
    if (isNaN(parsedValue)) return;

    const tokenType = type === "sell" ? "Sell Token" : "Buy Token";

    // Validate input with the validation function
    if (!validateInput(parsedValue, tokenType)) return;

    if (type === "sell") {
      setSellAmount(parsedValue);
      if (sellCurrency && buyCurrency) {
        const response = await postMethod({
          apiUrl: apiService.currencyConversion,
          payload: { from: sellCurrency, to: buyCurrency, amount: parsedValue },
        });
        if (response.status && response.Message) {
          setBuyAmount(parsedValue * response.Message);
        }
      }
    } else {
      setBuyAmount(parsedValue);
      // Convert buyAmount to sellAmount
      if (sellCurrency && buyCurrency) {
        const response = await postMethod({
          apiUrl: apiService.currencyConversion,
          payload: { from: buyCurrency, to: sellCurrency, amount: parsedValue },
        });
        if (response.status && response.Message) {
          setSellAmount(parsedValue * response.Message);  // Update Sell Amount
        }
      }
    }
  };


  const validateInput = (value, type) => {
    let selected = type === "Sell Token" ? sellCurrency : buyCurrency;

    if (!selected) {
      toast.error("Please choose a currency first.");
      return false;
    }

    if (!currencyLimits.minQuantity || !currencyLimits.maxQuantity) {
      toast.error("Currency limits are not set. Please select a valid currency.");
      return false;
    }

    const { minQuantity, maxQuantity } = currencyLimits;

    if (parseFloat(value) < minQuantity) {
      toast.error(`The minimum quantity for ${type} is ${minQuantity}`);
      return false;
    }

    if (parseFloat(value) > maxQuantity) {
      toast.error(`The maximum quantity for ${type} is ${maxQuantity}`);
      return false;
    }

    return true;
  };

  const handleSwap = async () => {
    // Swap currencies
    const tempCurrency = sellCurrency;
    setSellCurrency(buyCurrency);
    setBuyCurrency(tempCurrency);

    // Swap balances
    const tempBalance = sellBalance;
    setSellBalance(buyBalance);
    setBuyBalance(tempBalance);

    // Validate sellAmount after swapping currencies
    const tokenType = "Sell Token";
    if (!validateInput(sellAmount, tokenType)) return;

    // Recalculate conversion for the new configuration if a sell amount exists
    if (sellAmount && sellAmount !== "") {
      const response = await postMethod({
        apiUrl: apiService.currencyConversion,
        payload: {
          from: buyCurrency,  // new sell currency (was previous buyCurrency)
          to: tempCurrency,   // new buy currency (was previous sellCurrency)
          amount: sellAmount,
        },
      });

      if (response.status && response.Message) {
        setBuyAmount(sellAmount * response.Message);
      }
    }
  };



  const handleCancelClick = (transactionId) => {
    setSelectedTransactionId(transactionId);
    setIsCancelModalOpen(true);
  };

  const confirmCancelEscrow = () => {
    if (selectedTransactionId) {
      handleCancelEscrow(selectedTransactionId);
    }
    setIsCancelModalOpen(false);
  };

  const handleSubmit = async () => {
    const isVerified = await checkUserVerification();
    if (!isVerified) return;
    setIsModalOpen(true);
  };

  const confirmRequest = async () => {
    try {
      const balanceUpdateResponse = await postMethod({
        apiUrl: apiService.updateBalance, // Make sure this is correct
        payload: {
          sellToken: sellCurrency,
          buyToken: buyCurrency,
          sellPrice: parseFloat(sellAmount),
          buyPrice: parseFloat(buyAmount),

        },
      });
      fetchOtcData();

      if (balanceUpdateResponse?.status) {
        toast.success(balanceUpdateResponse.Message);
        const txId = balanceUpdateResponse.transactionId; // Assuming the backend sends back the transactionId
        setTransactionId(txId);

        fetchOtcData(); // Refresh table data again after balance update
      } else {
        toast.error(balanceUpdateResponse.Message);
      }

      setIsModalOpen(false);
      resetFields();
    } catch (error) {
      console.error("Error in confirmRequest:", error);
      toast.error("An error occurred while processing your request.");
    }
  };
  const handleCancelEscrow = async (txid) => {
    if (!txid) {
      toast.error("No transaction ID available to cancel.");
      return;
    }

    try {
      // Call your cancel escrow API
      const response = await postMethod({
        apiUrl: apiService.cancelEscrow,
        payload: { txid },
      });

      if (response?.status) {
        toast.success(response.Message);
        fetchOtcData(); // Refresh the OTC list to reflect the removal

        // Fetch updated balances after canceling escrow
        if (sellCurrency) {
          fetchUserBalance(sellCurrency).then(setSellBalance);
        }
        if (buyCurrency) {
          fetchUserBalance(buyCurrency).then(setBuyBalance);
        }
      } else {
        toast.error(response?.Message || "Escrow cancellation failed");
      }
    } catch (error) {
      console.error("Error canceling escrow:", error);
      toast.error("An error occurred while canceling escrow.");
    }
  };



  const handleCloseModal = () => {
    // Simply close the modal when cancel is clicked
    setIsModalOpen(false);
  };

  return (
    <div>
      <Header />
      {siteLoader === true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>

      ) : (

        <div className="">

          <div className="otc_spot_hero">
            <div className="container">
              <div className="ongo_top_man">
                <span className="otc_heading">OTC Trading</span>
                <span className="ongo_top_slash">|</span>
                <div className="ongo_other_main">
                  <div className="ongo_head_act">
                    <span>Spot</span>
                  </div>
                  <div className="ongo_head_otherinact" onClick={() => navAlgo()}>
                    <span>Algo Order</span>
                  </div>
                  <div className="ongo_head_otherinact" onClick={() => navAlgos()}>
                    <span>Otc Confirm</span>
                  </div>
                  <div className="ongo_head_otherinact" onClick={() => navOption()}>
                    <span>Option</span>
                  </div>
                </div>
              </div>
              <div className="otc_spo_defman">
                <span className="otc_spo_defman_head"> Spot Block Trading</span>
                <span className="otc_spo_defman_sub">
                  Request-for-quote (RFQ) for large and block trades.
                </span>
              </div>
              <div className="otc_spot_cent_main">
                <div className="otc_spot_card">
                  {/* Sell Token Section */}
                  <div className="otc_spot_title">
                    <h3>Sell Token</h3>
                    <div className="otc_spot_righ_topcor">
                      <span className="otc_spot_righ_totbal">Total Balance</span>
                      <span className="otc_righ_totbal_val">{sellBalance.toFixed(4)} {sellCurrency}</span>
                    </div>
                  </div>

                  <div className="otc_spo_lft_top">
                    <div className="foot_frsts">
                      <input
                        type="text"
                        id="numberInput"
                        min="0"
                        maxLength={10}
                        placeholder="0.00"
                        value={sellAmount}
                        onChange={(e) => handleAmountChange(e.target.value, 'sell')}
                        onInput={(e) => {
                          let value = e.target.value;

                          // Allow digits and one decimal point
                          value = value.replace(/[^0-9.]/g, "");

                          // Allow only one decimal point
                          if ((value.match(/\./g) || []).length > 1) {
                            value = value.slice(0, -1);  // Remove extra decimal point if present
                          }

                          // Set the input field value
                          e.target.value = value;

                          // Update the state value directly to handle the backspace issue
                          setSellAmount(value);
                        }}
                        className="swap_in_val"
                      />


                    </div>
                    <div className="otc_chng_frst">
                      <Dropdown
                        inline
                        placeholder="Select Coin"
                        options={options}
                        value={sellCurrency}  // add this controlled value
                        onChange={(e, data) => handleCurrencyChange(data.value, "sell")}
                      />
                    </div>
                  </div>

                  <div className="swap_icon my-4" onClick={handleSwap}>
                    <img src={require("../assets/swap_icon.png")} className="cursor-pointer" />
                  </div>

                  {/* Buy Token Section */}
                  <div className="otc_spot_title">
                    <h3>Buy Token</h3>
                    <div className="otc_spot_righ_topcor">
                      <span className="otc_spot_righ_totbal">Total Balance</span>
                      <span className="otc_righ_totbal_val">{buyBalance.toFixed(4)} {buyCurrency}</span>
                    </div>
                  </div>

                  <div className="otc_spo_lft_top">
                    <div className="foot_frsts">
                      <input
                        type="text"
                        id="numberInput"
                        min="0"
                        maxLength={10}
                        placeholder="0.00"
                        value={buyAmount}
                        onChange={(e) => handleAmountChange(e.target.value, 'buy')}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                          if ((e.target.value.match(/\./g) || []).length > 1) {
                            e.target.value = e.target.value.slice(0, -1);
                          }
                        }}
                        className="swap_in_val"
                        readOnly
                      />
                    </div>
                    <div className="otc_chng_frst">
                      <Dropdown
                        inline
                        placeholder="Select Coin"
                        options={options}
                        value={buyCurrency}   // add this controlled value
                        onChange={(e, data) => handleCurrencyChange(data.value, "buy")}

                      />
                    </div>
                  </div>
                  <div className="relative flex flex-col items-center">
                    <div className="Convert_btn mt-3">
                      <button className="req_btn_500" onClick={handleSubmit}>
                        RFQ Request
                      </button>
                    </div>

                  </div>
                  {/* Request Button */}
                  {/* <div className="Convert_btn mt-3">
                    <button className="req_btn_500" onClick={handleSubmit}>
                      Request
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="otc_spot_bottom_main">
                <div className="staking-flex dash_assets">
                  <h5 className="opt-title">OTC History</h5>
                  <Link to="/otcTable">
                    <div className="d-flex gap-2 text-yellow">
                      View All{" "}
                      <i class="fa-solid fa-chevron-right"></i>
                    </div>
                  </Link>
                </div>
                <div className="table-responsive table-cont dash_table_content">
                  <table className="table ">
                    <thead>
                      <tr className="stake-head-otcnow">
                        <th className="p-l-15">Buy Currency</th>
                        <th className="p-l-15">Sell Currency</th>
                        <th className="table_center_text opt-nowrap txt-center pad-left-23">Buy Price</th>
                        <th className="table_center_text opt-nowrap txt-center pad-left-23">Sell Price</th>
                        <th className="table_center_text opt-nowrap txt-center pad-left-23">Otc Status</th>
                        <th className="table_center_text opt-nowrap txt-center pad-left-23">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan={5} className="text-center py-5">
                            <div className="empty_data">Loading...</div>
                          </td>
                        </tr>
                      ) : otcData.length === 0 ? (
                        <tr>
                          <td colSpan={5} className="text-center py-5">
                            <div className="empty_data">
                              <div className="empty_data_img py-4">
                                <img
                                  src={require("../assets/no-copytrade-data.webp")}
                                  width="100px"
                                  alt="No Data"
                                />
                              </div>
                              <div>No data available</div>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        otcData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort based on the timestamp
                          .slice(0, 5).map((item, index) => (
                            <tr key={item._id}>
                              <td className="table-flex">
                                <img src={item?.buyTokenImage} alt="" />
                                <div className="table-opt-name">
                                  <h4 className="opt-name font_14">
                                    {item?.buyToken}
                                  </h4>
                                </div>
                              </td>
                              <td>
                                <div className="table-flex-newone">
                                  <img src={item?.sellTokenImage} alt="" />
                                  <div className="table-opt-name">
                                    <h4 className="opt-name font_14">
                                      {item?.sellToken}
                                    </h4>
                                  </div>
                                </div>
                              </td>
                              <td className="opt-term font_14 table_center_text pad-left-23">{item.buyPrice}</td>
                              <td className="opt-term font_14 table_center_text pad-left-23 p-0">{item.sellPrice}</td>
                              <td
                                className={`opt-term font_14 table_center_text pad-left-23 p-0 ${item.otcStatus === "Pending" ? "text-yellow" : "text-red"
                                  }`}
                              >
                                {item.otcStatus}
                              </td>
                              <td className="opt-term font_14 table_center_text pad-left-23 p-0" action_btn>
                                <button
                                  className={item.otcStatus === "Completed" ? "action_btn_disabled" : "action_btn"}
                                  onClick={() => handleCancelClick(item?.txid)}
                                  disabled={item.otcStatus === "Completed"}
                                >
                                  Cancel
                                </button>
                              </td>

                            </tr>
                          ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modals_support">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 support-modal">
                <div>
                  <div className="support-left-flex">
                    <h5 className="support-modal-title">Confirm Request</h5>
                    <i
                      className="fa-regular fa-circle-xmark cross_circle"
                      onClick={() => setIsModalOpen(false)}
                    ></i>
                  </div>
                  <div className="support-modal-tops">
                    <h6>Are you sure you want to confirm the RFQ?</h6>
                  </div>
                  <div className="support-modal-tops d-flex justify-content-center w-100">
                    {closebuttonLoader === false ? (
                      <div className="d-flex justify-content-center gap-4">
                        <button
                          class="action_btn_closed"
                          onClick={handleCloseModal}
                        >
                          Cancel
                        </button>
                        <button
                          class="action_btn_closed"
                          onClick={confirmRequest}
                        >
                          Confirm
                        </button>
                      </div>
                    ) : (
                      <button className="action_btn">Loading...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        aria-labelledby="modal-cancel-title"
        aria-describedby="modal-cancel-description"
      >
        <Box sx={style} className="modals_support">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 support-modal">
                <div>
                  <div className="support-left-flex">
                    <h5 className="support-modal-title">Confirm Cancellation</h5>
                    <i
                      className="fa-regular fa-circle-xmark cross_circle"
                      onClick={() => setIsCancelModalOpen(false)}
                    ></i>
                  </div>
                  <div className="support-modal-tops">
                    <h6>Are you sure you want to cancel this escrow transaction?</h6>
                  </div>
                  <div className="support-modal-tops d-flex justify-content-center w-100">
                    {closebuttonLoader === false ? (
                      <div className="d-flex justify-content-center gap-4">
                        <button
                          className="action_btn_closed"
                          onClick={() => setIsCancelModalOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="action_btn_closed"
                          onClick={confirmCancelEscrow}
                        >
                          Confirm
                        </button>
                      </div>
                    ) : (
                      <button className="action_btn">Loading...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

    </div>
  );

}

export default OTC;
