import React, { useEffect } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import { postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { toast } from "react-toastify";

export default function FiatDeposit() {
  const [OTP, setOTP] = useState("");
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [counter, setCounter] = useState(60);
  const [isResendVisible, setIsResendVisible] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setIsResendVisible(true);
    }
  }, [counter]);

  const submit = async () => {
    try {
      if (OTP !== "") {
        var obj = {
          emailOtp: OTP,
          // email: localStorage.getItem("useremail"),
          email: sessionStorage.getItem("useremail"),
        };

        var data = {
          apiUrl: apiService.emailotpverify,
          payload: obj,
        };
        setbuttonLoader(true);
        var resp = await postMethod(data);
        setbuttonLoader(false);
        if (resp.status == true) {
          toast.success(resp.Message);
          navigate("/login");
        } else {
          toast.error(resp.Message);
        }
      } else {
        toast.error("Enter OTP");
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  const handleResend = async () => {
    setCounter(60);
    setIsResendVisible(false);
    // Add logic to resend the OTP
    try {
      var obj = {
        email: sessionStorage.getItem("useremail"),
      };

      var data = {
        apiUrl: apiService.resendCode,
        payload: obj,
      };
      setbuttonLoader(true);

      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      setbuttonLoader(false);
    }
  };

  return (
    <>
      <section className="Non_fixed_nav">
        <Header />
      </section>{" "}
      <div>
        <div className="Verification assets_main">
          <div className="container">
            <div className="table_padding_bottom">
              <Link to="/dashboard">
                <h6>
                  {" "}
                  <i class="fa-solid fa-arrow-left-long mr-3"></i> Fiat Deposit
                </h6>
              </Link>
              <div className="row justify-content-center">
                <div className="col-lg-6 post-ad-card">
                  <span class="post-ad-title">Deposit Instructions</span>

                  <div className="fiat-notify">
                    {" "}
                    Confirm name on your bank account. The account holder should
                    be the same for your bank and Voltrix Crypt account
                  </div>
                  <div className="name_of_fidex">
                    <p>name on your voltrix Crypt account</p>
                    <h4>Xyzmhsa611</h4>

                    <h3>
                      Copy the following details into your bank’s transfer form{" "}
                    </h3>
                  </div>

                  <div className="input-groups">
                    <h6 className="input-label ref-label-title">Reference</h6>
                    <div className="ref_input">
                      <input
                        type="disable"
                        name="OTP"
                        className="fiat-inputs"
                        placeholder="EAA12 XYZ XYZ XYZO"
                      />
                      <i class="ri-file-copy-line text-yellow"></i>
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label ref-label-title">Name</h6>
                      <div className="ref_input">
                        <input
                          type="disable"
                          name="OTP"
                          className="fiat-inputs"
                          placeholder="EAA12 XYZ  LTD"
                        />
                        <i class="ri-file-copy-line text-yellow"></i>
                      </div>
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label ref-label-title">Address</h6>
                      <div className="ref_input">
                        <input
                          type="disable"
                          name="OTP"
                          className="fiat-inputs"
                          placeholder="EAA12 XYZ XYZ XYZO"
                        />
                        <i class="ri-file-copy-line text-yellow"></i>
                      </div>
                    </div>
                  </div>
                  <div className="input-groups fiat_deposit_name_details">
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label ref-label-title">Bank name</h6>
                      <div className="ref_input">
                        <input
                          type="disable"
                          name="OTP"
                          className="fiat-inputs"
                          placeholder="EAA12 XYZ  LTD"
                        />
                        <i class="ri-file-copy-line text-yellow"></i>
                      </div>
                    </div>
                    <div className="fiat_deposit_detail">
                      <h6 className="input-label ref-label-title">
                        Bank Address
                      </h6>
                      <div className="ref_input">
                        <input
                          type="disable"
                          name="OTP"
                          className="fiat-inputs"
                          placeholder="EAA12 XYZ XYZ XYZO"
                        />
                        <i class="ri-file-copy-line text-yellow"></i>
                      </div>
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label ref-label-title">
                      Account number
                    </h6>
                    <div className="ref_input">
                      <input
                        type="number"
                        name="OTP"
                        className="fiat-inputs"
                        placeholder="0012003560039956"
                      />
                    </div>
                  </div>
                  <div className="input-groups icons ifsc_code_content">
                    <h6 className="input-label ref-label-title">IFSC Code</h6>
                    <div className="ref_input">
                      <input
                        type="number"
                        name="OTP"
                        className="fiat-inputs"
                        placeholder="0012003560039956"
                      />
                    </div>
                  </div>
                  <div>
                    <h3 className="fill_text">Fill the details</h3>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label ref-label-title">
                      Transaction ID
                    </h6>
                    <div className="ref_input">
                      <input
                        type="text"
                        name="OTP"
                        className="fiat-inputs"
                        placeholder="Enter the transaction ID"
                      />
                    </div>
                  </div>
                  <div className="input-groups icons">
                    <h6 className="input-label ref-label-title">
                      Transaction Proof
                    </h6>
                    <div className="ref_input upload_icon">
                      <input
                        type="text"
                        name="OTP"
                        className="fiat-inputs"
                        placeholder="Upload the proof"
                      />
                      <i class="ri-upload-2-line"></i>
                    </div>
                  </div>
                  <div className="Submit my-4" onClick={submit}>
                    {buttonLoader == false ? (
                      <button>Submit</button>
                    ) : (
                      <button>Loading ...</button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
