import React, { useEffect } from "react";
import Header from "./Header";
import STAKEBITCOIN from "../assets/stake-bitcoin.webp";
import STAKEHERO from "../assets/stake-hero-ab.webp";
import useState from "react-usestateref";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";

function CopyTrading() {
  const [siteLoader, setSiteLoader] = useState(false);
  const [colorChanged, setColorChanged] = useState(false);
  const [startActive, setStartActive, startActiveref] = useState("future");
  const [typeActive, setTypeActive, typeActiveref] = useState("public");
  const [optionsActive, setOptionsActive, optionsActiveref] = useState("pnl");

  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setColorChanged(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const daysOptions = [
    { key: "50days", text: "50days", value: "50days" },
    { key: "100days", text: "100days", value: "100days" },
  ];

  const handleActive = async (value) => {
    setStartActive(value);
  };

  const handleType = async (value) => {
    setTypeActive(value);
  };

  const handleOptions = async (value) => {
    setOptionsActive(value);
  };

  const navNext = async () => {
    navigate("/futurecopysetting");
  };

  const navOngoing = async () => {
    navigate("/ongoingcopy");
  };

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <>
          <div className="new-popup-height">
            <div className="copy_trd_hero">
              <div className="container">
                <div className="row stake-wrapper">
                  <div className="col-lg-8">
                    <div className="copy_left_newtop">
                      <div
                        className={
                          startActiveref.current == "future"
                            ? "copie_head_active"
                            : "copie_head"
                        }
                        onClick={() => handleActive("future")}
                      >
                        <span className="">Futures</span>
                      </div>
                      <div
                        className={
                          startActiveref.current == "spot"
                            ? "copie_head_active"
                            : "copie_head"
                        }
                        onClick={() => handleActive("spot")}
                      >
                        <span className="">Spot</span>
                      </div>
                    </div>
                    <h6 className="cpoie-contents">
                      Trade Smarter with
                      <span
                        className={`crypto-span mar-lft  ${
                          colorChanged
                            ? "text-animate-inview text-animate"
                            : "text-animate"
                        }`}
                      >
                        the Power of Copying
                      </span>{" "}
                      Top Crypto Experts
                    </h6>
                    <div className="stake-sub-para">
                      Follow top traders and grow your portfolio effortlessly.
                    </div>
                  </div>

                  <div className="col-lg-4 stake-reward-right">
                    <div className="copie_rewards copie_top_resp">
                      <div className="staking-flex ">
                        <h4 className="stake-asset">Copying Margin Balance</h4>
                      </div>
                      <h2 className="copie-price ">
                        00.00 <span className="cop_top_uisdt">USDT</span>
                      </h2>
                      {/* <h5 className="stake-total">=$00.00</h5> */}
                      <div className="staking-flex mt-4">
                        <div>
                          <h5 className="stake-profit">Total Unrealized PnL</h5>
                          <h6 className="stake-profit-total ">0</h6>
                        </div>
                        <div className="copy_over_see">
                          <button
                            className="copy_had_cop"
                            onClick={() => navOngoing()}
                          >
                            Copy Overview
                          </button>
                        </div>
                        <div className="stake-bit">
                          <img
                            src={STAKEBITCOIN}
                            alt=""
                            className="stake-bit-img"
                            style={{ width: "50px" }}
                          />
                        </div>
                        <div className="stake-hero-pic">
                          <img
                            src={STAKEHERO}
                            alt=""
                            className="stake-hero-img"
                            style={{ width: "170px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="copie_second_main">
              <div className="container">
                <div className="copie_scnd_maintab">
                  <div
                    className="scnd_cop_inman"
                    onClick={() => handleType("public")}
                  >
                    <span
                      className={
                        typeActiveref.current == "public"
                          ? "scnd_cop_spa_active"
                          : "scnd_cop_spa"
                      }
                    >
                      Public Portfolios
                    </span>
                    <div className="scnd_cop_dis">
                      <div
                        className={
                          typeActiveref.current == "public"
                            ? "scnd_cop_disinn_active"
                            : "scnd_cop_disinn"
                        }
                      ></div>
                    </div>
                  </div>
                  <div
                    className="scnd_cop_inman"
                    onClick={() => handleType("private")}
                  >
                    <span
                      className={
                        typeActiveref.current == "private"
                          ? "scnd_cop_spa_active"
                          : "scnd_cop_spa"
                      }
                    >
                      Private Portfolios
                    </span>
                    <div className="scnd_cop_dis">
                      <div
                        className={
                          typeActiveref.current == "private"
                            ? "scnd_cop_disinn_active"
                            : "scnd_cop_disinn"
                        }
                      ></div>
                    </div>
                  </div>
                  <div
                    className="scnd_cop_inman"
                    onClick={() => handleType("fav")}
                  >
                    <span
                      className={
                        typeActiveref.current == "fav"
                          ? "scnd_cop_spa_active"
                          : "scnd_cop_spa"
                      }
                    >
                      My Favorites
                    </span>
                    <div className="scnd_cop_dis">
                      <div
                        className={
                          typeActiveref.current == "fav"
                            ? "scnd_cop_disinn_active"
                            : "scnd_cop_disinn"
                        }
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="copie_scnd_chanman">
                  <div className="copie_scnd_scnd_maintab">
                    <div className="copie_scndtwo_days">
                      <Dropdown
                        placeholder="Choose Days"
                        fluid
                        selection
                        options={daysOptions}
                        // onChange={(e, { value }) => dayschoose(value)}
                        className="opt-select-daysoptions"
                      />
                    </div>
                    <div className="copie_scndtwo_min">
                      <div
                        className={
                          optionsActiveref.current == "pnl"
                            ? "cop_scndrig_inn_active"
                            : "cop_scndrig_inn"
                        }
                        onClick={() => handleOptions("pnl")}
                      >
                        <span>PnL</span>
                      </div>
                      <div
                        className={
                          optionsActiveref.current == "ROI"
                            ? "cop_scndrig_inn_active"
                            : "cop_scndrig_inn"
                        }
                        onClick={() => handleOptions("ROI")}
                      >
                        <span>ROI</span>
                      </div>
                      <div
                        className={
                          optionsActiveref.current == "MDD"
                            ? "cop_scndrig_inn_active"
                            : "cop_scndrig_inn"
                        }
                        onClick={() => handleOptions("MDD")}
                      >
                        <span>MDD</span>
                      </div>
                      <div
                        className={
                          optionsActiveref.current == "AUM"
                            ? "cop_scndrig_inn_active"
                            : "cop_scndrig_inn"
                        }
                        onClick={() => handleOptions("AUM")}
                      >
                        <span>AUM</span>
                      </div>
                      <div
                        className={
                          optionsActiveref.current == "CopyTraders"
                            ? "cop_scndrig_inn_active"
                            : "cop_scndrig_inn"
                        }
                        onClick={() => handleOptions("CopyTraders")}
                      >
                        <span>Copy Traders</span>
                      </div>
                      <div
                        className={
                          optionsActiveref.current == "CopyTraderPnL"
                            ? "cop_scndrig_inn_active"
                            : "cop_scndrig_inn"
                        }
                        onClick={() => handleOptions("CopyTraderPnL")}
                      >
                        <span>Copy Trader PnL</span>
                      </div>
                      <div
                        className={
                          optionsActiveref.current == " SharpeRatio"
                            ? "cop_scndrig_inn_active"
                            : "cop_scndrig_inn"
                        }
                        onClick={() => handleOptions(" SharpeRatio")}
                      >
                        <span>Sharpe Ratio</span>
                      </div>
                    </div>
                  </div>
                  <div className="copi_scnd_riht">
                    <div className="stake-search-container">
                      <input
                        type="text"
                        maxLength={15}
                        placeholder="Search"
                        className="copii-input"
                      />
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <i class="fa-solid fa-filter"></i>
                  </div>
                </div>
                <div className="row copie_frst_row">
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button
                          className="copie_card_inbut_copy"
                          onClick={() => navNext()}
                        >
                          Copy
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row copie_frst_row">
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row copie_frst_row">
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 copi_card_resp_pad0">
                    <div className="copie_card_main">
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_frst_left">
                          <img
                            src={require("../assets/copie-inimg.webp")}
                            alt="Profile"
                          />
                          <div className="copie_cardin_frst_cen">
                            <span>KNOTMAIN</span>
                            <div className="cop_inus_dis_man">
                              <div className="cop_inus_dis">
                                <i class="fa-solid fa-user-group"></i>
                                <span> 999 / 1000 </span>
                              </div>
                              <div className="cop_inus_dis">
                                <span>API</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="copie_cardin_frst_right">
                          <i class="fa-solid fa-star"></i>
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_scnd_left">
                          <span className="copie_cardin_scnd_left_top">
                            7 Days PnL
                          </span>
                          <span className="copie_cardin_scnd_left_center">
                            +36,337.99
                          </span>
                          <span className="copie_cardin_scnd_left_top">
                            ROI <span>+5.44%</span>
                          </span>
                        </div>
                        <div className="copie_cardin_scnd_right">
                          <img
                            src={require("../assets/copie-card-chart.webp")}
                            alt="chart"
                          />
                        </div>
                      </div>
                      <div className="copie_cardin_frst">
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">AUM</span>
                          <span className="copie_cardin_third_bottom">
                            7,591,129.38
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            7 Days MDD
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                        <div className="copie_cardin_third">
                          <span className="copie_cardin_third_top">
                            Sharpe ratio
                          </span>
                          <span className="copie_cardin_third_bottom">
                            2.33%
                          </span>
                        </div>
                      </div>
                      <div className="copie_card_inbut">
                        <button className="copie_card_inbut_mark">Mock</button>
                        <button className="copie_card_inbut_copy">Copy</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq_cop">
              <div className="container">
                <h5 className="cop-opt-title">FAQ</h5>
                <div
                  class="accordion accordion-flush stake-acc-flush"
                  id="accordionFlushExample"
                >
                  <div className="copie-faq">
                    <div class="accordion-item stake-acc-item copie-bordor-bot">
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button collapsed stake-acc-btn fnt-16-copie"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          1. What is copy trading, and how does it work?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body-copie">
                          Copy trading on Voltrix Crypt lets users automatically
                          replicate expert traders' strategies in real-time for
                          effortless trading
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item stake-acc-item copie-bordor-bot">
                      <h2 class="accordion-header" id="flush-headingTwo">
                        <button
                          class="accordion-button collapsed stake-acc-btn fnt-16-copie"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          2. Do I need prior trading experience to use copy
                          trading?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body-copie">
                          No, Voltrix Crypt copy trading is designed for all
                          users, allowing even beginners to follow expert
                          traders without prior experience.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item stake-acc-item copie-bordor-bot">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <button
                          class="accordion-button collapsed stake-acc-btn fnt-16-copie"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          3. How do I choose the right trader to copy?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body-copie">
                          On Voltrix Crypt, you can choose a trader to copy by
                          reviewing their performance history, risk level,
                          success rate, and trading strategy to match your
                          goals.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item stake-acc-item copie-bordor-bot">
                      <h2 class="accordion-header" id="flush-headingFour">
                        <button
                          class="accordion-button collapsed stake-acc-btn fnt-16-copie"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          4. Can I customize the amount I invest in copy
                          trading?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body-copie">
                          Yes, Voltrix Crypt allows you to customize your
                          investment amount in copy trading, giving you full
                          control over your risk and capital allocation.
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item stake-acc-item copie-bordor-bot">
                      <h2 class="accordion-header" id="flush-headingFive">
                        <button
                          class="accordion-button collapsed stake-acc-btn fnt-16-copie"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          5. Is my investment safe with copy trading?
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        class="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body-copie">
                          While Voltrix Crypt provides access to expert
                          strategies, all investments carry risks; it’s
                          important to monitor your copied trades and adjust
                          settings as needed.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* popup */}

          <div className="vl-popup-outer">
            <div className="email-popup-card new-vl-inner-popup">
              <div className="email-pop-img">
                <img src={require("../assets/soon.webp")} alt="email-icon" />
              </div>
              <h3>Copy Trade – Coming Soon!</h3>
              <p>
                Unlock new trading possibilities with our Copy Trade feature.
                Stay tuned for the launch!
              </p>
              <div className="Submit">
                <button onClick={() => navigate("/")}>Back To Home</button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CopyTrading;
