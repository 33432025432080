import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link } from "react-router-dom";
import Header from "./Header";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import Side_bar from "./Side_bar";
import { env } from "../core/service/envconfig";
import "semantic-ui-css/semantic.min.css";
import AdvertiserTable from "./AdvertiserTable";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const P2P = () => {
  const [selectedMethod, setSelectedMethod] = useState("");
  const [selectedpayments, setselectedpayments, selectedpaymentsref] =
    useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [imageload, setimageload] = useState(false);
  const [Bankdetails, setBankdetails, Bankdetailsref] = useState({});
  const [Editdata, setEditdata, Editdataref] = useState({});
  const [Editstatus, setEditstatus, Editstatusref] = useState(false);
  const [siteLoader, setSiteLoader] = useState(false);
  const [buttonLoader, setbuttonLoader] = useState(false);
  const [buttonLoaderEdit, setbuttonLoaderEdit] = useState(false);

  const [formData, setFormData, formDataref] = useState({
    name: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifsc: "",
    accountType: "",
    status: "",
    upid: "",
    QRcode: null,
  });
  const [errors, setErrors] = useState({});

  const paymentMethods = [
    {
      key: "imps",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-imps"></div>
          IMPS
        </div>
      ),
      value: "IMPS",
    },
    {
      key: "upid",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-upi"></div>
          UPI ID
        </div>
      ),
      value: "UPID",
    },
    {
      key: "paytm",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-paytm"></div>
          Paytm
        </div>
      ),
      value: "Paytm",
    },
    {
      key: "bankTransfer",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-bank"></div>
          Bank Transfer
        </div>
      ),
      value: "BankTransfer",
    },
  ];

  const paymentMethod2s = [
    {
      key: "all",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-all"></div>ALL
        </div>
      ),
      value: "all",
    },
    {
      key: "imps",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-imps"></div>
          IMPS
        </div>
      ),
      value: "IMPS",
    },
    {
      key: "upid",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-upi"></div>UPI ID
        </div>
      ),
      value: "UPID",
    },
    {
      key: "paytm",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-paytm"></div>
          Paytm
        </div>
      ),
      value: "Paytm",
    },
    {
      key: "bankTransfer",
      text: (
        <div className="d-flex align-items-center fw-200">
          <div className="pay-bor bg-bank"></div>
          Bank Transfer
        </div>
      ),
      value: "BankTransfer",
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [total, settotal] = useState(5);
  const recordPerPage = 5;

  useEffect(() => {
    Getbankdetails(1);
  }, [0]);

  const Getbankdetails = async (pageNumber) => {
    try {
      setSiteLoader(true);
      setCurrentPage(pageNumber);
      var data = {
        apiUrl: apiService.Getbankdetails,
        payload: {
          FilPerpage: 5,
          FilPage: pageNumber,
          type:
            selectedpaymentsref.current == "all"
              ? ""
              : selectedpaymentsref.current,
        },
      };

      var resp = await postMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        setBankdetails(resp.data);
        settotal(resp.total);
      } else {
        setBankdetails({});
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
    Getbankdetails(pageNumber);
  };

  const handleMethodChange = (e, { value }) => {
    setSelectedMethod(value);
    setFormVisible(true);
    setEditstatus(false);
    setErrors({}); // Reset errors when method changes
  };

  const check = () => {
    toast.error(
      "You can only upload upto 20 details, you want to add one more means you should delete one of the existing details "
    );
  };
  const handlefillter = (e, { value }) => {
    setselectedpayments(value);
    Getbankdetails();
  };
  const sanitizeInput = (value) => {
    return value.trim();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let values = e.target.value;
    // const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
    // const sanitizedValue = value.replace(/[^a-zA-Z0-9@._-]/g, "");
    // const sanitizedValue = value;
    if (values.startsWith(" ")) {
      values = values.trimStart(); // Remove the leading space
    }
    setFormData({ ...formData, [e.target.name]: values });
    validateForm(formDataref.current);
  };

  // var get_status = (value)=>{
  //   console.log(value.target,"value.target")
  //   formData.status =value.target.value == 0 ?1:0
  //   validateForm(formDataref.current);
  // }
  const get_status = (e) => {
    setFormData({ ...formData, status: e.target.value });
    validateForm(formDataref.current);
  };
  const handleFileChange = (e) => {
    try {
      const fileExtension = e.name.split(".").at(-1);
      const fileSize = e.size;
      const fileName = e.name;
      if (
        fileExtension != "png" &&
        fileExtension != "webp" &&
        fileExtension != "jpeg" &&
        fileExtension != "jpg"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg or .webp "
        );
        setimageload(false);
        // document.getElementById("file-input").value = "";
      } else if (fileSize > 10000000) {
        toast.error("Please upload a file smaller than 1 MB");
        setimageload(false);
      } else {
        setimageload(true);
        const data = new FormData();
        data.append("file", e);
        data.append("upload_preset", env.upload_preset);
        data.append("cloud_name", env.cloud_name);
        fetch(
          "https://api.cloudinary.com/v1_1/" + env.cloud_name + "/auto/upload",
          { method: "post", body: data }
        )
          .then((resp) => resp.json())
          .then((data) => {
            setFormData({ ...formData, ["QRcode"]: data.secure_url });

            validateForm(formDataref.current);
            setimageload(false);
          })
          .catch((err) => {
            setimageload(false);
            toast.error("Please try again later");
          });
      }
    } catch (error) {
      setimageload(false);
      toast.error("Please try again later");
    }
  };

  const validateForm = (formData) => {
    try {
      const newErrors = {};
      if (!formData.name) {
        newErrors.name = "Name is required";
      } else if (
        selectedMethod === "IMPS" ||
        selectedMethod === "BankTransfer"
      ) {
        if (!formData.status) {
          newErrors.status = "Status is required";
        } else if (!formData.accountNumber) {
          newErrors.accountNumber = "Account Number is required";
        } else if (!formData.ifsc) {
          newErrors.ifsc = "IFSC is required";
        } else if (!formData.bankName) {
          newErrors.bankName = "Bank Name is required";
        } else if (!formData.accountType) {
          newErrors.accountType = "Account Type is required";
        } else if (!formData.branch) {
          newErrors.branch = "Branch is required";
        }
      } else if (selectedMethod === "UPID") {
        if (!formData.status) {
          newErrors.status = "Status is required";
        } else if (!formData.upid) {
          newErrors.upid = "UPI ID is required";
        } else if (formData.upid.length < 3 || formData.upid.length > 50) {
          newErrors.upid =
            "UPI ID must have at least 3 characters and at most 50 characters";
        } else if (!formData.QRcode) {
          newErrors.upidQR = "QR code is required";
        }
      } else if (selectedMethod === "Paytm") {
        if (!formData.status) {
          newErrors.status = "Status is required";
        } else if (!formData.upid) {
          newErrors.upid = "Paytm No is required";
        } else if (formData.upid.length < 8 || formData.upid.length > 15) {
          newErrors.upid = "Paytm No must be between 8 and 15 numbers";
        } else if (!formData.QRcode) {
          newErrors.paytmQR = "QR code is required";
        }
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    } catch (err) {
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm(formDataref.current)) {
      formData.type = selectedMethod;
      // Submit the form

      var data = {
        apiUrl: apiService.addbankdetails,
        payload: formDataref.current,
      };
      setbuttonLoader(true);
      var resp = await postMethod(data);
      setbuttonLoader(false);
      if (resp.status) {
        toast.success(resp.Message);
        Sentback();
      } else {
        toast.error(resp.Message);
        Sentback();
      }

      Getbankdetails();
    } else {
      const error = await validateForm(formData);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    if (validateForm(formDataref.current)) {
      formData.type = selectedMethod;


      var data = {
        apiUrl: apiService.updateBankdetails,
        payload: formDataref.current,
      };
      setbuttonLoaderEdit(true);
      var resp = await postMethod(data);
      setbuttonLoaderEdit(false);
      if (resp.status) {
        toast.success(resp.Message);

        Sentback();
      } else {
        toast.error(resp.Message);
        Sentback();
      }
      setEditstatus(false);
      setFormVisible(false);
      setSelectedMethod("");
      setFormData({});
      Getbankdetails(1);
    } else {
      const error = await validateForm(formData);
    }
  };

  const Sentback = () => {
    setSelectedMethod("");
    setFormVisible(false);
    setFormData({});
    setErrors({});
  };

  const Editpayment = (data) => {
    setSelectedMethod(data.type);
    setFormData({
      name: data.Accout_HolderName,
      accountNumber: data.Account_Number,
      bankName: data.Bank_Name,
      branch: data.Branch_Name,
      ifsc: data.IFSC_code,
      status: data.Status,
      accountType: data.Account_Type,
      upid: data.Upid_ID,
      QRcode: data.QRcode,
      _id: data._id,
    });
    // console.log(document.getElementById("file-input"));
    // document.getElementById("file-input").value = "Change File"

    setEditstatus(true);
    setFormVisible(true);
  };

  const deletePayment = async (data) => {
    setSiteLoader(true);

    var data = {
      apiUrl: apiService.deletbankDetails,
      payload: { _id: data },
    };

    var resp = await postMethod(data);
    setSiteLoader(false);

    if (resp.status) {
      toast.success(resp.message);
      Getbankdetails(1);
    } else {
      Getbankdetails(1);
      toast.error(resp.message);
    }
  };

  return (
    <>
      <section>
        <Header />
      </section>
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main">
          <div className="container">
            <div className="row">
              {!formVisible ? (
                <div className="col-lg-12">
                  <section className="asset_section">
                    <div className="row">
                      {/* head */}
                      <div className="p2p-pay-wrap p2p-order-head">
                        <Link to="/p2p">
                          <div className="p2p-order-title text-p2p">P2P</div>
                        </Link>
                        <div className="p2p-side-arrow">
                          <i className="ri-arrow-right-s-line"></i>
                        </div>
                        <div className="p2p-order-title text-order">
                          Payments
                        </div>
                      </div>

                      {/* <div className="p2p_title align-items-center">
                        Payment
                      </div> */}

                      <div className="p2p_method_content">
                        <p>
                          When adding a payment method on Voltrix Crypt, ensure
                          the account name matches your verified name for secure
                          transactions. Your payment methods will be visible to
                          others during trades, allowing you to manage up to 20
                          options for flexibility and convenience.
                        </p>

                        <div className="pay-coin">
                          <span className="icon-container">
                            <i className="fa-solid fa-plus"></i>
                          </span>
                          {total > 20 ? (
                            <div
                              placeholder="Add Payment Details"
                              className="check"
                              onClick={check}
                            >
                              {" "}
                              Add Payment Details
                            </div>
                          ) : (
                            <Dropdown
                              inline
                              placeholder="Add Payment Details"
                              options={paymentMethods}
                              value={selectedMethod}
                              onChange={handleMethodChange}
                              className="pay-coin payment"
                            />
                          )}
                        </div>
                      </div>

                      <div className="pay-coin newpay_fil">
                        <span className="icon-container">
                          {!selectedpaymentsref.current ? (
                            <i class="fa-solid fa-filter"></i>
                          ) : (
                            ""
                          )}
                        </span>
                        <Dropdown
                          inline
                          placeholder="Filter payments"
                          options={paymentMethod2s}
                          value={selectedpayments}
                          onChange={handlefillter}
                          className="pay-coin payment"
                        />
                      </div>

                      <div className="col-lg-12">
                        <div className="mt-5">
                          {Array.isArray(Bankdetails) &&
                          Bankdetails.length > 0 ? (
                            Bankdetails.map((options, index) => (
                              <div className="payments mt-5" key={index}>
                                <div className="payment-head">
                                  <div className="d-flex align-items-center">
                                    {" "}
                                    {options.type === "IMPS" && (
                                      <div className="pay-bor bg-imps"></div>
                                    )}
                                    {options.type === "UPID" && (
                                      <div className="pay-bor bg-upi"></div>
                                    )}
                                    {options.type === "Paytm" && (
                                      <div className="pay-bor bg-paytm"></div>
                                    )}
                                    {options.type === "BankTransfer" && (
                                      <div className="pay-bor bg-bank"></div>
                                    )}
                                    { options.type === "UPID" ? "UPI ID":options.type}
                                  </div>
                                  <div>
                                    <span
                                      onClick={() => Editpayment(options)}
                                      className="cursor-pointer"
                                    >
                                      Edit
                                    </span>
                                    <span
                                      onClick={() => deletePayment(options._id)}
                                      className="cursor-pointer"
                                    >
                                      Delete
                                    </span>
                                  </div>
                                </div>

                                <div className="payment-table row">
                                  <div className="col-lg-3">
                                    <div className="label">Full Name</div>
                                    <div className="content">
                                      {options.Accout_HolderName || "N/A"}
                                    </div>
                                  </div>

                                  {options.type == "UPID" ||
                                  options.type == "Paytm" ? (
                                    <>
                                      <div className="col-lg-3">
                                        {options.type == "UPID" ? (
                                          <div className="label">Upi No</div>
                                        ) : (
                                          <div className="label">Paytm No</div>
                                        )}
                                        <div className="content">
                                          {options.Upid_ID.slice(0, 13) ||
                                            "N/A"}
                                          ...
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="label">QR CODE</div>
                                        <div className="content">
                                          <img
                                            src={options.QRcode}
                                            alt="QR Code"
                                            width="100px"
                                            height="100px"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-lg-3">
                                        <div className="label">Status</div>
                                        {options.Status == 0 ||
                                        options.Status == 2 ? (
                                          <div className="content text-red">
                                            De Active
                                          </div>
                                        ) : (
                                          <div className="content text-yellow">
                                            Active
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {options.type == "IMPS" ||
                                  options.type == "BankTransfer" ? (
                                    <>
                                      <div className="col-lg-3">
                                        <div className="label">Account No</div>
                                        <div className="content">
                                          {options.Account_Number.slice(0, 13)}
                                          ...
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="label">IFSC Code</div>
                                        <div className="content">
                                          {options.IFSC_code.slice(0, 13) ||
                                            "N/A"}
                                          ....
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="label">Status</div>
                                        {options.Status == 0 ||
                                        options.Status == 2 ? (
                                          <div className="content text-red">
                                            De Active
                                          </div>
                                        ) : (
                                          <div className="content text-yellow">
                                            Active
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ))
                          ) : (
                            <div className="not-pay-wrapper">
                              {/* No bank details available. */}
                              <img
                                src={require("../assets/not-add-pay.png")}
                                alt="not-pay"
                                className="not-pay"
                              />
                            </div>
                          )}

                          {Bankdetails && Bankdetails.length > 0 ? (
                            <div className="pagination p-5">
                              <Stack spacing={2}>
                                <Pagination
                                  count={Math.ceil(total / recordPerPage)}
                                  page={currentPage}
                                  onChange={handlePageChange}
                                  size="small"
                                  sx={{
                                    "& .MuiPaginationItem-root": {
                                      color: "#fff",
                                    },
                                    "& .Mui-selected": {
                                      backgroundColor: "#ffc630 !important", // Background color for selected item
                                      color: "#000", // Text color for selected item
                                      "&:hover": {
                                        backgroundColor: "#ffc630",
                                        color: "#000",
                                      },
                                    },
                                    "& .MuiPaginationItem-ellipsis": {
                                      color: "#fff", // Color for ellipsis
                                    },
                                    "& .MuiPaginationItem-icon": {
                                      color: "#fff", // Color for icon (if present)
                                    },
                                  }}
                                />
                              </Stack>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              ) : (
                <>
                  <div className="col-lg-12">
                    <section className="asset_section">
                      <div className="row mt-5 justify-content-center">
                        <div>
                          <Link onClick={Sentback} className="">
                            <h6 className="payment-back">
                              {" "}
                              <i class="fa-solid fa-arrow-left-long mr-4"></i>{" "}
                              <span>Back</span>
                            </h6>
                          </Link>
                        </div>

                        <div className="row justify-content-center pay-cards mt-4">
                          <div className="col-lg-7 ">
                            <form className="p2p-payment-form">
                              <div className="p2p_payment">
                                {/* border-left colors */}
                                {selectedMethod === "IMPS" && (
                                  <div className="pay-bor bg-imps"></div>
                                )}
                                {selectedMethod === "UPID" && (
                                  <div className="pay-bor bg-upi"></div>
                                )}
                                {selectedMethod === "Paytm" && (
                                  <div className="pay-bor bg-paytm"></div>
                                )}
                                {selectedMethod === "BankTransfer" && (
                                  <div className="pay-bor bg-bank"></div>
                                )}

                                {/* select method */}
                                {selectedMethod === "IMPS"
                                  ? "IMPS"
                                  : selectedMethod === "UPID"
                                  ? "UPI ID"
                                  : selectedMethod === "Paytm"
                                  ? "Paytm"
                                  : selectedMethod === "BankTransfer"
                                  ? "Bank Transfer"
                                  : ""}
                              </div>

                              <div className="pay-tips">
                                <span>
                                  <img
                                    src={require("../assets/deposit-imp.png")}
                                    alt="tips-icon"
                                    className="pay-tips-icon"
                                  />
                                </span>

                                <div>
                                  <span className="text-yellow">Tips:</span>

                                  {selectedMethod === "IMPS" && (
                                    <span className="pay-tips-content">
                                      Enter your account details accurately and
                                      ensure they match your verified name for
                                      secure transactions.
                                    </span>
                                  )}
                                  {selectedMethod === "UPID" && (
                                    <span className="pay-tips-content">
                                      Enter your UPI ID accurately and ensure it
                                      matches your verified name for secure
                                      transactions.
                                    </span>
                                  )}
                                  {selectedMethod === "Paytm" && (
                                    <span className="pay-tips-content">
                                      Enter your Paytm accurately and ensure it
                                      matches your verified name for secure
                                      transactions.
                                    </span>
                                  )}
                                  {selectedMethod === "BankTransfer" && (
                                    <span className="pay-tips-content">
                                      Enter your bank account details accurately
                                      and ensure they match your verified name
                                      for secure transactions.
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="input-groups">
                                <h6 className="input-label text-white">Name</h6>
                                <input
                                  type="text"
                                  name="name"
                                  className="payment-input"
                                  autoComplete="off"
                                  placeholder="Please enter your name"
                                  maxLength="30"
                                  value={formData.name || ""}
                                  onChange={handleInputChange}
                                />
                                {errors.name && (
                                  <span className="errorcss">
                                    {errors.name}
                                  </span>
                                )}
                              </div>

                              <div className="input-groups">
                                <h6 className="input-label text-white">
                                  Status
                                </h6>
                                <div className="radio">
                                  <div>
                                    <input
                                      type="radio"
                                      name="status"
                                      value="1"
                                      onChange={get_status}
                                      checked={formData.status == "1"}
                                    />{" "}
                                    Active
                                  </div>
                                  <div>
                                    <input
                                      type="radio"
                                      name="status"
                                      value="2"
                                      onChange={get_status}
                                      checked={
                                        formData.status == "2" ||
                                        formData.status == "0"
                                      }
                                    />{" "}
                                    Deactive
                                  </div>
                                </div>
                                {errors.status && (
                                  <span className="errorcss">
                                    {errors.status}
                                  </span>
                                )}
                              </div>
                              {selectedMethod === "IMPS" ||
                              selectedMethod === "BankTransfer" ? (
                                <>
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Bank Account/Card Number
                                    </h6>
                                    <input
                                      type="Number"
                                      name="accountNumber"
                                      onChange={(e) => {
                                        // Allow only numbers and limit the length to 10 digits
                                        const value = e.target.value;
                                        if (
                                          value.length <= 30 &&
                                          /^[0-9]*$/.test(value)
                                        ) {
                                          handleInputChange(e);
                                        }
                                      }}
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(
                                          evt.key
                                        ) && evt.preventDefault()
                                      }
                                      className="payment-input"
                                      placeholder="Please enter your bank account/card number"
                                      value={formData.accountNumber || ""}
                                      // onChange={handleInputChange}
                                    />
                                    {errors.accountNumber && (
                                      <span className="errorcss">
                                        {errors.accountNumber}
                                      </span>
                                    )}
                                  </div>

                                  {/* ifsc */}
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      IFSC Code
                                    </h6>
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      maxLength="30"
                                      name="ifsc"
                                      className="payment-input"
                                      placeholder="Please enter your IFSC code"
                                      value={formData.ifsc || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.ifsc && (
                                      <span className="errorcss">
                                        {errors.ifsc}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Bank Name
                                    </h6>
                                    <input
                                      type="text"
                                      name="bankName"
                                      autoComplete="off"
                                      maxLength="30"
                                      className="payment-input"
                                      placeholder="Please enter your bank name"
                                      value={formData.bankName || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.bankName && (
                                      <span className="errorcss">
                                        {errors.bankName}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Account Type
                                    </h6>
                                    <input
                                      type="text"
                                      name="accountType"
                                      autoComplete="off"
                                      maxLength={50}
                                      className="payment-input"
                                      placeholder="Specify the account type (savings or current)"
                                      value={formData.accountType || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.accountType && (
                                      <span className="errorcss">
                                        {errors.accountType}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Account Branch
                                    </h6>
                                    <input
                                      type="text"
                                      name="branch"
                                      autoComplete="off"
                                      maxLength="30"
                                      className="payment-input"
                                      placeholder="Please enter your account branch"
                                      value={formData.branch || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.branch && (
                                      <span className="errorcss">
                                        {errors.branch}
                                      </span>
                                    )}
                                  </div>
                                </>
                              ) : null}

                              {selectedMethod === "UPID" && (
                                <>
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      UPI ID
                                    </h6>
                                    <input
                                      type="text"
                                      name="upid"
                                      maxLength={50}
                                      autoComplete="off"
                                      className="payment-input"
                                      placeholder="Please enter your UPI ID"
                                      value={formData.upid || ""}
                                      onChange={handleInputChange}
                                    />
                                    {errors.upid && (
                                      <span className="errorcss">
                                        {errors.upid}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Payment QR Coded
                                    </h6>

                                    {formData.QRcode ? (
                                      <div className="uploadedimage">
                                        <input
                                          id="file-input"
                                          type="file"
                                          name="upidQR"
                                          className="payment-input-QR bg-transparent cursor-pointer new_pp_indchn"
                                          onChange={(e) =>
                                            handleFileChange(e.target.files[0])
                                          }
                                        />
                                        <div className="uploadedimage2">
                                          <span>File Uploaded</span>
                                        </div>
                                        <label
                                          htmlFor="file-input"
                                          className="file-input-label-afterfile"
                                        >
                                          {imageload ? (
                                            <i class="fa-solid fa-spinner fa-spin"></i>
                                          ) : (
                                            <i class="fa-solid fa-arrow-up-from-bracket cursor-pointer"></i>
                                          )}
                                        </label>
                                      </div>
                                    ) : (
                                      <div className="file-input-wrapper">
                                        <input
                                          id="file-input"
                                          type="file"
                                          name="upidQR"
                                          className="payment-input-QR bg-transparent cursor-pointer"
                                          onChange={(e) =>
                                            handleFileChange(e.target.files[0])
                                          }
                                        />
                                        <label
                                          htmlFor="file-input"
                                          className="file-input-label"
                                        >
                                          {imageload ? (
                                            <i class="fa-solid fa-spinner fa-spin"></i>
                                          ) : (
                                            <i class="fa-solid fa-arrow-up-from-bracket cursor-pointer"></i>
                                          )}
                                        </label>
                                      </div>
                                    )}

                                    {errors.upidQR && (
                                      <span className="errorcss">
                                        {errors.upidQR}
                                      </span>
                                    )}

                                    {formData.QRcode ? (
                                      <img
                                        src={formData.QRcode}
                                        width="100px"
                                        className=" mt-4imagecustomsize"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}

                              {selectedMethod === "Paytm" && (
                                <>
                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      {" "}
                                      Paytm No
                                    </h6>
                                    <input
                                      type="number"
                                      name="upid"
                                      className="payment-input"
                                      placeholder="Enter your paytm number"
                                      autoComplete="off"
                                      onKeyDown={(evt) =>
                                        ["e", "E", "+", "-"].includes(
                                          evt.key
                                        ) && evt.preventDefault()
                                      }
                                      value={formData.upid || ""}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        // Allow only up to 15 digits for Paytm No
                                        if (value.length <= 15) {
                                          setFormData({
                                            ...formData,
                                            upid: value,
                                          });
                                          validateForm(formDataref.current); // Call the validation after setting form data
                                        }
                                      }}
                                      // onChange={handleInputChange}
                                    />
                                    {errors.upid && (
                                      <span className="errorcss">
                                        {errors.upid}
                                      </span>
                                    )}
                                  </div>

                                  <div className="input-groups">
                                    <h6 className="input-label text-white">
                                      Paytm QR Code
                                    </h6>
                                    {/* <div className="file-input-wrapper">
                                      <input
                                        id="file-input-paytm"
                                        type="file"
                                        name="paytmQR"
                                        // value={formData.QRcode}
                                        className="payment-input-QR bg-transparent cursor-pointer"
                                        onChange={(e) =>
                                          handleFileChange(e.target.files[0])
                                        }
                                      />
                                      <label
                                        htmlFor="file-input-paytm"
                                        className="file-input-label"
                                      >
                                        <i class="fa-solid fa-arrow-up-from-bracket"></i>
                                      </label>
                                    </div> */}

                                    {formData.QRcode ? (
                                      <div className="uploadedimage">
                                        <input
                                          id="file-input"
                                          type="file"
                                          name="upidQR"
                                          className="payment-input-QR bg-transparent cursor-pointer new_pp_indchn"
                                          onChange={(e) =>
                                            handleFileChange(e.target.files[0])
                                          }
                                        />
                                        <div className="uploadedimage2">
                                          <span>File Uploaded</span>
                                        </div>
                                        <label
                                          htmlFor="file-input"
                                          className="file-input-label-afterfile"
                                        >
                                          {imageload ? (
                                            <i class="fa-solid fa-spinner fa-spin"></i>
                                          ) : (
                                            <i class="fa-solid fa-arrow-up-from-bracket cursor-pointer"></i>
                                          )}
                                        </label>
                                      </div>
                                    ) : (
                                      <div className="file-input-wrapper">
                                        <input
                                          id="file-input"
                                          type="file"
                                          name="upidQR"
                                          className="payment-input-QR bg-transparent cursor-pointer"
                                          onChange={(e) =>
                                            handleFileChange(e.target.files[0])
                                          }
                                        />
                                        <label
                                          htmlFor="file-input"
                                          className="file-input-label"
                                        >
                                          {imageload ? (
                                            <i class="fa-solid fa-spinner fa-spin"></i>
                                          ) : (
                                            <i class="fa-solid fa-arrow-up-from-bracket cursor-pointer"></i>
                                          )}
                                        </label>
                                      </div>
                                    )}

                                    {errors.paytmQR && (
                                      <span className="errorcss">
                                        {errors.paytmQR}
                                      </span>
                                    )}

                                    {formData.QRcode ? (
                                      <img
                                        className="imagecustomsize mt-4"
                                        src={formData.QRcode}
                                        width="100px"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </>
                              )}

                              <div className="ad-upload">
                                <div className="Submit mt-4">
                                  {Editstatus == false ? (
                                    <>
                                      {buttonLoader == false ? (
                                        <button
                                          type="submit"
                                          onClick={handleSubmit}
                                        >
                                          Add Payment Method
                                        </button>
                                      ) : (
                                        <button type="submit">
                                          Loading...
                                        </button>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {buttonLoaderEdit == false ? (
                                        <button
                                          type="submit"
                                          onClick={handleEdit}
                                        >
                                          Edit Payment Method
                                        </button>
                                      ) : (
                                        <button type="submit">
                                          Loading ...
                                        </button>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default P2P;
