import React, { useEffect, useRef } from "react";
import Header from "./Header";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";
import "./OTC.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

import { socket } from '../context/socket';

const OtcHistoryTable = () => {
    const [otcData, setOtcData] = useState([]);
    const [siteLoader, setSiteLoader] = useState(false);
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [otccurrentpage, setOtcCurrentPage] = useState(1);
    const [otctotalpage, setOtcTotalPages] = useState(0);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [closebuttonLoader, setClosebuttonLoader] = useState(false);
    const [cancelMessage, setCancelMessage] = useState("");
    const token = sessionStorage.getItem('socketToken');

    const [notification, setnotification] = useState("");
    const [hasUnread, setHasUnread] = useState(false);

    const [selectedOrderOwnerId, setSelectedOrderOwnerId] = useState(null); // New state to store orderOwnerId

    let userid = '';

    if (token) {
        let tokensplit = token.split("_");
        userid = tokensplit[0]; // Assuming the first part of the token is the user ID
    } else {
        userid = new Date().getTime(); // Fallback to a unique identifier if token is absent
    }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    const fetchOtcData = async (page) => {
        setSiteLoader(true);

        const response = await getMethod({ apiUrl: apiService.otcGetAll });

        if (response && Array.isArray(response)) {
            setOtcData(response); // Set the fetched OTC data
            setOtcTotalPages(Math.ceil(response.length / 5)); // Calculate total pages based on the data length
        } else {
        }

        setSiteLoader(false);
    };

    useEffect(() => {
        fetchOtcData(1); // Fetch OTC data on component mount
    }, []);

    const handlePageOtc = (event, page) => {
        fetchOtcData(page);
        setOtcCurrentPage(page);
    };
    const userToken = sessionStorage.getItem("useremail"); // Get the token

    const handleConfirmTransaction = async (txid, orderOwnerId) => {
        try {
            const payload = { txid1: txid, userId: orderOwnerId }; // Send order owner's ID
            const payload2 = { txid1: txid, userId: orderOwnerId, oppositeUserId: userToken }

            socket.emit("userConfirmTransaction", payload2);

            const response = await postMethod({
                apiUrl: apiService.confirmTransaction,
                payload,
            });


            if (response && response.Message) {
                if (response.status) {
                    fetchOtcData();
                    toast.success(response.Message);
                } else {
                    toast.error(response.Message);
                }
            } else {
                toast.error("Unexpected response from server");
            }
            fetchOtcData();
        } catch (error) {
            toast.error("Error confirming transaction");
        }
    };


    useEffect(() => {
        setSiteLoader(false);

        // let socket_token = localStorage.getItem("socketToken");
        let socket_token = sessionStorage.getItem("socketToken");
        if (
            socket_token == null ||
            socket_token == undefined ||
            socket_token == ""
        ) {
            return;
        }
        let socketsplit = socket_token?.split("_");
        socket.connect();
        // socket.off("socketResponse");
        socket.on("socketResponse" + socketsplit[0], function (res) {
            if (res.Reason == "notify") {
                toast.success(res.Message, {
                    toastId: "3",
                });
            } else if (res.Reason == "ordercancel") {
                toast.success(res.Message, {
                    toastId: "3",
                });
            }
        });
        let userToken = sessionStorage.getItem("user_token");
        if (userToken) {
            socket.emit("getnotifications");
            // console.log("updatenotifications socketenters -->>");
            socket.on("updatenotifications", async (response) => {
                // console.log("updatenotifications socket -->>",response);
                setnotification(response.data.notification);
                if (response.data.status > 0) {
                    setHasUnread(true);
                }
            });
        }
    }, [0]);







    const confirmCancelEscrow = () => {
        if (selectedTransactionId && selectedOrderOwnerId) {
            handleConfirmTransaction(selectedTransactionId, selectedOrderOwnerId);
        }
        setIsCancelModalOpen(false);
    };



    const handleCancelClick = (transactionId, orderOwnerId) => {
        setSelectedTransactionId(transactionId);
        setSelectedOrderOwnerId(orderOwnerId); // Store order owner ID
        setIsCancelModalOpen(true);
        setCancelMessage(`Confirm transaction with User ID: ${orderOwnerId}`);
    };



    return (
        <>
            <section>
                <Header />
            </section>
            {siteLoader ? (
                <div className="loadercss">
                    <Bars
                        height="80"
                        width="80"
                        color="#ffc630"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            ) : (
                <main className="dashboard_main">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-lg-2">
                                <Side_bar />
                            </div>

                            <div className="col-lg-10">
                                <section className="asset_section">
                                    <div className="row">
                                        <div className="buy_head">
                                            <div className="Buycrypto_title">OTC History</div>

                                            <div className="table-responsive table-cont dash_table_content">
                                                <table className="table">
                                                    <thead>
                                                        <tr className="stake-head-otcnow">
                                                            <th className="p-l-15">Buy Currency</th>
                                                            <th className="p-l-15">Sell Currency</th>
                                                            <th className="table_center_text opt-nowrap txt-center pad-left-23">Buy Price</th>
                                                            <th className="table_center_text opt-nowrap txt-center pad-left-23">Sell Price</th>
                                                            <th className="table_center_text opt-nowrap txt-center pad-left-23">Otc Status</th>
                                                            <th className="table_center_text opt-nowrap txt-center pad-left-23">Otc Confirm</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {otcData.length > 0 ? (
                                                            otcData.slice((otccurrentpage - 1) * 5, otccurrentpage * 5).map((item) => {
                                                                return (
                                                                    <tr key={item._id}>
                                                                        <td className="table-flex">
                                                                            <img src={item?.buyTokenImage} alt="" />
                                                                            <div className="table-opt-name">
                                                                                <h4 className="opt-name font_14">
                                                                                    {item?.buyToken}
                                                                                </h4>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="table-flex-newone">
                                                                                <img src={item?.sellTokenImage} alt="" />
                                                                                <div className="table-opt-name">
                                                                                    <h4 className="opt-name font_14">
                                                                                        {item?.sellToken}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="opt-term font_14 table_center_text pad-left-23">{item.buyPrice}</td>
                                                                        <td className="opt-term font_14 table_center_text pad-left-23 p-0">{item.sellPrice}</td>
                                                                        <td className="opt-term font_14 table_center_text pad-left-23 p-0">{item.otcStatus}</td>
                                                                        <td className="opt-term font_14 table_center_text pad-left-23 p-0" action_btn>
                                                                            <button
                                                                                className="action_btn"
                                                                                onClick={() => handleCancelClick(item?.txid, item?.userId)} // Pass txid and userId
                                                                            >
                                                                                Confirm
                                                                            </button>
                                                                        </td>


                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={5} className="text-center py-5">
                                                                    <div className="empty_data">
                                                                        <div className="empty_data_img">
                                                                            <img
                                                                                src={require("../assets/No-data.webp")}
                                                                                width="100px"
                                                                            />
                                                                        </div>
                                                                        <div className="no_records_text">No Records Found</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>

                                                {otcData.length > 0 && (
                                                    <div className="pagination">
                                                        <Stack spacing={2}>
                                                            <Pagination
                                                                count={otctotalpage}
                                                                page={otccurrentpage}
                                                                onChange={handlePageOtc}
                                                                size="small"
                                                                sx={{
                                                                    "& .MuiPaginationItem-root": {
                                                                        color: "#fff",
                                                                    },
                                                                    "& .Mui-selected": {
                                                                        backgroundColor: "#ffc630 !important",
                                                                        color: "#000",
                                                                    },
                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                        color: "#fff",
                                                                    },
                                                                    "& .MuiPaginationItem-icon": {
                                                                        color: "#fff",
                                                                    },
                                                                }}
                                                            />
                                                        </Stack>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <Modal
                            open={isCancelModalOpen}
                            onClose={() => setIsCancelModalOpen(false)}
                            aria-labelledby="modal-cancel-title"
                            aria-describedby="modal-cancel-description"
                        >
                            <Box sx={style} className="modals_support">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12 support-modal">
                                            <div>
                                                <div className="support-left-flex">
                                                    <h5 className="support-modal-title">Confirm Transaction</h5>
                                                    <i
                                                        className="fa-regular fa-circle-xmark cross_circle"
                                                        onClick={() => setIsCancelModalOpen(false)}
                                                    ></i>
                                                </div>
                                                <div className="support-modal-tops">
                                                    <h6>Are you sure want to confirm this order </h6> {/* Display User ID */}
                                                </div>

                                                <div className="support-modal-tops d-flex justify-content-center w-100">
                                                    {closebuttonLoader === false ? (
                                                        <div className="d-flex justify-content-center gap-4">
                                                            <button
                                                                className="action_btn_closed"
                                                                onClick={() => setIsCancelModalOpen(false)}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className="action_btn_closed"
                                                                onClick={() => confirmCancelEscrow()} // No need to pass parameters now
                                                            >
                                                                Confirm
                                                            </button>


                                                        </div>
                                                    ) : (
                                                        <button className="action_btn">Loading...</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </Modal>

                    </div>
                </main>
            )}
        </>
    );
};

export default OtcHistoryTable;
