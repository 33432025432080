import React, { useEffect } from "react";
import Header from "./Header";
import { stakeOpt } from "../utils/mockData2";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Side_bar from "./Side_bar";
import { getMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link } from "react-router-dom";
import useState from "react-usestateref";
import { toast } from "react-toastify";
import { Bars } from "react-loader-spinner";
import Moment from "moment";

const OtcHistoryTable = () => {
    const [otcData, setOtcData] = useState([]);
    const [siteLoader, setSiteLoader] = useState(false);

    const [otccurrentpage, setOtcCurrentPage] = useState(1);
    const [otctotalpage, setOtcTotalPages] = useState(0);

    // Fetching OTC Data
    const fetchOtcData = async (page) => {
        setSiteLoader(true);

        const response = await getMethod({ apiUrl: apiService.otcGet });

        if (response && Array.isArray(response)) {
            setOtcData(response); // Set the fetched OTC data
            setOtcTotalPages(Math.ceil(response.length / 5)); // Calculate total pages based on the data length
        } else {
            console.error("Invalid data format:", response);
        }

        setSiteLoader(false);
    };

    useEffect(() => {
        fetchOtcData(1); // Fetch OTC data on component mount
    }, []);

    const handlePageOtc = (event, page) => {
        fetchOtcData(page); // Fetch OTC data for the selected page
        setOtcCurrentPage(page);
    };

    return (
        <>
            <section>
                <Header />
            </section>
            {siteLoader ? (
                <div className="loadercss">
                    <Bars
                        height="80"
                        width="80"
                        color="#ffc630"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            ) : (
                <main className="dashboard_main">
                    <div className="container-lg">
                        <div className="row">
                            <div className="col-lg-2">
                                <Side_bar />
                            </div>

                            <div className="col-lg-10">
                                <section className="asset_section">
                                    <div className="row">
                                        <div className="buy_head">
                                            <div className="Buycrypto_title">OTC History</div>
                                            <ul className="history-lists">

                                                <Link to="/loginHistory" className="history-links">
                                                    Login
                                                </Link>
                                                <Link to="/referralHistory" className="history-links">
                                                    Referral
                                                </Link>
                                                <Link
                                                    to="/depositHistory"
                                                    className="history-links"
                                                >
                                                    Deposit
                                                </Link>
                                                <Link to="/withdrawHistory" className="history-links">
                                                    Withdraw
                                                </Link>
                                                <Link
                                                    to="/internaltransferhistory"
                                                    className="history-links"
                                                >
                                                    Internal Transfer
                                                </Link>
                                                <Link to="/swapHistory" className="history-links">
                                                    Convert
                                                </Link>
                                                <Link to="/stakingHistory" className="history-links">
                                                    Staking
                                                </Link>
                                                <Link to="/orderHistory" className="history-links">
                                                    Open Order
                                                </Link>
                                                <Link
                                                    to="/cancelorderHistory"
                                                    className="history-links"
                                                >
                                                    Close Order
                                                </Link>
                                                <Link to="/tradeHistory" className="history-links">
                                                    Trade
                                                </Link>
                                                <Link to="/rewardsHistory" className="history-links">
                                                    Rewards
                                                </Link>
                                                <Link to="/otcTable" className="history-links active">
                                                    OTC History
                                                </Link>
                                                {/* Other links go here */}
                                            </ul>
                                            <div className="table-responsive table-cont dash_table_content">
                                                <table className="table">
                                                    <thead>
                                                        <tr className="stake-head-otcnow">
                                                            <th className="p-l-15">Buy Currency</th>
                                                            <th className="p-l-15">Sell Currency</th>
                                                            <th className="table_center_text opt-nowrap txt-center pad-left-23">Buy Price</th>
                                                            <th className="table_center_text opt-nowrap txt-center pad-left-23">Sell Price</th>
                                                            <th className="table_center_text opt-nowrap txt-center pad-left-23">Otc Status</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {otcData.length > 0 ? (
                                                            otcData.slice((otccurrentpage - 1) * 5, otccurrentpage * 5).map((item) => {
                                                                return (
                                                                    <tr key={item._id}>
                                                                        <td className="table-flex">
                                                                            <img src={item?.buyTokenImage} alt="" />
                                                                            <div className="table-opt-name">
                                                                                <h4 className="opt-name font_14">
                                                                                    {item?.buyToken}
                                                                                </h4>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="table-flex-newone">
                                                                                <img src={item?.sellTokenImage} alt="" />
                                                                                <div className="table-opt-name">
                                                                                    <h4 className="opt-name font_14">
                                                                                        {item?.sellToken}
                                                                                    </h4>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="opt-term font_14 table_center_text pad-left-23">{item.buyPrice}</td>
                                                                        <td className="opt-term font_14 table_center_text pad-left-23 p-0">{item.sellPrice}</td>
                                                                        <td className="opt-term font_14 table_center_text pad-left-23 p-0">{item.otcStatus}</td>
                                                                    </tr>
                                                                );
                                                            })
                                                        ) : (
                                                            <tr>
                                                                <td colSpan={5} className="text-center py-5">
                                                                    <div className="empty_data">
                                                                        <div className="empty_data_img">
                                                                            <img
                                                                                src={require("../assets/No-data.webp")}
                                                                                width="100px"
                                                                            />
                                                                        </div>
                                                                        <div className="no_records_text">No Records Found</div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>

                                                {otcData.length > 0 && (
                                                    <div className="pagination">
                                                        <Stack spacing={2}>
                                                            <Pagination
                                                                count={otctotalpage}
                                                                page={otccurrentpage}
                                                                onChange={handlePageOtc}
                                                                size="small"
                                                                sx={{
                                                                    "& .MuiPaginationItem-root": {
                                                                        color: "#fff",
                                                                    },
                                                                    "& .Mui-selected": {
                                                                        backgroundColor: "#ffc630 !important",
                                                                        color: "#000",
                                                                    },
                                                                    "& .MuiPaginationItem-ellipsis": {
                                                                        color: "#fff",
                                                                    },
                                                                    "& .MuiPaginationItem-icon": {
                                                                        color: "#fff",
                                                                    },
                                                                }}
                                                            />
                                                        </Stack>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </>
    );
};

export default OtcHistoryTable;
