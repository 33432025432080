import React, { useEffect } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";

function FutureCopySetting() {
  const [siteLoader, setSiteLoader] = useState(false);
  const [typeActive, setTypeActive, typeActiveref] = useState("fixedRatio");
  const [isChecked, setIschecked] = useState(false);
  const [isCheckedProfit, setIscheckedProfit] = useState(false);
  const [isCheckedAgree, setIscheckedAgree] = useState(false);

  const navigate = useNavigate();

  const handleType = async (value) => {
    setTypeActive(value);
  };

  const handleBack = async () => {
    navigate("/copytrading");
  };

  const handleTerms = (event) => {
    setIschecked(event.target.checked);
  };
  const handleTermsProfit = (event) => {
    setIscheckedProfit(event.target.checked);
  };
  const handleTermsAgree = (event) => {
    setIscheckedAgree(event.target.checked);
  };

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="">
          <div className="copi_setting_hero">
            <div className="container">
              <div
                className="copi_setting_hero_backman"
                onClick={() => handleBack()}
              >
                <i class="fa-solid fa-arrow-left"></i>
                <span>Back</span>
              </div>
              <div className="future_copy_settings">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="future_copy_settings_card">
                      <div className="future_copy_settings_card_top">
                        <div className="copi_set_topintop">
                          <span className="copi_set_head">
                            {" "}
                            Futures Copy Settings
                          </span>
                          <div className="copi_set_subhead">
                            <span className="copi_set_subhead_span">
                              Profit Sharing{" "}
                              <span className="copi_set_subhead_spaninspan px-2">
                                {" "}
                                30.00%
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="future_copy_settings_card_center">
                        <div className="future_copy_settings_card_centertop">
                          <div className="copie_setting_scnd_maintab">
                            <div
                              className="scnd_cop_inman"
                              onClick={() => handleType("fixedRatio")}
                            >
                              <span
                                className={
                                  typeActiveref.current == "fixedRatio"
                                    ? "scnd_cop_spa_active"
                                    : "scnd_cop_spa"
                                }
                              >
                                Fixed Ratio
                              </span>
                              <div className="scnd_cop_dis">
                                <div
                                  className={
                                    typeActiveref.current == "fixedRatio"
                                      ? "scnd_cop_disinn_active"
                                      : "scnd_cop_disinn"
                                  }
                                ></div>
                              </div>
                            </div>
                            <div
                              className="scnd_cop_inman"
                              onClick={() => handleType("fixedAmount")}
                            >
                              <span
                                className={
                                  typeActiveref.current == "fixedAmount"
                                    ? "scnd_cop_spa_active"
                                    : "scnd_cop_spa"
                                }
                              >
                                Fixed Amount
                              </span>
                              <div className="scnd_cop_dis">
                                <div
                                  className={
                                    typeActiveref.current == "fixedAmount"
                                      ? "scnd_cop_disinn_active"
                                      : "scnd_cop_disinn"
                                  }
                                ></div>
                              </div>
                            </div>
                          </div>
                          <span className="future_copy_settings_card_centerspan">
                            * Orders will be opened in proportion to your
                            available margin balance/lead trader's available
                            margin balance.
                          </span>
                        </div>
                        <div className="cop_set_inn_inner">
                          <div className="cop_set_inn_inner_topp">
                            <span className="copy_amnt_span">Copy Amount</span>
                            <div className="flex_input_posion">
                             <input
                                type="text"
                                className="input-field-copie"
                                placeholder="500-200,00"
                              />
                              <div className="copie_abs">
                                    <span className="cop_usdt_inp">USDT</span>
                                    <span className="cop_max_inp">MAX</span>
                              </div>  
                            </div>  
                            <div className="cop_form_avai">
                                <span className="cop_swet_avail">Available</span>
                                <span className="cop_swet_avail_bal">0.00 USDT</span>
                            </div>  
                          </div>  
                          <div className="cop_set_inn_inner_centch">
                            <div class="checkbox-container-copieset">
                              <input
                                id="custom-checkbox"
                                checked={isChecked}
                                onChange={handleTerms}
                                className="input-field regular_checkbox"
                                type="checkbox"
                                placeholder="Enter the referral ID (optional)"
                              />
                              <label htmlFor="custom-checkbox"></label>
                            </div>
                            <label htmlFor="custom-checkbox" className="terms-check-copieset">
                            Auto Invest
                            </label>
                          </div>  
                          <div className="cop_set_inn_inner_botman">
                              <div className="cop_set_inn_inner_topp">
                              <span className="copy_amnt_span">Total Stop Loss</span>
                              <div className="flex_input_posion">
                             <input
                                type="text"
                                className="input-field-copie"
                                placeholder="0-95"
                              />
                              <div className="copie_abs_scnd">
                                    <span className="cop_usdt_inp">%</span>
                                    <span className="cop_usdt_inp">ROI</span>
                              </div>  
                            </div>  
                            <span className="future_copy_settings_card_centerspan">
                            When the estimated margin balance of copiers reaches 50.00 USDT, it will trigger a Stop Loss Market order to close all positions. Estimated PnL will be
                           <span style={{color:"#f45e71"}}> -50.00</span><span style={{color:"#fff"}}> USDT</span></span>
                              </div>  
                              <div className="cop_set_inn_inner_botbottom">
                                  <span className="adv_set_opt">Advanced Settings (Optional)</span>

                                  <div className="cop_set_inn_inner_centch">
                          <div class="checkbox-container-copieset">
                            <input
                              id="custom-checkbox-second"
                              checked={isCheckedProfit}
                              onChange={handleTermsProfit}
                              className="input-field regular_checkbox"
                              type="checkbox"
                              placeholder="Enter the referral ID (optional)"
                            />
                            <label htmlFor="custom-checkbox-second"></label>
                          </div>
                          <label htmlFor="custom-checkbox-second" className="terms-check-copieset">
                          I have confirmed Profit Sharing is <span style={{color:"#ffc630"}}> 30.00%</span>
                          </label>
                          </div>  

                          <div className="cop_set_inn_inner_centch">
                          <div class="checkbox-container-copieset">
                            <input
                              id="custom-checkbox-third"
                              checked={isCheckedAgree}
                              onChange={handleTermsAgree}
                              className="input-field regular_checkbox"
                              type="checkbox"
                              placeholder="Enter the referral ID (optional)"
                            />
                            <label htmlFor="custom-checkbox-third"></label>
                          </div>
                          <label htmlFor="custom-checkbox-third" className="terms-check-copieset">
                          I have Read and I agree to the <Link to=""> User Service Agreement </Link> 
                          </label>
                          </div>  
                              </div>  
                          </div>  
                        </div>
                      </div>
                      <button className="copie_seting_copie">
                      Copy
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="copie_sett_right_man">
                        <div className="copie_sett_right_man_top">
                        <div className="copie_cardin_frst_left">
                                <img src={require("../assets/copie-inimg.webp")} alt="Profile" />
                                <div className="copie_cardin_frst_cen">
                                    <span>KNOTMAIN</span>
                                    <div className="cop_inus_dis_man">
                                        <div className="cop_inus_dis">
                                         <i class="fa-solid fa-user-group"></i>
                                         <span> 999 / 1000 </span>
                                         </div>
                                         <div className="cop_inus_dis">
                                         <span>API</span>
                                         </div>   
                                    </div>    
                                </div>
                             </div>  
                             <span className="cop_setrigh_inbig">
                             Fixed ratio only. Target APR 400% / MDD 30%. Never copy less than $5,000, you must invest at least $5,000 to generate a profit.
                              </span> 
                        </div>  
                        <div className="copie_sett_right_man_bot">
                        <div className="copie_cardin_scnd_left">
                                <span className="copie_cardin_scnd_left_top">7 Days PnL</span>
                                <span className="copie_cardin_scnd_left_center">+36,337.99</span>
                                <span className="copie_cardin_scnd_left_top">
                                    ROI <span>+5.44%</span>
                                </span>
                            </div>    
                            <div className="copie_sett_right_pic">
                                <img src={require("../assets/copie-card-chart.webp")} alt="chart" />
                            </div>  
                        </div>  
                    </div>  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FutureCopySetting;
