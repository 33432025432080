import Header from "./Header";
import "./ForexTrade.css";
import useState from "react-usestateref";
import { frWatchList, orderTypes } from "./optionaltradedata";
import { Dropdown } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";

const ForexTrading = () => {
  const [frWatch, setFrWatch] = useState("watchlist");
  const [frSymbol, setFrSymbol] = useState("symbol");
  const [activeTab, setActiveTab, activeTabref] = useState("position");

  const handleTabChange = (data) => {
    setFrWatch(data);
  };

  const handleTabRightChange = (data) => {
    setFrSymbol(data);
  };

  const optionsOpen = [
    { text: "EURUSD", value: "EURUSD" },
    { text: "GBPUSD", value: "GBPUSD" },
    { text: "XTIUSD", value: "XTIUSD" },
  ];

  const [orderType, setOrderType] = useState("Market");
  const handleOrderClick = (data) => {
    setOrderType(data);
  };

  const [orderBtn, setOrderBtn] = useState("sell");
  const handleOrderBtn = (data) => {
    setOrderBtn(data);
  };

  const [newOrder, setNewOrder] = useState(true);
  const handleNewOrder = () => {
    setNewOrder(!newOrder);
  };

  const [autoChart, setAutoChart] = useState(false);
  const handleAutoChart = () => {
    setAutoChart(!autoChart);
  };

  const [tradeCentral, setTradeCentral] = useState(false);
  const handleTradeCentral = () => {
    setTradeCentral(!tradeCentral);
  };

  const [calender, setCalender] = useState(false);
  const handleCalender = () => {
    setCalender(!calender);
  };

  const [marketHour, setMarketHour] = useState(false);
  const handleMarketHour = () => {
    setMarketHour(!marketHour);
  };

  const [leverage, setLeverage] = useState(false);
  const handleLeverage = () => {
    setLeverage(!leverage);
  };

  const [depth, setDepth] = useState(false);
  const handleDepth = () => {
    setDepth(!depth);
  };

  const [symbol, setSymbol] = useState(false);
  const handleSymbol = () => {
    setSymbol(!symbol);
  };

  const [trade, setTrade] = useState(false);
  const handleTrade = () => {
    setTrade(!trade);
  };

  const [inverted, setInverted] = useState(false);
  const handleInverted = () => {
    setInverted(!inverted);
  };

  const handleTabs = async (value) => {
    setActiveTab(value);
  };

  const [startDate, setStartDate] = useState(new Date());

  const navigate = useNavigate();

  return (
    <>
      <Header />

      <>
        <div className="new-popup-height forex-main">
          <div className="container p-0">
            <div className="row mx-0">
              <div className="col-lg-3 forex_trade-left p-0">
                <div className="fr-lft-tabs">
                  <div
                    className={`fr-lft-tab-title ${
                      frWatch === "watchlist" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("watchlist")}
                  >
                    Watchlist
                  </div>

                  <div
                    className={`fr-lft-tab-title ${
                      frWatch === "symbols" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("symbols")}
                  >
                    All symbols
                  </div>
                </div>
                <div className="fr-lft-data">
                  <table className="table m-0">
                    <thead className="fr-lft-thead">
                      <tr className="fr-lft-head-tr">
                        <th>Symbols</th>
                        <th className="text-center">Bid</th>
                        <th className="text-center fr-pr-0">Ask</th>
                        <th className="fr-txt-right">D. Chg.</th>
                      </tr>
                    </thead>
                    <tbody className="fr-lft-body">
                      {frWatchList.map((item) => {
                        return (
                          <tr key={item.id} className="fr-lft-body-tr">
                            <td>
                              <span
                                className={`m-0 icon-container ${
                                  item.tradeSignal
                                    ? "tradeGreen green-arr-rotate"
                                    : "tradeRed red-arr-rotate"
                                }`}
                              >
                                <i
                                  className={`${
                                    item.tradeSignal
                                      ? item.redIcon
                                      : item.greenIcon
                                  }`}
                                ></i>
                              </span>
                              <span className="fr-lft-sym">{item.symbols}</span>
                            </td>
                            <td
                              className={`text-center fr-lft-data-td ${
                                item.tradeSignal ? "green" : "red"
                              }`}
                            >
                              {item.bid}
                            </td>
                            <td
                              className={`text-center fr-lft-data-td ${
                                item.tradeSignal ? "green" : "red"
                              }`}
                            >
                              {item.ask}
                            </td>
                            <td
                              className={`fr-pr fr-txt-right fr-lft-data-td ${
                                item.tradeSignal ? "green" : "red"
                              }`}
                            >
                              {item.chg}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="fr-add-wrap">
                    <span className="fr-add-wrap-plus">
                      <i class="fa-solid fa-plus"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Click to add..."
                      className="fr-add-wrap-input"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 forex_trade-center p-0">
                <img
                  src={require("../assets/forex-trade.webp")}
                  alt=""
                  className="w-100 h-100"
                />
              </div>
              <div className="col-lg-3 forex_trade-right p-0">
                <div className="fr-lft-tabs">
                  <div
                    className={`fr-lft-tab-title ${
                      frSymbol === "symbol" ? "active" : ""
                    }`}
                    onClick={() => handleTabRightChange("symbol")}
                  >
                    Symbol
                  </div>
                  <div
                    className={`fr-lft-tab-title ${
                      frSymbol === "dom" ? "active" : ""
                    }`}
                    onClick={() => handleTabRightChange("dom")}
                  >
                    DoM
                  </div>
                  <div
                    className={`fr-lft-tab-title ${
                      frSymbol === "news" ? "active" : ""
                    }`}
                    onClick={() => handleTabRightChange("news")}
                  >
                    News
                  </div>
                  <div
                    className={`fr-lft-tab-title ${
                      frSymbol === "calender" ? "active" : ""
                    }`}
                    onClick={() => handleTabRightChange("calender")}
                  >
                    Calender
                  </div>
                </div>

                <div className="fr-right-contents">
                  <Dropdown
                    inline
                    placeholder="EURUSD"
                    options={optionsOpen}
                    className="fr-rgt-dropdown "
                  />

                  {/* New Order */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        newOrder && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleNewOrder}
                    >
                      <span className="fr-rgt-nw-title">New order</span>
                      <span className="fr-rgt-nw-arr">
                        {newOrder ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {newOrder && (
                      <div className="fr-rgt-nw-body">
                        <div className="fr-rgt-nw-body-top">
                          <div className="fr-rgt-nw-body-top-inner">
                            <span>
                              <i className="fa-solid fa-arrow-up-long"></i>
                            </span>
                            <h5>Euro vs Dollar</h5>
                          </div>
                          <span className="fr-rgt-nw-span">+61.7 (+0.59%)</span>
                        </div>

                        <div className="fr-rgt-nw-body-ctr">
                          <div className="fr-rgt-order-tabs-flex">
                            {orderTypes.map((data) => {
                              return (
                                <div
                                  key={data.id}
                                  className={`fr-rgt-order-tabs ${
                                    orderType === data.order ? "active" : ""
                                  }`}
                                  onClick={() => handleOrderClick(data.order)}
                                >
                                  {data.order}
                                </div>
                              );
                            })}
                          </div>

                          <div className="fr-rgt-order-btns-wrap">
                            <button
                              className={`fr-rgt-order-btn sell ${
                                orderBtn === "sell" ? "active" : ""
                              }`}
                              onClick={() => handleOrderBtn("sell")}
                            >
                              Sell
                              <span>1.04762</span>
                            </button>
                            <button
                              className={`fr-rgt-order-btn buy ${
                                orderBtn === "buy" ? "active" : ""
                              }`}
                              onClick={() => handleOrderBtn("buy")}
                            >
                              Buy
                              <span>1.04762</span>
                            </button>
                          </div>

                          {/* market */}
                          {orderType === "Market" && (
                            <>
                              <div className="fr-rgt-loader-outer">
                                <div className="fr-rgt-loader left"></div>
                                <div className="fr-rgt-loader right"></div>
                              </div>

                              <div className="fr-rgt-span-wrapper">
                                <span>Spread:</span>
                                <span>0.00; </span>
                                <span> High</span>
                                <span>1.04938; </span>
                                <span> Low</span>
                                <span>1.04115</span>
                              </div>

                              <div className="fr-rgt-quan-wrap">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <span>Quantity</span>
                                    <span>Lots</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="5.0"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Est. Buy margin : </span>
                                    <span>€ 1 000.00</span>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="market-range"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="market-range"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="market-range"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Market Range
                                      </label>
                                    </div>
                                    <span>Pips</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="5.0"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Pip value : </span>
                                    <span>€ 9.53</span>
                                  </div>
                                </div>
                              </div>

                              <div className="fr-rgt-quan-input-wrap my-2">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="st-ls"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="st-ls"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="st-ls"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Stop loss
                                      </label>
                                    </div>
                                  </div>
                                  <div className="fr-rgt-quan-main ">
                                    <input
                                      type="number"
                                      placeholder="5.0"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>

                                <div className="fr-cn-txt cn-mt">Pips</div>

                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="tk-profit"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="tk-profit"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="tk-profit"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Take Profit
                                      </label>
                                    </div>
                                  </div>
                                  <div className="fr-rgt-quan-main ">
                                    <input
                                      type="number"
                                      placeholder="5.0"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="fr-rgt-quan-input-wrap mb-2">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-main m-0">
                                    <input
                                      type="number"
                                      placeholder="1.04732"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>

                                <span className="fr-cn-txt">Est. Price</span>

                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-main m-0">
                                    <input
                                      type="number"
                                      placeholder="1.04732"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="fr-rgt-quan-input-wrap mb-2">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-main m-0">
                                    <input
                                      type="number"
                                      placeholder="-14.30%"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>

                                <span className="fr-cn-txt">Est. Balance</span>

                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-main m-0">
                                    <input
                                      type="number"
                                      placeholder="-14.30%"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="fr-rgt-quan-input-wrap mb-2">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-main m-0">
                                    <input
                                      type="number"
                                      placeholder="€ -143.01"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>

                                <span className="fr-cn-txt">Est. Profit</span>

                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-main m-0">
                                    <input
                                      type="number"
                                      placeholder="€ -143.01"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="fr-rgt-quan-top">
                                <div className="d-flex align-items-center ">
                                  <div className="op-fav-check-wrap fr-tc-check">
                                    <input
                                      type="checkbox"
                                      id="tr-stop"
                                      className="op__check"
                                    />
                                    <label
                                      htmlFor="tr-stop"
                                      className="fr-check-bg"
                                    ></label>
                                  </div>
                                  <label
                                    htmlFor="tr-stop"
                                    className="op-st-label fr-rgt-check-txt"
                                  >
                                    Trailing Stop
                                  </label>
                                </div>
                              </div>
                              <button className="fr-rgt-nw-btn">
                                Place Order
                              </button>
                            </>
                          )}

                          {/* limit */}
                          {orderType === "Limit" && (
                            <>
                              <div className="fr-rgt-quan-wrap">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <span>Entry price</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="1.04868"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Current distance : </span>
                                    <span>-0.5</span>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="mr-rg"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="mr-rg"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="mr-rg"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Market Range
                                      </label>
                                    </div>
                                    <span>Lots</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="1.00"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Est. Buy margin : </span>
                                    <span>€ 1000.00</span>
                                  </div>
                                </div>
                              </div>

                              <div className="fr-rgt-quan-wrap">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="expiry"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="expiry"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="expiry"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Expiry
                                      </label>
                                    </div>
                                  </div>
                                  <div className="fr-rgt-quan-main fr-rgt-cal-wrapper">
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      className="fr-rgt-calender"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Duration : </span>
                                    <span>Good Till Cancelled</span>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <span>UTC+0</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="12:41"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="fr-rgt-quan-wrap mt-2">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="limit-st-ls"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="limit-st-ls"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="limit-st-ls"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Stop loss
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="limit-tk-profit"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="limit-tk-profit"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="limit-tk-profit"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Take Profit
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button className="fr-rgt-nw-btn">
                                Place Order
                              </button>

                              <div className="fr-rgt-span-wrapper mt-2">
                                <span>Spread:</span>
                                <span>0.00; </span>
                                <span> High</span>
                                <span>1.04938; </span>
                                <span> Low</span>
                                <span>1.04115</span>
                              </div>
                            </>
                          )}

                          {/* stop */}
                          {orderType === "Stop" && (
                            <>
                              <div className="fr-rgt-quan-wrap">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <span>Entry price</span>
                                  </div>
                                  <div className="fr-rgt-quan-main fr-rgt-stop-flex">
                                    <input
                                      type="number"
                                      placeholder="1.04868"
                                      className="fr-rgt-input"
                                    />
                                    <span className="fr-st-arr">
                                      <i className="fa-solid fa-arrow-right"></i>
                                    </span>
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Current distance : </span>
                                    <span>-0.5</span>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="mr-rg"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="mr-rg"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="mr-rg"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Market Range
                                      </label>
                                    </div>
                                    <span>Lots</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="1.00"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Est. Buy margin : </span>
                                    <span>€ 1000.00</span>
                                  </div>
                                </div>
                              </div>

                              <div className="fr-rgt-quan-wrap">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="expiry"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="expiry"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="expiry"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Expiry
                                      </label>
                                    </div>
                                  </div>
                                  <div className="fr-rgt-quan-main fr-rgt-cal-wrapper">
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      className="fr-rgt-calender"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Duration : </span>
                                    <span>Good Till Cancelled</span>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <span>UTC+0</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="12:41"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="fr-rgt-quan-wrap mt-2">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="limit-st-ls"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="limit-st-ls"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="limit-st-ls"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Stop loss
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="limit-tk-profit"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="limit-tk-profit"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="limit-tk-profit"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Take Profit
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button className="fr-rgt-nw-btn">
                                Place Order
                              </button>

                              <div className="fr-rgt-span-wrapper mt-2">
                                <span>Spread:</span>
                                <span>0.00; </span>
                                <span> High</span>
                                <span>1.04938; </span>
                                <span> Low</span>
                                <span>1.04115</span>
                              </div>
                            </>
                          )}

                          {/* stop limit */}
                          {orderType === "Stop-Limit" && (
                            <>
                              <div className="fr-rgt-quan-wrap">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <span>Entry price</span>
                                  </div>
                                  <div className="fr-rgt-quan-main fr-rgt-stop-flex">
                                    <input
                                      type="number"
                                      placeholder="1.04868"
                                      className="fr-rgt-input"
                                    />
                                    <span className="fr-st-arr">
                                      <i className="fa-solid fa-arrow-right"></i>
                                    </span>
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Current distance : </span>
                                    <span>-0.5</span>
                                  </div>
                                </div>

                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="mr-rg"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="mr-rg"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="mr-rg"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Market Range
                                      </label>
                                    </div>
                                    <span>Lots</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="1.00"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Est. Buy margin : </span>
                                    <span>€ 1000.00</span>
                                  </div>
                                </div>
                              </div>

                              <div className="fr-rgt-quan-wrap">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <span>Limit range</span>
                                    <span>Pips</span>
                                  </div>
                                  <div className="fr-rgt-quan-main">
                                    <input
                                      type="number"
                                      placeholder="5.0"
                                      className="fr-rgt-input"
                                    />
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="st-lm-expiry"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="st-lm-expiry"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="st-lm-expiry"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Expiry
                                      </label>
                                    </div>
                                    <span>UTC+0</span>
                                  </div>
                                  <div className="fr-rgt-quan-main fr-rgt-cal-wrapper d-flex gap-1 fr-st-lm-flex-lg">
                                    <DatePicker
                                      selected={startDate}
                                      onChange={(date) => setStartDate(date)}
                                      className="fr-rgt-calender"
                                    />
                                    <input
                                      type="number"
                                      placeholder="12:41"
                                      className="fr-st-lm-flex-rt fr-rgt-input"
                                      // className="fr-rgt-input"
                                    />
                                  </div>
                                  <div className="fr-rgt-quan-bottom mt-1">
                                    <span>Duration : </span>
                                    <span>Good Till Cancelled</span>
                                  </div>
                                </div>
                              </div>

                              <div className="fr-rgt-quan-wrap mt-2">
                                <div className="fr-rgt-quan left">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="limit-st-ls"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="limit-st-ls"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="limit-st-ls"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Stop loss
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                <div className="fr-rgt-quan rgt">
                                  <div className="fr-rgt-quan-top">
                                    <div className="d-flex align-items-center ">
                                      <div className="op-fav-check-wrap fr-tc-check">
                                        <input
                                          type="checkbox"
                                          id="limit-tk-profit"
                                          className="op__check"
                                        />
                                        <label
                                          htmlFor="limit-tk-profit"
                                          className="fr-check-bg"
                                        ></label>
                                      </div>
                                      <label
                                        htmlFor="limit-tk-profit"
                                        className="op-st-label fr-rgt-check-txt"
                                      >
                                        Take Profit
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <button className="fr-rgt-nw-btn">
                                Place Order
                              </button>

                              <div className="fr-rgt-span-wrapper mt-2">
                                <span>Spread:</span>
                                <span>0.00; </span>
                                <span> High</span>
                                <span>1.04938; </span>
                                <span> Low</span>
                                <span>1.04115</span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Autochartist */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        autoChart && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleAutoChart}
                    >
                      <span className="fr-rgt-nw-title">Autochartist</span>
                      <span className="fr-rgt-nw-arr">
                        {autoChart ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {autoChart && (
                      <div className="fr-rgt-auto-main">
                        <div className="fr-rgt-at-top">
                          <table className="table">
                            <thead className="fr-rgt-at-thead">
                              <tr>
                                <th className="fr-rgt-at-tp-span">Interval</th>
                                <th className="fr-rgt-at-tp-span">Direction</th>
                                <th className="fr-rgt-at-tp-span">Pattern</th>
                              </tr>
                            </thead>
                            <tbody className="fr-fgt-at-tbody">
                              <tr>
                                <td className="fr-rgt-at-cnt">h4</td>
                                <td className="fr-rgt-at-cnt fr-rgt-at-flx">
                                  <span className="fr-arr-grn">
                                    <i className="fa-solid fa-sort-up"></i>
                                  </span>
                                  <span>up</span>
                                </td>
                                <td className="fr-rgt-at-cnt .fr-rgt-at-flx">
                                  <span>
                                    <img
                                      src={require("../assets/line.webp")}
                                      alt=""
                                    />
                                  </span>
                                  <span className="mx-2">Channel Up</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="fr-rgt-at-cnt">h4</td>
                                <td className="fr-rgt-at-cnt fr-rgt-at-flx">
                                  <span className="fr-arr-grn">
                                    <i className="fa-solid fa-sort-up"></i>
                                  </span>
                                  <span>up</span>
                                </td>
                                <td className="fr-rgt-at-cnt .fr-rgt-at-flx">
                                  <span>
                                    <img
                                      src={require("../assets/line.webp")}
                                      alt=""
                                    />
                                  </span>
                                  <span className="mx-2">Channel Up</span>
                                </td>
                              </tr>
                              <tr>
                                <td className="fr-rgt-at-cnt">h4</td>
                                <td className="fr-rgt-at-cnt fr-rgt-at-flx">
                                  <span className="fr-arr-grn">
                                    <i className="fa-solid fa-sort-up"></i>
                                  </span>
                                  <span>up</span>
                                </td>
                                <td className="fr-rgt-at-cnt .fr-rgt-at-flx">
                                  <span>
                                    <img
                                      src={require("../assets/line.webp")}
                                      alt=""
                                    />
                                  </span>
                                  <span className="mx-2">Channel Up</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <button className="fr-rgt-nw-btn">Show More</button>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Trading central */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        tradeCentral && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleTradeCentral}
                    >
                      <span className="fr-rgt-nw-title">Trading Central</span>
                      <span className="fr-rgt-nw-arr">
                        {tradeCentral ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {tradeCentral && (
                      <div className="fr-rgt-tc-body">
                        <div className="fr-tc-top">
                          <div className="fr-tc-top-flex">
                            <h6>Intraday</h6>
                            <div className="fr-tc-top-sq"></div>
                          </div>
                          <div className="fr-tc-top-rgt">24/01/2025 05:39</div>
                        </div>
                        <div className="fr-tc-main mt-3">
                          <div className="fr-tc-tr-wrap">
                            <span>
                              Target - <span>1.05</span>{" "}
                            </span>
                            <span className="fr-tc-tr-blr">
                              Pips: <span>12.5</span>{" "}
                            </span>
                            <span> € 119.19</span>
                          </div>
                          <p className="fr-tc-content mt-3">
                            EUR/USD Intraday: further advance.
                          </p>
                          <p className="fr-tc-content">
                            Long positions above 1.0410 with targets at 1.0475 &
                            1.0500 in extension.
                          </p>
                          <div className="fr-rgt-quan-wrap gap-4">
                            <div className="fr-rgt-quan left">
                              <div className="fr-rgt-quan-top">
                                <span>Quantity</span>
                                <span>Lots</span>
                              </div>
                              <div className="fr-rgt-quan-main">
                                <input
                                  type="number"
                                  placeholder="1.00"
                                  className="fr-rgt-input"
                                />
                              </div>
                            </div>

                            <div className="fr-rgt-quan rgt">
                              <button
                                className={`fr-rgt-order-btn fr-rgt-tr-btn `}
                              >
                                Buy
                                <span>1.04762</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="fr-tc-bottom">
                          <div className="d-flex align-items-center mt-3 fr-tc-check-opacity">
                            <div className="op-fav-check-wrap fr-tc-check ">
                              <input
                                type="checkbox"
                                id="take-profit"
                                className="op__check"
                              />
                              <label htmlFor="take-profit"></label>
                            </div>
                            <label
                              htmlFor="take-profit"
                              className="op-st-label"
                            >
                              Take profit at 1.05 (12.5 pips)
                            </label>
                          </div>

                          <div className="d-flex align-items-center mt-3">
                            <div className="op-fav-check-wrap fr-tc-check">
                              <input
                                type="checkbox"
                                id="stop-loss"
                                className="op__check"
                              />
                              <label htmlFor="stop-loss"></label>
                            </div>
                            <label htmlFor="stop-loss" className="op-st-label">
                              Stop loss at 1.041 (-77.5 pips)
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Calender */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        calender && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleCalender}
                    >
                      <span className="fr-rgt-nw-title">Calender</span>
                      <span className="fr-rgt-nw-arr">
                        {calender ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {calender && (
                      <div className="fr-rgt-tc-body">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div className="d-flex gap-2">
                            <div>
                              <span className="fr-cl-clk">
                                <i className="fa-regular fa-clock"></i>
                              </span>
                              <span className="mx-2 fr-cl-clk-txt">
                                10:00 (UTC+0)
                              </span>
                            </div>
                            <div>
                              <span className="fr-cl-time">Time: </span>
                              <span className="fr-cl-clk-txt">53m ago</span>
                            </div>
                          </div>
                          <div>
                            <img src={require("../assets/eur.webp")} alt="" />
                            <span className="mx-2 fr-cl-clk-txt">EUR</span>
                          </div>
                        </div>
                        <h6 className="fr-rgt-cl-title">
                          ECB’s President Lagarde speech
                        </h6>
                        <div className="fr-rgt-imp-outer">
                          <div className="fr-rgt-imp-wrap fr-rgt-imp-br">
                            <img
                              src={require("../assets/impact.webp")}
                              alt=""
                            />
                            <div className="fr-rgt-im-title">Impact</div>
                          </div>
                          <div className="fr-rgt-imp-wrap fr-rgt-imp-br">
                            <span className="text-white">-</span>
                            <div className="fr-rgt-im-title">Actual</div>
                          </div>
                          <div className="fr-rgt-imp-wrap fr-rgt-imp-br">
                            <span className="text-white">-</span>
                            <div className="fr-rgt-im-title">Consensus</div>
                          </div>
                          <div className="fr-rgt-imp-wrap">
                            <span className="text-white">-</span>
                            <div className="fr-rgt-im-title">Previous</div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between flex-wrap mt-3">
                          <div className="d-flex gap-2">
                            <div>
                              <span className="fr-cl-clk">
                                <i className="fa-regular fa-clock"></i>
                              </span>
                              <span className="mx-2 fr-cl-clk-txt">
                                10:00 (UTC+0)
                              </span>
                            </div>
                            <div>
                              <span className="fr-cl-time">Time: </span>
                              <span className="fr-cl-clk-txt">53m ago</span>
                            </div>
                          </div>
                          <div>
                            <img src={require("../assets/eur.webp")} alt="" />
                            <span className="mx-2 fr-cl-clk-txt">EUR</span>
                          </div>
                        </div>
                        <h6 className="fr-rgt-cl-title">
                          ECB’s President Lagarde speech
                        </h6>
                        <div className="fr-rgt-imp-outer">
                          <div className="fr-rgt-imp-wrap fr-rgt-imp-br">
                            <img
                              src={require("../assets/impact.webp")}
                              alt=""
                            />
                            <div className="fr-rgt-im-title">Impact</div>
                          </div>
                          <div className="fr-rgt-imp-wrap fr-rgt-imp-br">
                            <span className="text-white">-</span>
                            <div className="fr-rgt-im-title">Actual</div>
                          </div>
                          <div className="fr-rgt-imp-wrap fr-rgt-imp-br">
                            <span className="text-white">-</span>
                            <div className="fr-rgt-im-title">Consensus</div>
                          </div>
                          <div className="fr-rgt-imp-wrap">
                            <span className="text-white">-</span>
                            <div className="fr-rgt-im-title">Previous</div>
                          </div>
                        </div>

                        <button className="fr-rgt-nw-btn">Show More</button>
                      </div>
                    )}
                  </div>

                  {/* Market hours */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        marketHour && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleMarketHour}
                    >
                      <span className="fr-rgt-nw-title">Market hours</span>
                      <span className="fr-rgt-nw-arr">
                        {marketHour ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {marketHour && (
                      <div className="fr-rgt-mr-outer">
                        <div className="fr-rgt-tc-body ">
                          <div className="fr-rgt-mr-wrap fr-tc-check-opacity">
                            <div className="fr-rgt-mr-content">Sun</div>
                            <div className="fr-rgt-mr-content">
                              22:00:05 - Mon
                            </div>
                            <div className="fr-rgt-mr-content">21:29:55</div>
                          </div>
                          <div className="fr-rgt-mr-wrap fr-tc-check-opacity">
                            <div className="fr-rgt-mr-content">Mon</div>
                            <div className="fr-rgt-mr-content">
                              22:00:05 - Mon
                            </div>
                            <div className="fr-rgt-mr-content">21:29:55</div>
                          </div>
                          <div className="fr-rgt-mr-wrap fr-tc-check-opacity">
                            <div className="fr-rgt-mr-content">Tue</div>
                            <div className="fr-rgt-mr-content">
                              22:00:05 - Tue
                            </div>
                            <div className="fr-rgt-mr-content">21:29:55</div>
                          </div>
                          <div className="fr-rgt-mr-wrap fr-tc-check-opacity">
                            <div className="fr-rgt-mr-content">Wed</div>
                            <div className="fr-rgt-mr-content">
                              22:00:05 - Wed
                            </div>
                            <div className="fr-rgt-mr-content">21:29:55</div>
                          </div>
                          <div className="fr-rgt-mr-wrap">
                            <div className="fr-rgt-mr-content">Thu</div>
                            <div className="fr-rgt-mr-content">
                              22:00:05 - Thu
                            </div>
                            <div className="fr-rgt-mr-content">
                              21:29:55 (Closed in 10:20:24)
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* leverage */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        leverage && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleLeverage}
                    >
                      <span className="fr-rgt-nw-title">Leverage</span>
                      <span className="fr-rgt-nw-arr">
                        {leverage ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {leverage && (
                      <div className="fr-rgt-tc-body">
                        <div className="fr-rgt-lv-wrap">
                          <div className="fr-lv-title">Volume</div>
                          <div className="fr-lv-title">Leverage</div>
                        </div>
                        <div className="fr-rgt-lv-wrap">
                          <div className="fr-lv-content">≤1m</div>
                          <div className="fr-lv-content">1:500</div>
                        </div>
                        <div className="fr-rgt-lv-wrap">
                          <div className="fr-lv-content">≤5m</div>
                          <div className="fr-lv-content">1:200</div>
                        </div>
                        <div className="fr-rgt-lv-wrap">
                          <div className="fr-lv-content">≤5m</div>
                          <div className="fr-lv-content">1:100</div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* depth */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        depth && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleDepth}
                    >
                      <span className="fr-rgt-nw-title">
                        Depth of market (standard)
                      </span>
                      <span className="fr-rgt-nw-arr">
                        {depth ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {depth && (
                      <div className="fr-rgt-tc-body">
                        <div className="fr-rgt-dep-wrap">
                          <div className="fr-rgt-dep-inner pl">
                            <span className="fr-rgt-dep-span">1.00</span>
                            <span className="fr-rgt-dep-span red-l">
                              1.04887
                            </span>
                          </div>
                          <div className="fr-rgt-dep-inner pr">
                            <span className="fr-rgt-dep-span green-l">
                              1.04887
                            </span>
                            <span className="fr-rgt-dep-span">1.00</span>
                          </div>
                        </div>
                        <div className="fr-rgt-dep-wrap mt-3">
                          <div className="fr-rgt-dep-inner pl">
                            <span className="fr-rgt-dep-span">15.00</span>
                            <span className="fr-rgt-dep-span red-bg-one">
                              1.04885
                            </span>
                          </div>
                          <div className="fr-rgt-dep-inner pr">
                            <span className="fr-rgt-dep-span green-bg-one">
                              1.04885
                            </span>
                            <span className="fr-rgt-dep-span">15.00</span>
                          </div>
                        </div>
                        <div className="fr-rgt-dep-wrap mt-3">
                          <div className="fr-rgt-dep-inner pl">
                            <span className="fr-rgt-dep-span">30.00</span>
                            <span className="fr-rgt-dep-span red-bg-two">
                              1.04882
                            </span>
                          </div>
                          <div className="fr-rgt-dep-inner pr">
                            <span className="fr-rgt-dep-span green-bg-two">
                              1.04891
                            </span>
                            <span className="fr-rgt-dep-span">30.00</span>
                          </div>
                        </div>
                        <div className="fr-rgt-dep-wrap mt-3">
                          <div className="fr-rgt-dep-inner red-bg-three">
                            <span className="fr-rgt-dep-span">50.00</span>
                            <span className="fr-rgt-dep-span red-l">
                              1.04880
                            </span>
                          </div>
                          <div className="fr-rgt-dep-inner green-bg-three ">
                            <span className="fr-rgt-dep-span green-l">
                              1.04893
                            </span>
                            <span className="fr-rgt-dep-span">50.00</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* symbol */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        symbol && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleSymbol}
                    >
                      <span className="fr-rgt-nw-title">Symbol info</span>
                      <span className="fr-rgt-nw-arr">
                        {symbol ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {symbol && (
                      <div className="fr-rgt-tc-body">
                        <div className="fr-rgt-sy-wrap pt-0">
                          <div className="fr-rgt-sy-content">Base Asset</div>
                          <div className="fr-rgt-sy-content">EUR</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Quote Asset</div>
                          <div className="fr-rgt-sy-content">USD</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Min Change</div>
                          <div className="fr-rgt-sy-content">0.00001</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Pip Position</div>
                          <div className="fr-rgt-sy-content">4</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Lot Size</div>
                          <div className="fr-rgt-sy-content">€100,000</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Commission (per min USD volume)
                          </div>
                          <div className="fr-rgt-sy-content">$45.00</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            P&L Conversion Fee Rate
                          </div>
                          <div className="fr-rgt-sy-content">0.00%</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Min Trade Quantity
                          </div>
                          <div className="fr-rgt-sy-content">0.01 Lots</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Max Trade Quantity
                          </div>
                          <div className="fr-rgt-sy-content">100.00 Lots</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Min Stop-Loss Distance
                          </div>
                          <div className="fr-rgt-sy-content">0.0 pips</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Min Take-Profit Distance
                          </div>
                          <div className="fr-rgt-sy-content">0.0 pips</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Swap (Long)</div>
                          <div className="fr-rgt-sy-content">-2.445 pips</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Swap (Short)</div>
                          <div className="fr-rgt-sy-content">-0.105 pips</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">3-Day Swaps</div>
                          <div className="fr-rgt-sy-content">Wednesday</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Weekend Swaps</div>
                          <div className="fr-rgt-sy-content">Disabled</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Swap Time</div>
                          <div className="fr-rgt-sy-content">20:59</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Swap Period, Hours
                          </div>
                          <div className="fr-rgt-sy-content">24</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Grace Periods</div>
                          <div className="fr-rgt-sy-content">0</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Buy Positions</div>
                          <div className="fr-rgt-sy-content">0 (0.00 Lots)</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Sell Positions
                          </div>
                          <div className="fr-rgt-sy-content">0 (0.00 Lots)</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">FX Symbol ID</div>
                          <div className="fr-rgt-sy-content">1</div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Trade statistics*/}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        trade && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleTrade}
                    >
                      <span className="fr-rgt-nw-title">Trade statistics</span>
                      <span className="fr-rgt-nw-arr">
                        {trade ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {trade && (
                      <div className="fr-rgt-tc-body">
                        <div className="fr-rgt-sy-wrap pt-0">
                          <div className="fr-rgt-sy-content">Net profit</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Profit factor</div>
                          <div className="fr-rgt-sy-content">- </div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Commission</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Max balance drawdown
                          </div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Total trades</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Winning trades
                          </div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Largest winning trade
                          </div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Losing trades</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Largest losing trade
                          </div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Average trade</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Total pips</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Average pips</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">
                            Average trade duration
                          </div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                        <div className="fr-rgt-sy-wrap">
                          <div className="fr-rgt-sy-content">Traded volume</div>
                          <div className="fr-rgt-sy-content">-</div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* inverted rate */}
                  <div className="fr-rgt-nw-order-wrap">
                    <div
                      className={`fr-rgt-nw-head ${
                        inverted && "fr-fgt-nw-head-bb"
                      }`}
                      onClick={handleInverted}
                    >
                      <span className="fr-rgt-nw-title">Inverted rate</span>
                      <span className="fr-rgt-nw-arr">
                        {inverted ? (
                          <i className="fa-solid fa-chevron-up"></i>
                        ) : (
                          <i className="fa-solid fa-chevron-down"></i>
                        )}
                      </span>
                    </div>

                    {inverted && (
                      <div className="fr-rgt-tc-body">
                        <table className="table">
                          <thead className="fr-rgt-inv-thead">
                            <tr>
                              <th></th>
                              <th className="text-center">Bid</th>
                              <th className="text-center">Ask</th>
                            </tr>
                          </thead>
                          <tbody className="fr-rgt-inv-tbody">
                            <tr>
                              <td className="fr-rgt-inv-data one">
                                <span className="inv-arr">
                                  <i className="fa-solid fa-arrow-up-long"></i>
                                </span>
                                EURUSD
                              </td>
                              <td className="fr-rgt-inv-data two text-center">
                                0.9534979
                              </td>
                              <td className="fr-rgt-inv-data three text-center">
                                0.9534979
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="easy_Low_main">
                <div className="easy_Low_main_top">
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "position" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("position")}
                  >
                    Position (0)
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "orders" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("orders")}
                  >
                    Orders (0)
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "history" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("history")}
                  >
                    History
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "pricealert" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("pricealert")}
                  >
                    Price alerts
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "transaction" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("transaction")}
                  >
                    Transactions
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "journal" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("journal")}
                  >
                    Journal
                  </span>
                </div>
                <div className="w-100 mb-5">
                  <div className="table-responsive table-cont otc_spot_tableup">
                    <table className="table">
                      <thead>
                        <tr className="stake-head font-satoshi">
                          <th>Symbol</th>
                          <th className="pad-left-23 txt-center">Size</th>
                          <th className="pad-left-23 txt-center">Available</th>
                          <th className="pad-left-23 txt-center">
                            Average Price
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            Unrealized PnL
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            ROE%
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            Market Value
                          </th>
                          <th className="opt-nowrap table-action pad-left-23 pad-rght-tab">
                            Close Position
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center py-5">
                            <div className="empty_data">
                              <div className="empty_data_img py-4">
                                <img
                                  src={require("../assets/no-copytrade-data.webp")}
                                  width="100px"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* popup */}
        <div className="vl-popup-outer">
          <div className="email-popup-card new-vl-inner-popup">
            <div className="email-pop-img">
              <img src={require("../assets/soon.webp")} alt="email-icon" />
            </div>
            <h3>Forex Trade – Coming Soon!</h3>
            <p>
              Unlock new trading possibilities with our Forex Trade feature.
              Stay tuned for the launch!
            </p>
            <div className="Submit">
              <button onClick={() => navigate("/")}>Back To Home</button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ForexTrading;
