import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "semantic-ui-css/semantic.min.css";
import moment from "moment";
import ICON from "../assets/deposit-imp.png";
import WARNICON from "../assets/icons/withdraw-warn.webp";

const P2P = () => {
  const navigate = useNavigate();
  const [p2pOrders, setP2POrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [selectPayment, setselectPayment] = useState("");
  const [amount, setAmount] = useState("");
  const [orderType, setOrderType] = useState("buy");
  const [cryptoCurrencies, setCryptoCurrencies] = useState([]);
  const [fiatCurrencies, setFiatCurrencies] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState("");
  const [selectedFiat, setSelectedFiat] = useState("");
  const [profileData, setProfileData, profileDataRef] = useState("");
  const [isBuy, setIsBuy] = useState("");
  const [siteLoader, setSiteLoader] = useState(true);
  const [isIndexVal, setIsIndexVal] = useState("");
  const [UserID, setUserID, UserIDref] = useState("");

  const [loginStatus, setLoginStatus] = useState(false);

  const preferPayment = [
    { value: "All Payment", text: "All Payment" },
    { key: "imps", text: "IMPS", value: "IMPS" },
    { key: "upid", text: "UPI ID", value: "UPID" },
    { key: "paytm", text: "Paytm", value: "Paytm" },
    { key: "bankTransfer", text: "Bank Transfer", value: "BankTransfer" },
  ];

  const allpayment = [
    { key: "imps", text: "IMPS", value: "IMPS" },
    { key: "upid", text: "UPID", value: "UPID" },
    { key: "paytm", text: "Paytm", value: "Paytm" },
    { key: "bankTransfer", text: "Bank Transfer", value: "BankTransfer" },
  ];

  useEffect(() => {
    // const token = localStorage.getItem("VTXToken");
    let token_check = sessionStorage.getItem("user_token");
    if (token_check) {
      setLoginStatus(true);
      const token = sessionStorage.getItem("VTXToken");
      const VTX = token.split("_")[1];
      setUserID(VTX);
      getAllP2POrders();
      getProfile();
    } else {
      getAllP2POrdersbefore();
      setLoginStatus(false);
    }

    getAllCurrency();
    getKYCstatus();
  }, []);

  useEffect(() => {
    filterOrders();
  }, [
    paymentMethod,
    orderType,
    selectedCrypto,
    selectedFiat,
    p2pOrders,
    amount,
  ]);
  const [bankstatus, setbankstatus] = useState(false);

  const check_details = async (value) => {
    try {
      var obj = { type: value };
      setselectPayment(value);
      var data = {
        apiUrl: apiService.check_details,
        payload: obj,
      };

      var resp = await postMethod(data);
      if (resp.status == true) {
        setbankstatus(true);
      } else {
        showerrorToast(
          `Add any one ${value == "UPID" ? "UPI ID" : value}  details.`
        );
        setbankstatus(false);
      }
    } catch (error) {
    }
  };

  const getAllP2POrdersbefore = async () => {
    try {
      setSiteLoader(true);
      const data = {
        apiUrl: apiService.p2pGetOrderBefore,
        payload: {
          currency: selectedCrypto || selectedFiat,
        },
      };
      const resp = await postMethod(data);

      if (resp.status) {
        setSiteLoader(false);
        setP2POrders(resp.Message);
        setFilteredOrders(resp.Message);

      }
    } catch (error) {
    }
  };

  const loginNave = async () => {
    navigate("/login");
  };

  const getAllP2POrders = async () => {
    try {
      setSiteLoader(true);
      const data = {
        apiUrl: apiService.p2pGetOrder,
        payload: {
          currency: selectedCrypto || selectedFiat,
        },
      };
      const resp = await postMethod(data);

      if (resp.status) {
        setSiteLoader(false);
        setP2POrders(resp.Message);
        setFilteredOrders(resp.Message);

      }
    } catch (error) {
    }
  };

  const getAllCurrency = async () => {
    setSiteLoader(true);

    try {
      const data = { apiUrl: apiService.getP2Pcurrency };
      const resp = await getMethod(data);
      setSiteLoader(false);
      if (resp && resp.data) {
        const cryptoArray = resp.data
          .filter((currency) => currency.coinType === "1")
          .map((currency) => ({
            key: currency._id,
            text: currency.currencySymbol,
            value: currency.currencySymbol,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));

        const fiatArray = resp.data
          .filter((currency) => currency.coinType === "2")
          .map((currency) => ({
            key: currency._id,
            text: currency.currencySymbol,
            value: currency.currencySymbol,
            image: {
              avatar: true,
              src: currency.Currency_image,
            },
          }));

        setCryptoCurrencies(cryptoArray);
        setFiatCurrencies(fiatArray);
      }
    } catch (error) {
    }
  };

  const getProfile = async () => {
    try {
      setSiteLoader(true);

      const data = { apiUrl: apiService.getUserDetails };
      const resp = await getMethod(data);
      setSiteLoader(false);

      if (resp.status) {
        setProfileData(resp.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  const filterOrders = () => {
    setSiteLoader(true);

    let filtered = p2pOrders;

    if (paymentMethod) {
      filtered = filtered.filter(
        (order) => order.paymentMethod === paymentMethod
      );
    }

    if (orderType) {
      filtered = filtered.filter((order) => order.orderType != orderType);
    }

    if (selectedCrypto) {
      filtered = filtered.filter(
        (order) => order.firstCurrency === selectedCrypto
      );
    }

    if (selectedFiat) {
      filtered = filtered.filter(
        (order) => order.secondCurrency == selectedFiat
      );
    }

    if (amount) {
      // filtered = filtered.filter((order) => order.price === amount);
      filtered = filtered.filter((order) => order.price.startsWith(amount));
    }

    setFilteredOrders(filtered);
    setSiteLoader(false);
  };

  const handleClick = (i, option) => {
    setIsBuy(option);
    setIsIndexVal(i);
  };

  const [payAmount, setPayAmount] = useState("");
  const [receiveAmount, setReceiveAmount] = useState("");
  const [error, setError] = useState("");

  const validatePayAmount = (value) => {
    const numericValue = parseFloat(value);

    if (isNaN(numericValue) || value === "") {
      setError("Please enter a valid amount.");
      return false;
    } else if (numericValue <= 0) {
      setError("Amount must be greater than zero.");
      return false;
    } else if (
      isBuy.available_qty > isBuy.fromLimit &&
      numericValue < isBuy.fromLimit
    ) {
      setError(`Amount should not be less than ${isBuy.fromLimit}.`);
      return false;
    } else if (numericValue > isBuy.toLimit) {
      setError(`Amount should not exceed ${isBuy.toLimit}.`);
      return false;
    } else if (numericValue > isBuy.available_qty) {
      setError(
        `Amount should not exceed than the available quantity ${isBuy.available_qty}.`
      );
      return false;
    }

    setError(""); // Clear error if all validations pass
    return true;
  };

  const handlePayAmountChange = (e) => {
    const value = e.target.value;
    if (validatePayAmount(value)) {
      setPayAmount(value);
      const calculatedReceiveAmount =
        parseFloat(value) * parseFloat(isBuy.price);
      setReceiveAmount(calculatedReceiveAmount.toFixed(2));
    } else {
      setPayAmount(value);
      setReceiveAmount("");
    }

    // const numericValue = parseFloat(value);
    // if (
    //   !isNaN(numericValue) &&
    //   numericValue >= isBuy.fromLimit &&
    //   numericValue <= isBuy.toLimit
    // ) {
    //   setPayAmount(value);
    //   const calculatedReceiveAmount = numericValue * parseFloat(isBuy.price);
    //   setReceiveAmount(calculatedReceiveAmount.toFixed(2));
    // } else {
    //   showerrorToast("Enter the valid quantity");
    //   setPayAmount(value);
    //   const calculatedReceiveAmount = numericValue * parseFloat(isBuy.price);
    //   setReceiveAmount(calculatedReceiveAmount.toFixed(2));
    // }
  };

  const handleCancel = () => {
    setPayAmount("");
    setReceiveAmount("");
    setIsIndexVal("");
  };

  const confirm_order_buy = async () => {
    try {
      if (payAmount != "" && receiveAmount != "") {
        if (selectPayment != "") {
          var obj = {
            qty: payAmount,
            total: receiveAmount,
            paymentMethod: selectPayment,
            orderId: isBuy.orderId,
            type: "buy",
          };
          if (bankstatus == true) {
            var data = {
              apiUrl: apiService.p2p_confirm_order,
              payload: obj,
            };
            var resp = await postMethod(data);
            setSiteLoader(false);

            if (resp.status) {
              showsuccessToast(resp.Message);
              navigate(resp.link);
            } else {
              showerrorToast(resp.Message);
            }
           }else {
            showerrorToast(`Add any one ${selectPayment =="UPID"? "UPI ID":selectPayment}  details.`);
          }
        } else {
          showerrorToast("Please select the payment methods");
        }
        // if (selectPayment != "") {
        //   console.log(obj, "ihjujhuj");
        //   var data = {
        //     apiUrl: apiService.p2p_confirm_order,
        //     payload: obj,
        //   };
        //   setSiteLoader(true);

        //   var resp = await postMethod(data);
        //   setSiteLoader(false);

        //   if (resp.status) {
        //     showsuccessToast(resp.Message);
        //     navigate(resp.link);
        //   } else {
        //     showerrorToast(resp.Message);
        //   }
        // } else {
        //   showerrorToast("Please select the payment methods");
        // }
      } else {
        showerrorToast("Please enter valid quantity");
      }
    } catch (error) {
    }
  };

  const confirm_order_sell = async () => {
    try {
      var obj = {};
      obj.qty = payAmount;
      obj.paymentMethod = selectPayment;

      obj.total = receiveAmount;
      obj.orderId = isBuy.orderId;
      obj.type = "sell";

      if (obj.qty != "" && obj.total != "") {
        if (selectPayment != "") {
          if (bankstatus == true) {
            var data = {
              apiUrl: apiService.p2p_confirm_sellorder,
              payload: obj,
            };
            setSiteLoader(true);

            var resp = await postMethod(data);
            setSiteLoader(false);

            if (resp.status) {
              showsuccessToast(resp.Message);
              navigate(resp.link);
              window.location.href = resp.link;
            } else {
              showerrorToast(resp.Message);
              if (resp.bank) {
                navigate("/Paymentmethod");
              }
            }
          }
          else {
        showerrorToast(`Add any one ${selectPayment =="UPID"? "UPI ID":selectPayment}  details.`);
          }
        } else {
          showerrorToast("Please select the payment methods");
        }
      } else {
        showerrorToast("Please enter quantity");
      }
    } catch (error) {}
  };

  const showsuccessToast = (message) => {
    toast.dismiss();
    toast.success(message);
  };

  const showerrorToast = (message) => {
    toast.dismiss();
    toast.error(message);
  };

  const [kycStatus, setkycStatus, kycStatusref] = useState(1);

  const getKYCstatus = async () => {
    try {
      var data = {
        apiUrl: apiService.getKYCStatus,
      };

      setSiteLoader(true);
      var getKYC = await getMethod(data);
      setSiteLoader(false);

      if (getKYC.status) {
        setkycStatus(getKYC?.Message?.kycstatus);
      } else {
        setkycStatus(0);
      }
    } catch (err) {
      setkycStatus(0);
    }
  };
  return (
    <>
      <Header />

      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <main className="dashboard_main">
          <div className="container-lg">
            {kycStatusref.current == 1 || loginStatus == false ? (
              <div className="row">
                <div className="col-lg-12">
                  <section className="asset_section">
                    <div className="row">
                      <div className="p2p_title">
                        P2P{" "}
                        <div className="p2p-head-right">
                          <span className="p2p-right-links">
                            <img
                              src={require("../assets/icons/p2p-orders.webp")}
                              alt="p2p-order"
                              className="p2p-right-icons"
                            />
                            <Link
                              to={
                                loginStatus == true
                                  ? "/processorders"
                                  : "/login"
                              }
                              className="p2p-right-links"
                            >
                              Orders
                            </Link>
                          </span>
                          <span className="p2p-right-links">
                            <img
                              src={require("../assets/icons/p2p-plus.png")}
                              alt="p2p-order"
                              className="p2p-right-icons"
                            />
                            <Link
                              to={loginStatus == true ? "/postad" : "/login"}
                              className="p2p-right-links"
                            >
                              Post ads
                            </Link>
                          </span>
                          <span className="p2p-right-links">
                            <img
                              src={require("../assets/icons/p2p-payment.webp")}
                              alt="p2p-payment"
                              className="p2p-right-icons"
                            />
                            <Link
                              to={
                                loginStatus == true
                                  ? "/Paymentmethod"
                                  : "/login"
                              }
                              className="p2p-right-links"
                            >
                              Payment Method
                            </Link>
                          </span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="filter-btns-wrapper mb-5">
                          <div className="fil-buy-sell">
                            <span
                              className={`fil-sell ${
                                orderType === "buy" ? "fil-buy" : ""
                              }`}
                              onClick={() => setOrderType("buy")}
                            >
                              Buy
                            </span>
                            <span
                              className={`fil-sell ${
                                orderType === "sell" ? "fil-sell-red" : ""
                              }`}
                              onClick={() => setOrderType("sell")}
                            >
                              Sell
                            </span>
                          </div>

                          <div className="fil-country">
                            <Dropdown
                              placeholder="Crypto "
                              fluid
                              selection
                              options={cryptoCurrencies}
                              onChange={(e, { value }) =>
                                setSelectedCrypto(value)
                              }
                            />
                          </div>

                          <div className="fil-country">
                            <Dropdown
                              placeholder="Fiat "
                              fluid
                              selection
                              options={fiatCurrencies}
                              onChange={(e, { value }) =>
                                setSelectedFiat(value)
                              }
                            />
                          </div>

                          <div className="fil-payment">
                            <Dropdown
                              placeholder="All payment method"
                              fluid
                              selection
                              options={preferPayment}
                              onChange={(e, { value }) =>
                                setPaymentMethod(value)
                              }
                              value={paymentMethod}
                            />
                          </div>

                          <div className="fil-enter">
                            <input
                              type="text"
                              placeholder="Enter Amount"
                              className="fil-amount"
                              value={amount}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              // onChange={(e) => setAmount(e.target.value)}
                              onChange={(e) => {
                                const value = e.target.value;
                                // const numericValue = value.replace(/\D/g, "");
                                const numericValue = value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                                const parts = numericValue.split(".");
                                if (parts.length > 2) {
                                  return;
                                }
                                if (numericValue.length <= 15) {
                                  setAmount(numericValue);
                                }
                              }}
                            />
                            <span className="fil-inr">
                              {selectedFiat ? selectedFiat : "INR"}
                            </span>
                            {/* <span className="white-das">|</span>
                          <span className="fil-search">Search</span> */}
                          </div>
                        </div>

                        <div className="table-responsive table-cont">
                          <table className="table">
                            <thead>
                              <tr className="stake-head">
                                <th>Advertiser</th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  Date & Time
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  Price
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  Available / Limits
                                </th>
                                <th className="opt-nowrap txt-center pad-left-23">
                                  Payment Method
                                </th>
                                <th className="table_action p-r-25">Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filteredOrders && filteredOrders.length > 0 ? (
                                filteredOrders.map((options, i) => (
                                  <React.Fragment key={options.id}>
                                    {i === isIndexVal ? (
                                      <tr>
                                        <td colSpan="6">
                                          <div className="row bord-top">
                                            <div className="col-lg-7 pad-all-3 pad_ll_3_chg">
                                              <div className="table-flex">
                                                {/* <img
                                              src={require(`../assets/j.png`)}
                                              alt={options.optName}
                                            /> */}
                                                <div className="p2p_namefrst_change">
                                                  <span>
                                                    {options.displayname.charAt(
                                                      0
                                                    )}
                                                  </span>
                                                </div>
                                                <div className="table-opt-name">
                                                  <h4 className="opt-nowrap opt-name font_14">
                                                    {options.displayname}
                                                  </h4>
                                                  <h3 className="opt-nowrap opt-sub font_14">
                                                    {`${
                                                      options.orders_count
                                                    } Volume | ${parseFloat(
                                                      options.rating
                                                    ).toFixed(
                                                      2
                                                    )} % Transaction rate`}
                                                  </h3>
                                                </div>
                                              </div>
                                              <div className="ad-buy-details">
                                                <div className="opt-nowrap opt-term font_14 ">
                                                  {options.price}{" "}
                                                  {options?.secondCurrency}
                                                  <div className="opt-price-span mar-t-price">
                                                    Price
                                                  </div>
                                                </div>
                                                <div className="opt-nowrap opt-term font_14 ">
                                                  <span className="opt-pay">
                                                    {options.paymentMethod=="UPID"? "UPI ID":options.paymentMethod}{" "}
                                                  </span>
                                                  <div className="opt-price-span mar-t-price">
                                                    Payment Method
                                                  </div>
                                                </div>
                                                <div className="opt-nowrap opt-term font_14">
                                                  {options && options.fromLimit}{" "}
                                                  - {options && options.toLimit}{" "}
                                                  {options &&
                                                    options.firstCurrency}
                                                  <div className="opt-price-span mar-t-price">
                                                    Limit
                                                  </div>
                                                </div>
                                                <div className="opt-nowrap opt-term font_14 ">
                                                  {options.pay_duration}
                                                  <div className="opt-price-span mar-t-price">
                                                    Payment Time Limit
                                                  </div>
                                                </div>
                                                <div className="opt-nowrap opt-term font_14 ">
                                                  {options.available_qty}{" "}
                                                  {options.firstCurrency}
                                                  <div className="opt-price-span mar-t-price">
                                                    Available
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="col-lg-5 col-md-6 col-sm-6 col-5 youpay">
                                              <form className="youpay-form">
                                                <label htmlFor="quantity-sell">
                                                  Enter quantity to{" "}
                                                  {orderType == "buy"
                                                    ? "Buy"
                                                    : "Sell"}
                                                </label>
                                                <div className="p2p-pay-input mb-4">
                                                  <input
                                                    id="quantity-sell"
                                                    type="text"
                                                    placeholder="Enter Amount"
                                                    className="w-100 pay-input mb-0"
                                                    value={payAmount}
                                                    onChange={(e) => {
                                                      // Allow only numbers and limit the length to 10 digits
                                                      const value =
                                                        e.target.value;
                                                      if (
                                                        value.length <= 30 &&
                                                        /^[0-9]*\.?[0-9]*$/.test(
                                                          value
                                                        )
                                                      ) {
                                                        handlePayAmountChange(
                                                          e
                                                        );
                                                      }
                                                    }}
                                                    onKeyDown={(evt) =>
                                                      [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                      ].includes(evt.key) &&
                                                      evt.preventDefault()
                                                    }
                                                  />
                                                  {error && (
                                                    <p className="errorcss mb-0">
                                                      {error}
                                                    </p>
                                                  )}
                                                  <span className="youpay-span">
                                                    {options.firstCurrency}
                                                  </span>
                                                </div>

                                                <label htmlFor="you-pay">
                                                  You will pay
                                                </label>
                                                <div className="p2p-pay-input">
                                                  <input
                                                    type="text"
                                                    placeholder="0.00"
                                                    className="w-100 receive-input"
                                                    value={receiveAmount}
                                                    readOnly
                                                  />
                                                  <span>
                                                    {options.secondCurrnecy}
                                                  </span>
                                                </div>

                                                <label htmlFor="you-pay">
                                                  Select Payment Method
                                                </label>

                                                <div className=" mb-4">
                                                  {options.paymentMethod ==
                                                  "All Payment" ? (
                                                    <Dropdown
                                                      placeholder="Choose payment method"
                                                      className="you-pay-select"
                                                      fluid
                                                      selection
                                                      options={allpayment}
                                                      // onChange={(e, { value }) =>
                                                      //   setselectPayment(value)
                                                      // }
                                                      onChange={(
                                                        e,
                                                        { value }
                                                      ) => check_details(value)}
                                                      value={selectPayment}
                                                    />
                                                  ) : (
                                                    <Dropdown
                                                      placeholder="Choose payment method"
                                                      className="you-pay-select"
                                                      fluid
                                                      selection
                                                      options={[
                                                        {
                                                          value:
                                                            options.paymentMethod,
                                                          text: options.paymentMethod,
                                                        },
                                                      ]}
                                                      // onChange={(e, { value }) =>
                                                      //   setselectPayment(value)
                                                      // }
                                                      onChange={(
                                                        e,
                                                        { value }
                                                      ) => check_details(value)}
                                                      value={selectPayment}
                                                    />
                                                  )}
                                                </div>

                                                <div className="youpay-btns">
                                                  <button
                                                    type="button"
                                                    className="youpay-cancel"
                                                    onClick={handleCancel}
                                                  >
                                                    Cancel
                                                  </button>
                                                  {orderType == "buy" ? (
                                                    <Link
                                                      type="submit"
                                                      onClick={() =>
                                                        confirm_order_buy()
                                                      }
                                                      className={`${
                                                        orderType == "buy"
                                                          ? "fil-buy"
                                                          : "action_btn_sell"
                                                      } `}
                                                    >
                                                      Buy
                                                    </Link>
                                                  ) : (
                                                    <Link
                                                      type="submit"
                                                      onClick={() =>
                                                        confirm_order_sell()
                                                      }
                                                      className={`${
                                                        orderType == "buy"
                                                          ? "fil-buy"
                                                          : "youpay-sell"
                                                      } `}
                                                    >
                                                      <span className="mx-1">
                                                        Sell
                                                      </span>
                                                      {options.firstCurrency}
                                                    </Link>
                                                  )}
                                                </div>
                                              </form>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr key={options.id}>
                                        <td>
                                          <div className="table-flex">
                                            {/* <img
                                          src={require(`../assets/j.png`)}
                                          alt={options.optName}
                                        /> */}
                                            <div className="p2p_namefrst_change">
                                              <span>
                                                {options.displayname.charAt(0)}
                                              </span>
                                            </div>
                                            <div className="table-opt-name">
                                              <h4 className="opt-nowrap opt-name font_14">
                                                {options.displayname}
                                              </h4>
                                              <h3 className="opt-nowrap opt-sub font_14">
                                                {`${
                                                  options.orders_count
                                                } Volume | ${parseFloat(
                                                  options.rating
                                                ).toFixed(
                                                  2
                                                )} % Transaction rate`}
                                              </h3>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="opt-nowrap opt-price font_14 table_center_text pad-left-23">
                                          {moment(options.date).format("lll")}
                                        </td>
                                        <td className="opt-nowrap opt-price font_14 table_center_text pad-left-23">
                                          {options.price}{" "}
                                          <span className="opt-price-span">
                                            {options?.secondCurrency}
                                          </span>
                                        </td>

                                        <td className="opt-nowrap opt-percent font_14 table_center_text pad-left-23">
                                          <div className="table-opt-name table-flex-col">
                                            <h4 className="opt-name font_14">
                                              <span className="opt-sub opt-sub-amt">
                                                Amount{" "}
                                              </span>
                                              <span className="opt-amount">
                                                {options &&
                                                  options.available_qty}{" "}
                                                {options &&
                                                  options.firstCurrency}
                                              </span>
                                            </h4>
                                            <h3 className="opt-sub font_14">
                                              <span className="opt-sub opt-sub-lmt">
                                                Limit{" "}
                                              </span>
                                              <span className="opt-amount">
                                                {" "}
                                                {options &&
                                                  options.fromLimit} -{" "}
                                                {options && options.toLimit}{" "}
                                                {options &&
                                                  options.firstCurrency}
                                              </span>
                                            </h3>
                                          </div>
                                        </td>
                                        <td className="opt-nowrap opt-term font_14 table_center_text pad-left-23">
                                          <span className="opt-pay">
                                            {options && options.paymentMethod=="UPID"? "UPI ID":options.paymentMethod}{" "}
                                          </span>
                                        </td>
                                        <td className="opt-btn-flex table-action pad-left-23">
                                          {loginStatus == true ? (
                                            <>
                                              {options.user_id ==
                                              UserIDref.current ? (
                                                <Link
                                                  className={`${
                                                    orderType == "buy"
                                                      ? "p2p-buy"
                                                      : "action_btn_sell"
                                                  } `}
                                                  to={`/p2p/order/${options.orderId}`}
                                                >
                                                  View
                                                </Link>
                                              ) : (
                                                <Link
                                                  className={`${
                                                    orderType == "buy"
                                                      ? "p2p-buy"
                                                      : "action_btn_sell"
                                                  } `}
                                                  onClick={() =>
                                                    handleClick(i, options)
                                                  }
                                                >
                                                  {orderType == "buy"
                                                    ? "Buy"
                                                    : "Sell"}
                                                </Link>
                                              )}
                                            </>
                                          ) : (
                                            <button
                                              className="action_btn"
                                              onClick={() => loginNave()}
                                            >
                                              Login
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={6} className="text-center py-5">
                                    <div className="empty_data">
                                      <div className="empty_data_img">
                                        <img
                                          src={require("../assets/No-data.webp")}
                                          width="100px"
                                        />
                                      </div>
                                      <div className="no_records_text">
                                        No Records Found
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            ) : (
              <>
                <div className="row ">
                  <div className="p2p_title">Withdraw</div>
                  <div className="col-lg-7">
                    <div className="deposit mt-5  h-100">
                      <div className="dep-kyc">
                        <div className="dep-kyc-head">
                          <img
                            src={ICON}
                            alt="warn-icon"
                            className="deposit-imp-icon"
                          />
                          <h6>KYC Verification Required</h6>
                        </div>
                        <p>
                          You haven't completed the KYC verification process. To
                          ensure the security and compliance of our platform,
                          you must complete your KYC verification before you can
                          Transfer any crypto.
                        </p>
                        <div>
                          <img
                            src={require("../assets/BeforeKyc.webp")}
                            alt="Verify kyc"
                            className="before_kyc_depo withdraw-p-l-24"
                          />
                        </div>
                        <p className="mt-4">
                          Please click the button below to verify your account
                        </p>
                        <div className="withdraw-p-l-24">
                          <Link to="/kyc">
                            <button className="action_btn w-100 mb-2">
                              Verify Now
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <div>
                      <div className="container-lg">
                        <div className="deposit-imp-notes mt-5">
                          <div className="imp-notes-title">
                            <span>
                              <img
                                src={ICON}
                                alt="warn-icon"
                                className="deposit-imp-icon"
                              />
                            </span>
                            <p>Important Notes</p>
                          </div>
                          <div className="imp-notes-content">
                            <h6>Security</h6>
                            <p>
                              Ensure that your account is secure and that you
                              are using a trusted Person.
                            </p>
                          </div>
                          <div className="imp-notes-content">
                            <h6>Payment Methods</h6>
                            <p>
                              P2P platforms offer various payment methods, so
                              choose one that is convenient and secure for you
                            </p>
                          </div>
                          <div className="imp-notes-content">
                            <h6>Dispute Resolution</h6>
                            <p>
                              In case of disputes, resolution can be complex and
                              time-consuming. Be cautious and try to resolve
                              issues amicably.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </main>
      )}
    </>
  );
};

export default P2P;
