export const env = {
  // apiHost: "http://localhost:3033/",
  // frontUrl: "http://localhost:3000/",
  apiHost: "https://voltrixcrypt.io:3033/",
  frontUrl: "https://voltrixcrypt.io/",
  upload_preset: "sdfnunjedkaldkXVTLsdfojasdfsd",
  cloud_name: "daafoiwvn",

  // SITE_URL: "http://localhost:3000/",
  SITE_URL: "https://voltrixcrypt.io/",
};
