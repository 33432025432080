export const subHeadCoin = [
  {
    id: 1,
    img: require("../assets/trade-sol.webp"),
    currency: "SOLUSDT",
    price: "249.8755",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 2,
    img: require("../assets/trade-btc.webp"),
    currency: "BTCUSDT",
    price: "249.8755",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
  {
    id: 3,
    img: require("../assets/trade-eth.webp"),
    currency: "ETHUSDT",
    price: "249.8755",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 4,
    img: require("../assets/trade-xrp.webp"),
    currency: "XRPUSDT",
    price: "249.8755",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
  {
    id: 5,
    img: require("../assets/trade-doge.webp"),
    currency: "DOGEUSDT",
    price: "249.8755",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 6,
    img: require("../assets/trade-bnb.webp"),
    currency: "BNBUSDT",
    price: "578.8",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
];

export const greeksTabs = [
  {
    id: 1,
    coin: "SOLUSDT",
  },
  {
    id: 2,
    coin: "BTCUSDT",
  },
  {
    id: 3,
    coin: "ETHUSDT",
  },
  {
    id: 4,
    coin: "BNBUSDT",
  },
];

export const frWatchList = [
  {
    id: 1,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "+0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 2,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "-0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
  {
    id: 3,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "+0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 4,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "-0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
  {
    id: 5,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "+0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 6,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "-0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
  {
    id: 7,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "+0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 8,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "-0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
  {
    id: 9,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "+0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 10,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "-0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
  {
    id: 11,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "+0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: true,
  },
  {
    id: 12,
    symbols: "EURUSD",
    bid: "1.11921",
    ask: "1.119236",
    chg: "-0.73%",
    redIcon: "fa-solid fa-arrow-down-long",
    greenIcon: "fa-solid fa-arrow-up-long",
    tradeSignal: false,
  },
];

export const orderTypes = [
  {
    id: 1,
    order: "Market",
  },
  {
    id: 2,
    order: "Limit",
  },
  {
    id: 3,
    order: "Stop",
  },
  {
    id: 4,
    order: "Stop-Limit",
  },
];
