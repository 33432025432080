import React, { useEffect } from "react";
import Header from "./Header";
import useState from "react-usestateref";
import { getMethod, postMethod } from "../core/service/common.api";
import apiService from "../core/service/detail";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import { Dropdown } from "semantic-ui-react";
import { Bars } from "react-loader-spinner";
import "./CopyTrading.css";
import "./OTC.css";

function EasyOption() {
  const [siteLoader, setSiteLoader] = useState(false);
  const navigate = useNavigate();
  const stepperNum = [1, 2, 3, 4];
  const filteredStepperNum = stepperNum.slice(0, -1);
  const [stepCount, setStepCount] = useState(0);
  const [activeTarget, setActiveTarget, activeTargetref] = useState("week");
  const [activePrice, setActivePrice, activePriceref] = useState("price");
  const [activeTab, setActiveTab, activeTabref] = useState("position");
  const [activeUpdown, setActiveUpdown, activeUpdownref] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("ETH");

  const predictionOptions = [
    {
      key: "ETH",
      text: "Make a prediction for ETH",
      value: "ETH",
    },
    {
      key: "BTC",
      text: "Make a prediction for BTC",
      value: "BTC",
    },
  ];

  const options = [
    { key: "BTC", text: "BTC", value: "BTC" },
    { key: "ETH", text: "ETH", value: "ETH" },
  ];

  const handleChange = (e, { value }) => {
    setSelectedCurrency(value);
  };

  const navOption = async () => {
    navigate("/optionaltrade/:pair");
  };

  const handleTarget = async (value) => {
    setActiveTarget(value);
  };

  const handlePrice = async (value) => {
    setActivePrice(value);
  };

  const handleTabs = async (value) => {
    setActiveTab(value);
  };

  const handleUpDown = async (value) => {
    setActiveUpdown(value);
  };

  return (
    <div>
      <Header />
      {siteLoader == true ? (
        <div className="loadercss">
          <Bars
            height="80"
            width="80"
            color="#ffc630"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="new-popup-height">
          <div className="easy_opt_hero">
            <div className="container">
              <div className="easy_opt_top">
                <div className="easy_opt_top_back" onClick={() => navOption()}>
                  <span>Options</span>
                  <i class="fa-solid fa-chevron-right"></i>
                </div>
                <span className="easy_opt_top_head">Easy Options</span>
              </div>
              <div className="easy_opt_center">
                <span className="easy_opt_scndleft">
                  Fill in the sides (Up or Down), set your target date, and
                  enter the predicted price to filter and customize your trading
                  options. This ensures a more precise and tailored prediction
                  experience!
                </span>
                <Dropdown
                  placeholder="Choose prediction for ETH"
                  fluid
                  selection
                  options={predictionOptions}
                  value={selectedCurrency}
                  onChange={handleChange}
                  className="opt-select-easyoptions"
                  trigger={
                    <div className="custom-trigger">
                      Make a prediction for{" "}
                      <span className="highlight-currency">
                        {selectedCurrency}
                      </span>
                    </div>
                  }
                />
              </div>
              <div className="easy_opt_third">
                <span className="easy_opt_third_top">
                  Current Price <span> 0.0000 ETH</span>
                </span>
                <div className="easy_opt_third_center">
                  <div className="step-container-easyopt">
                    {filteredStepperNum.map((stepper, index) => (
                      <div
                        key={stepper}
                        className={`stepper-contents ${
                          stepCount >= index + 1 ? "active-step" : ""
                        } ${stepCount > index ? "active-line" : ""}`}
                      >
                        <div className="stepper-count">{stepper}</div>
                      </div>
                    ))}
                  </div>
                  <div className="row w-100">
                    <div className="col-lg-4">
                      <div className="easy_mid_left">
                        <span className="easy_gen_predict">
                          General Prediction
                        </span>
                        <div className="easy_predi_cent">
                          <div
                            className={` box_dim_clk_grn ${
                              activeUpdownref.current == "up" ? "active" : ""
                            } `}
                          >
                            <div
                              className={` easy_predi_cent_gren ${
                                activeUpdownref.current == "up" ? "active" : ""
                              } `}
                              onClick={() => handleUpDown("up")}
                            >
                              <i class="fa-solid fa-arrow-up easy_pre_i"></i>
                            </div>
                          </div>
                          <div
                            className={` box_dim_clk_red ${
                              activeUpdownref.current == "down" ? "active" : ""
                            } `}
                          >
                            <div
                              className={` easy_predi_cent_red ${
                                activeUpdownref.current == "down"
                                  ? "active"
                                  : ""
                              } `}
                              onClick={() => handleUpDown("down")}
                            >
                              <i class="fa-solid fa-arrow-down easy_pre_i"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 easy_1k_margin">
                      <div className="easy_mid_center_car">
                        <div className="easy_mid_center_top">
                          <span className="easy_gen_predict">
                            Select Target Date
                          </span>
                          <div className="easy_gen_predict_cenyel">
                            <span
                              className={
                                activeTargetref.current == "week"
                                  ? "easy_gen_predict_cenyel_active"
                                  : "easy_gen_predict_cenyel_inactive"
                              }
                              onClick={() => handleTarget("week")}
                            >
                              Within a week
                            </span>
                            <span
                              className={
                                activeTargetref.current == "month"
                                  ? "easy_gen_predict_cenyel_active"
                                  : "easy_gen_predict_cenyel_inactive"
                              }
                              onClick={() => handleTarget("month")}
                            >
                              With a month
                            </span>
                            <span
                              className={
                                activeTargetref.current == "moremonth"
                                  ? "easy_gen_predict_cenyel_active"
                                  : "easy_gen_predict_cenyel_inactive"
                              }
                              onClick={() => handleTarget("moremonth")}
                            >
                              More than 1 month
                            </span>
                          </div>
                        </div>
                        <Dropdown
                          placeholder="2025-01-28"
                          fluid
                          className="easy-option-drops"
                          selection
                          options={options}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 easy_1k_margin">
                      <div className="easy_mid_center_car">
                        <div className="easy_mid_center_top">
                          <span className="easy_gen_predict">
                            {" "}
                            Predicted Price
                          </span>
                          <div className="easy_gen_predict_cenyel">
                            <span
                              className={
                                activePriceref.current == "price"
                                  ? "easy_gen_predict_cenyel_active"
                                  : "easy_gen_predict_cenyel_inactive"
                              }
                              onClick={() => handlePrice("price")}
                            >
                              Price
                            </span>
                            <span
                              className={
                                activePriceref.current == "custom"
                                  ? "easy_gen_predict_cenyel_active"
                                  : "easy_gen_predict_cenyel_inactive"
                              }
                              onClick={() => handlePrice("custom")}
                            >
                              Custom
                            </span>
                          </div>
                        </div>
                        <Dropdown
                          placeholder=" Select Predicted Price"
                          fluid
                          className="easy-option-drops"
                          selection
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="easy_Low_main">
                <div className="easy_Low_main_top">
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "position" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("position")}
                  >
                    Position (0)
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "orders" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("orders")}
                  >
                    Orders (0)
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "history" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("history")}
                  >
                    History
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "pricealert" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("pricealert")}
                  >
                    Price alerts
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "transaction" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("transaction")}
                  >
                    Transactions
                  </span>
                  <span
                    className={`easy_low_tabhead ${
                      activeTabref.current == "journal" ? "active" : ""
                    } `}
                    onClick={() => handleTabs("journal")}
                  >
                    Journal
                  </span>
                </div>
                <div className="w-100">
                  <div className="table-responsive table-cont otc_spot_tableup">
                    <table className="table">
                      <thead>
                        <tr className="stake-head font-satoshi">
                          <th>Symbol</th>
                          <th className="pad-left-23 txt-center">Size</th>
                          <th className="pad-left-23 txt-center">Available</th>
                          <th className="pad-left-23 txt-center">
                            Average Price
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            Unrealized PnL
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            ROE%
                          </th>
                          <th className="pad-left-23 txt-center opt-nowrap">
                            Market Value
                          </th>
                          <th className="opt-nowrap table-action pad-left-23 pad-rght-tab">
                            Close Position
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td colSpan={8} className="text-center py-5">
                            <div className="empty_data">
                              <div className="empty_data_img py-4">
                                <img
                                  src={require("../assets/no-copytrade-data.webp")}
                                  width="100px"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* popup */}

          <div className="vl-popup-outer">
            <div className="email-popup-card new-vl-inner-popup">
              <div className="email-pop-img">
                <img src={require("../assets/soon.webp")} alt="email-icon" />
              </div>
              <h3>Easy Options – Coming Soon!</h3>
              <p>
                Unlock new trading possibilities with our Easy Options feature.
                Stay tuned for the launch!
              </p>
              <div className="Submit">
                <button onClick={() => navigate("/")}>Back To Home</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EasyOption;
